import { requests } from './DefaultRequest'
import utils from '../utils'
import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const ProposalsRequest = {
  getProposals: (
    pageIndex,
    pageSize,
    keyword,
    proposalStatus,
    type,
    proposal_permission,
    department,
    time
  ) =>
    requests.get(
      `/api/v1/proposals/current-user?${
        type === undefined
          ? 'type=BUSINESS_TRIP&type=RETIMEKEEPING&type=LEAVE&type=SICK&type=SICK_OTHER&type=OVERTIME_NORMAL&type=OVERTIME_DAYOFF&type=LEAVE&type=OVERTIME_HOLIDAY&type=LEAVE_ANNUAL&type=LEAVE_NO_SALARY&type=LEAVE_ABNORMAL&type=EVALUATE_MEMBER&type=EVALUATE_LEADER&type=TIMEKEEPER&type=STATIONERY&type=MEETING&type=VEHICLE'
          : type
      }`,
      true,
      {
        page: pageIndex,
        size: pageSize,
        ...(!utils.isNullish(keyword) && { keyword: keyword }),
        ...(!utils.isNullish(proposalStatus) && {
          proposal_status: proposalStatus,
        }),
        // ...(!utils.isNullish(type) && { type: type }),
        type: null,
        ...(!utils.isNullish(proposal_permission) && {
          proposal_permission: proposal_permission,
        }),
        ...(!utils.isNullish(department) && { department: department }),
        ...(!utils.isNullish(time) && { time: time }),
      }
    ),

  // approve: (id, overtime) =>
  //   requests.post(`api/v1/approve`, { id: id, overtime: overtime }),

  approve: (id, overtime) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/approve`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: { id: id, overtime: overtime },
    }),

  deleteProposalItem: code => requests.delete(`/api/v1/proposals/${code}`),
  updateProposal: payload => requests.put(`/api/v1/proposal-others`, payload),
  /** Nghỉ phép */
  createProposalLeave: proposalLeaveInfo =>
    requests.post(`/api/v1/proposal-leaves`, proposalLeaveInfo),
  getProposalLeavesDetail: proposalId =>
    requests.get(`/api/v1/proposal-leaves/${proposalId}`),
  getProposalLeaveComment: proposalId =>
    requests.get(`/api/v1/proposal-leaves/${proposalId}/comments`),
  createProposalLeaveComment: (proposalId, data) =>
    requests.post(`/api/v1/proposal-leaves/${proposalId}/comments`, data),
  updateLeaveAssignPerson: (proposalId, data) =>
    requests.put(`/api/v1/proposal-leaves/${proposalId}/assign-person`, data),

  /** Xe cộ */
  createVehicle: vehicleInfo =>
    requests.post(`/api/v1/proposal-vehicles`, vehicleInfo),
  getVehicleDetail: proposalId =>
    requests.get(`/api/v1/proposal-vehicles/${proposalId}`),
  getVehicleComment: proposalId =>
    requests.get(`/api/v1/proposal-vehicles/${proposalId}/comments`),
  createVehicleComment: (proposalId, data) =>
    requests.post(`/api/v1/proposal-vehicles/${proposalId}/comments`, data),
  updateVehicleAssignPerson: (proposalId, data) =>
    requests.put(`/api/v1/proposal-vehicles/${proposalId}/assign-person`, data),

  /** Khác */
  createOther: otherInfo => requests.post(`/api/v1/proposal-others`, otherInfo),
  getOtherDetail: proposalId =>
    requests.get(`/api/v1/proposal-others/${proposalId}`),
  getOtherComment: proposalId =>
    requests.get(`/api/v1/proposal-others/${proposalId}/comments`),
  createOtherComment: (proposalId, data) =>
    requests.post(`/api/v1/proposal-others/${proposalId}/comments`, data),
  updateOtherAssignPerson: (proposalId, data) =>
    requests.put(`/api/v1/proposal-others/${proposalId}/assign-person`, data),
  deleteCommentProposal: (proposal_code, comment_id) =>
    requests.delete(
      `/api/v1/proposal-others/${proposal_code}/comments/${comment_id}`
    ),

  /** Xóa phiếu trình */
  deleteProposal: proposalId =>
    requests.delete(`/api/v1/proposal-others/${proposalId}`),
  approvalProposalOther: data =>
    requests.post(`/api/v1/proposal-others/approval`, data),

  getProposalApprovalConfig: proposalType =>
    requests.get(`/api/v1/approval_template/apply/${proposalType}`),

  getProposalDetailApproval: proposalId =>
    requests.get(`/api/v1/proposals/${proposalId}/approve`),

  approveProposal: (proposalId, payload) =>
    requests.put(`/api/v1/proposals/${proposalId}/approve`, payload),
}
