import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const DeparmentRequest = {
  getUserOfDepartment: departmentCode =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/departments/${departmentCode}/users`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  getDepartmentList: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/departments`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  getAllDepartmentList: companyCode =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/departments/getAll`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        company_code: companyCode,
      },
    }),

  getDepartmentWithUsers: (paramDepartmentSort, companyCode) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/departments/users`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        sort: paramDepartmentSort,
        company_code: companyCode,
      },
    }),

  getDepartmentListFromCompanyCode: companyCode =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/departments/${companyCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  create: name =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/department`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        name: name,
      },
    }),

  update: (code, name) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/department/${code}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        name: name,
      },
    }),
  delete: code =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/department/${code}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  sort: codes =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/departments/sort`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        data: codes,
      },
    }),

  getNumberOfUsersInDepartment: companyCode =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/users-in-department/${companyCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
}
