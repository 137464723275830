import React from 'react'
import { Helmet } from 'react-helmet'
import AdministrativeManagementLayout from './AdministrativeManagementLayout'

const AdministrativeManagementApprovalTemplate = () => {
  return (
    <AdministrativeManagementLayout selectedKey="approval-templates">
      <Helmet>
        <title>
          Mẫu duyệt | Quản trị | Hành chính
        </title>
      </Helmet>
    </AdministrativeManagementLayout>
  )
}

export default AdministrativeManagementApprovalTemplate
