import { DoubleRightOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { memo } from 'react'
import { EmptyText } from '../../components/Common/CellText'
import date_format from '../../date_format'

const TimekeepingInfo = props => {
  const { timekeepingIdList, link } = props
  const pageType = proposal_type => {
    switch (proposal_type) {
      case 'LEAVE':
      case 'LEAVE_ANNUAL':
      case 'LEAVE_NO_SALARY':
      case 'BUSINESS_TRIP':
      case 'EVALUATE_MEMBER':
      case 'EVALUATE_LEADER':
      case 'TIMEKEEPER':
      case 'STATIONERY':
      case 'MEETING':
      case 'VEHICLE':
      case 'SICK':
        return 'other'
      default:
        return ''
    }
  }
  return (
    <div
      style={{
        margin: '10px',
        border: '1px solid #2c65ac',
        borderRadius: '5px',
      }}>
      {timekeepingIdList?.length === 1?timekeepingIdList?.map((item, index) => (
        <div key={index}>
          <h3
            style={{
              display: 'flex',
              backgroundColor: '#2c65ac',
              height: '40px',
              width: '100%',
              color: 'white',
              padding: '0 10px',
              alignItems: 'center',
            }}>
            <span style={{ marginRight: '3px' }}>
              Chi tiết thông tin chấm công ngày:{' '}
            </span>
            {date_format.DATE_FORMAT(item.timekeepingDay)}
          </h3>
          <div style={{ display: 'flex' }}>
            <div
              key={'timekeeping-table'}
              style={{ margin: '5px', width: '100%' }}>
              <dt>Vào làm lúc:</dt>
              <dd style={{ margin: '0 5px', borderBottom: '1px solid gray' }}>
                {item.late == true ? (
                  item.ioTimeIn ? (
                    <span style={{ color: 'red' }}>
                      {date_format.HH_mm_DD_MM_YYYY(item.ioTimeIn)}
                    </span>
                  ) : (
                    <EmptyText>Không có dữ liệu</EmptyText>
                  )
                ) : item.ioTimeIn ? (
                  <span style={{ color: '#2c65ac' }}>
                    {date_format.HH_mm_DD_MM_YYYY(item.ioTimeIn)}
                  </span>
                ) : (
                  <EmptyText>Không có dữ liệu</EmptyText>
                )}
              </dd>
              <dt>Tan làm lúc:</dt>
              <dd style={{ margin: '0 5px', borderBottom: '1px solid gray' }}>
                {item.early == true ? (
                  item.ioTimeOut ? (
                    <span style={{ color: 'red' }}>
                      {date_format.HH_mm_DD_MM_YYYY(item.ioTimeOut)}
                    </span>
                  ) : (
                    <EmptyText>Không có dữ liệu</EmptyText>
                  )
                ) : item.ioTimeOut ? (
                  <span style={{ color: '#2c65ac' }}>
                    {date_format.HH_mm_DD_MM_YYYY(item.ioTimeOut)}
                  </span>
                ) : (
                  <EmptyText>Không có dữ liệu</EmptyText>
                )}
              </dd>
              <dt>Trạng thái đi làm:</dt>
              <dd style={{ margin: '0 5px', borderBottom: '1px solid gray' }}>
                {item.absent === true ? (
                  <span style={{ color: '#f79e00' }}>Không xác định</span>
                ) : (
                  <div>
                    {item.late === true ? (
                      <span style={{ color: 'red' }}>Đi muộn</span>
                    ) : (
                      'Đúng giờ'
                    )}
                  </div>
                )}
              </dd>
              <dt>Trạng thái tan làm:</dt>
              <dd style={{ margin: '0 5px', borderBottom: '1px solid gray' }}>
                {item.absent === true ? (
                  <span style={{ color: '#f79e00' }}>Không xác định</span>
                ) : (
                  <div>
                    {item.early === true ? (
                      <span style={{ color: 'red' }}>Về sớm</span>
                    ) : (
                      'Đúng giờ'
                    )}
                  </div>
                )}
              </dd>
              <dt>Kết quả chấm công:</dt>
              <dd style={{ margin: '0 5px', borderBottom: '1px solid gray' }}>
                {item?.summaryText ? (
                  item.summaryText
                ) : (
                  <EmptyText>Không có dữ liệu</EmptyText>
                )}
              </dd>
              <dt>Danh sách hành chính cùng ngày:</dt>
              <dd style={{ margin: '0 5px', borderBottom: '1px solid gray' }}>
                {item.proposalList.length >= 1 ? (
                  item.proposalList?.map((item, index) => (
                    <div key={index}>
                      {link?.map((link, linkIndex) =>
                        item.status === 'APPROVAL' ? (
                          <a
                            key={linkIndex}
                            href={`${link.url}/proposal/other/view/${item.proposalCode}`}
                            target="_blank">
                            <span style={{ marginRight: '3px' }}>
                              {item.title}
                            </span>
                          </a>
                        ) : (
                          <Tooltip
                            key={linkIndex}
                            title={
                              item.status === 'APPROVAL'
                                ? 'Đơn đã duyệt'
                                : 'Đơn chưa được duyệt'
                            }>
                            <a
                              href={`${link.url}/proposal/other/view/${item.proposalCode}`}
                              target="_blank"
                              style={{
                                color: 'red',
                              }}>
                              <span style={{ marginRight: '3px' }}>
                                {item.title}
                              </span>
                            </a>
                          </Tooltip>
                        )
                      )}
                    </div>
                  ))
                ) : (
                  <EmptyText>Không có dữ liệu</EmptyText>
                )}
              </dd>
              <button style={{ margin: '5px' }}>
                {link?.map((item, index) => (
                  <a
                    key={index}
                    href={`${item.url}/proposal`}
                    target="_blank"
                    color="fff">
                    Tạo hành chính <DoubleRightOutlined />
                  </a>
                ))}
              </button>
            </div>
          </div>
        </div>
      )):""}
    </div>
  )
}

TimekeepingInfo.propTypes = {}

export default memo(
  inject('loadingAnimationStore', 'commandStore')(observer(TimekeepingInfo))
)
