import { Checkbox, Col, Form, Row, Select } from 'antd'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import EmptyContent from '../../components/EmptyContent'

const { Option } = Select

const AdvanceFilterBlock = props => {
  const {
    visible,
    timekeepingStore,
    titleSearch,
    aclStore,
    authenticationStore,
    setQueryStringParsed,
    queryStringParsed,
    departmentList,
  } = props
  const [form] = Form.useForm()
  const { queryParams } = timekeepingStore
  const isChiefOfDepartment = aclStore.aclActionsByUser.some(
    aclAction => aclAction.code === 'view_TRUONG_BAN' && aclAction.status
  )
  const isHr = aclStore.aclActionsByUser.some(
    aclAction => aclAction.code === 'view_CHAM_CONG_QLNS' && aclAction.status
  )
  const isChiefOfDepartmentButNotAdmin =
    isChiefOfDepartment &&
    !authenticationStore.isAdmin &&
    !authenticationStore.isSuperAdmin

  useEffect(() => {
    form.resetFields()
    if (!authenticationStore.currentUser) {
      return
    }

    const currentUserDepartmentCode =
      authenticationStore?.currentUser?.department?.code
    if (
      isChiefOfDepartmentButNotAdmin &&
      form.getFieldValue('department') !== currentUserDepartmentCode
    ) {
      form.setFieldsValue({ department: currentUserDepartmentCode })
      handleSearchByDe(currentUserDepartmentCode)
    }
  }, [
    form,
    authenticationStore.currentUser?.department?.code,
    isChiefOfDepartmentButNotAdmin,
  ])

  const handleSearch = async value => {
    setQueryStringParsed(prevState => ({
      ...prevState,
      late: value,
      page: 0,
      size: 10,
    }))
  }

  const handleSearchByEarly = async value => {
    setQueryStringParsed(prevState => ({
      ...prevState,
      early: value,
      page: 0,
      size: 10,
    }))
  }

  const handleSearchByDe = async value => {
    setQueryStringParsed(prevState => ({
      ...prevState,
      deId: value,
      page: 0,
      size: 10,
    }))
  }

  const handleSearchByProposal = async value => {
    setQueryStringParsed(prevState => ({
      ...prevState,
      existProposal: value?.target?.checked,
      page: 0,
      size: 10,
    }))
  }

  const handleSearchByAb = async value => {
    setQueryStringParsed(prevState => ({
      ...prevState,
      absent: value,
      page: 0,
      size: 10,
    }))
  }

  if (!visible) {
    return null
  }

  return (
    <ContentBlockWrapper>
      <Form
        scrollToFirstError
        form={form}
        layout={'vertical'}
        initialValues={{ title: titleSearch }}>
        <Row style={{ display: 'flex' }} gutter={15}>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label={'Đi muộn'} name={'title'}>
              <Select
                onChange={handleSearch}
                allowClear
                notFoundContent={<EmptyContent />}
                placeholder={'-- Chọn trạng thái đi muộn--'}>
                <Option value={true}>Có</Option>
                <Option value={false}>Không</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label={'Vắng mặt'} name={'timekeeping_ab_type'}>
              <Select
                onChange={handleSearchByAb}
                allowClear
                notFoundContent={<EmptyContent />}
                placeholder={'-- Chọn trạng thái vắng mặt--'}>
                <Option value={true}>Vắng mặt</Option>
                <Option value={false}>Có mặt</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label={'Về sớm'} name={'timekeeping_e_type'}>
              <Select
                onChange={handleSearchByEarly}
                allowClear
                notFoundContent={<EmptyContent />}
                placeholder={'-- Chọn trạng thái về sớm --'}>
                <Option value={true}>Có</Option>
                <Option value={false}>Không</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label={'Bộ phận'} name={'department'}>
              <Select
                mode="multiple"
                disabled={isChiefOfDepartmentButNotAdmin && !isHr}
                style={{ width: '100%' }}
                allowClear
                onChange={handleSearchByDe}
                filterOption={(inputValue, option) => {
                  return option.children
                    .toLowerCase()
                    .includes(inputValue.toLowerCase().trim())
                }}
                placeholder={'-- Chọn bộ phận --'}>
                {departmentList
                  .slice()
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((department, index) => {
                    return (
                      <Option key={index} value={department.code}>
                        {department.name}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label={'Lọc ngày có hành chính'} name={'existProposal'}>
              <Checkbox name="existProposal" onChange={handleSearchByProposal}>
                <span
                  style={{
                    color: '#000',
                    fontWeight: 'bold',
                    transform: 'translateY(-1px)',
                    display: 'inline-block',
                  }}>
                  Lọc ngày có hành chính
                </span>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ContentBlockWrapper>
  )
}

AdvanceFilterBlock.propTypes = {
  visible: PropTypes.bool,
  titleSearch: PropTypes.string,
}

export default inject(
  'timekeepingStore',
  'loadingAnimationStore',
  'aclStore',
  'authenticationStore'
)(observer(AdvanceFilterBlock))
