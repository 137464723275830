import React, { memo, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { Container } from '../../layouts/Container/Container'
import { CONTAINER_MAX_WIDTH, layout } from '../../ui_constants'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../layouts/DashboardLayout'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { Button, Form, Input, message, Row, Upload } from 'antd'
import validator from '../../validator'
// import { FormButtonGroup } from '../TaskManagementCreatePage/TaskManagementCreatePageStyled'
import { useLocation, withRouter } from 'react-router-dom'
import RichEditor from '../../components/RichEditor/RichEditor'
import { UploadOutlined } from '@ant-design/icons'
import UploadFileItem from '../../components/Common/FileItemComponent/UploadFileItem'
import utils from '../../utils'
import { UploadFileListWrapper } from '../../components/CommentComponent/CommentComponentStyled'
import queryString from 'query-string'

const TinTucCreatePage = props => {
  const location = useLocation()
  const queryStringParse = queryString.parse(location.search, {
    arrayFormat: 'index',
  })
  const {
    history,
    loadingAnimationStore,
    authenticationStore,
    tintucStore,
    fileStore,
    match,
  } = props

  const { id } = match.params
  const { newDetails } = tintucStore

  const EDITOR_REF = useRef()
  const [form] = Form.useForm()

  const [fileList, setFileList] = useState([])
  const [fileListUpdate, setFileListUpdate] = useState([])
  const [editorContent, setEditorContent] = useState(null)

  const handleChangeFile = info => {
    if (info.fileList.length === 0) {
      form.setFieldsValue({ files: undefined })
      setFileList([])
      return
    }
    const filteredFileList = info.fileList.filter(
      (elem, index, fileList) =>
        fileList.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }

  const handleRemoveFileFromUploadList = fileUID => {
    const newFileList = fileList.filter(file => file.uid !== fileUID)
    setFileList(newFileList)
  }
  const handleRemoveFileFromListUpdate = file_id => {
    const newFileListUpdate = fileListUpdate.filter(
      file => file.file_id !== file_id
    )
    setFileListUpdate(newFileListUpdate)
  }

  const onFinish = async values => {
    loadingAnimationStore.showSpinner(true)

    const uploadFileList = fileList
      ? fileList.map(file => file.originFileObj)
      : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    const fileListUpdateOrigin =
      newDetails && newDetails.attachments?.map(file => file.file_id)
    const fileListUpdateAfter =
      fileListUpdate && fileListUpdate?.map(file => file.file_id)

    try {
      const response = await Promise.all(batchUploadArr)
      let submitValues = {
        ...values,
        id: null,
        attachments_request: {
          new_items: response.map(response => response.data.file_id),
        },
        content: EDITOR_REF.current.editor.getData(),
        author: {
          name_lowercase: authenticationStore.currentUser.name_lowercase,
          user_name: authenticationStore.currentUser.username,
        },
      }
      if (id) {
        let valuesUpdate = {
          ...submitValues,
          id: id,
          attachments_request: {
            new_items: response.map(response => response.data.file_id),
            remove_items: fileListUpdateOrigin?.filter(
              file => !fileListUpdateAfter.includes(file)
            ),
          },
        }
        await tintucStore.update(valuesUpdate)
        message.success('Sửa thông báo thành công!')
      }
      if (!id) {
        await tintucStore.create(submitValues)
        message.success('Tạo thông báo thành công!')
      }
      if (queryStringParse.isDetail && id) {
        history.push(`/utility/general-notifications/view/${id}`)
      } else {
        history.push('/utility/general-notifications')
      }
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const renderFileList = fileList.length !== 0 && (
    <UploadFileListWrapper>
      {fileList.map(file => (
        <UploadFileItem
          key={file.uid}
          file_id={file.uid}
          file_name={file.name}
          file_type={utils.getExtensionFile(file.name)}
          handleRemoveFileFromUploadList={() =>
            handleRemoveFileFromUploadList(file.uid)
          }
        />
      ))}
    </UploadFileListWrapper>
  )
  const renderFileListUpdate = fileListUpdate && (
    <UploadFileListWrapper>
      {fileListUpdate.map(file => (
        <UploadFileItem
          key={file.file_id}
          file_id={file.file_id}
          file_name={file.file_name}
          file_type={utils.getExtensionFile(file.file_name)}
          isDownloadFile={true}
          handleRemoveFileFromUploadList={() =>
            handleRemoveFileFromListUpdate(file.file_id)
          }
        />
      ))}
    </UploadFileListWrapper>
  )

  useEffect(() => {
    if (!id) return
    ;(async () => {
      try {
        loadingAnimationStore.showSpinner(true)
        const res = await tintucStore.getById(id)
        setEditorContent(res.data?.content)
        setFileListUpdate(res.data?.attachments)
        form.setFieldsValue({
          subject: res.data?.subject,
        })
      } catch (error) {
        history.push('/utility/general-notifications')
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
  }, [id])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Đăng thông báo</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={id ? 'Sửa thông tin' : 'Đăng thông báo'}
        showTitle={true}
      />
      <ContentBlockWrapper>
        <Container maxWidth={CONTAINER_MAX_WIDTH}>
          <Form
            {...layout}
            scrollToFirstError={true}
            form={form}
            style={{ paddingTop: '2rem' }}
            onFinish={onFinish}
            name={'create-general-notifications-form'}>
            <Form.Item
              name={'subject'}
              label={'Tiêu đề'}
              rules={[
                { required: true, message: 'Vui lòng nhập tiêu đề!' },
                { validator: validator.validateInputString },
              ]}>
              <Input placeholder={'Nhập tiêu đề'} />
            </Form.Item>
            <Form.Item label={'Nội dung'} name={'content'}>
              <RichEditor
                EDITOR_REF={EDITOR_REF}
                placeholder={'Nhập nội dung'}
                editorContent={editorContent}
              />
            </Form.Item>
            <Form.Item label={'Tài liệu đính kèm'} name={'files'}>
              <Upload
                valuePropName={'fileList'}
                fileList={fileList}
                onChange={handleChangeFile}
                beforeUpload={() => false}
                multiple={true}
                showUploadList={false}>
                <Button icon={<UploadOutlined />}>
                  Chọn tài liệu đính kèm
                </Button>
              </Upload>
              {renderFileList}
              {renderFileListUpdate}
            </Form.Item>
          </Form>
        </Container>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

TinTucCreatePage.propTypes = {}

export default memo(
  withRouter(
    inject(
      'history',
      'fileStore',
      'tintucStore',
      'loadingAnimationStore',
      'authenticationStore',
    )(observer(TinTucCreatePage)),
  ),
)
