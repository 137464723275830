import { FormOutlined } from '@ant-design/icons'
import { Button, Input, message } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { useHistory, useParams } from 'react-router-dom'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent/SpinnerInlineComponent'
import { ACL_TYPE } from '../../constants'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import ACLActionCard from './ACLActionCard'

const AclCreatePage = props => {
  const { aclStore } = props

  let { code: aclCode } = useParams()
  const { aclActionList } = aclStore

  const [nameACL, setNameACL] = useState('')
  const [HO_SO_THONG_TIN_actions, set_HO_SO_THONG_TIN_actions] = useState([])
  const [LUONG_THUE_BH_actions, set_LUONG_THUE_BH_actions] = useState([])
  const [QUAN_TRI_actions, set_QUAN_TRI_actions] = useState([])

  useEffect(() => {
    const hoSoThongTinActions = []
    const luongThueBHActions = []
    const quanTriActions = []
    aclActionList.forEach(item => {
      if (item.tag === ACL_TYPE.HO_SO_THONG_TIN) {
        hoSoThongTinActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.LUONG_THUE_BH) {
        luongThueBHActions.push(toJS(item))
      }
      if (item.tag === ACL_TYPE.QUAN_TRI) {
        quanTriActions.push(toJS(item))
      }
    })
    set_HO_SO_THONG_TIN_actions(hoSoThongTinActions)
    set_LUONG_THUE_BH_actions(luongThueBHActions)
    set_QUAN_TRI_actions(quanTriActions)
  }, [aclActionList])

  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        if (aclCode) {
          const res = await aclStore.getACLDetail(aclCode)
          setNameACL(res.data.name)
        } else {
          await aclStore.getACLActionList()
        }
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoading(false)
      }
    })()
    return () => {
      aclStore.clearActionList()
    }
  }, [])

  const handleSave = async () => {
    const payload = {
      name: nameACL,
      actions: aclStore.aclActionUpdateList.map(item => ({
        code: item.code,
        status: item.status,
      })),
    }
    try {
      setIsSaving(true)
      if (aclCode) {
        await aclStore.updateACLGroup(aclCode, payload)
        message.success('Lưu thành công!')
      } else {
        await aclStore.createACLGroup(payload)
        history.push('/acl')
        message.success('Tạo phân quyền thành công!')
      }
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsSaving(false)
    }
  }

  const ACLRows = [1]
  const ACLList = [
    {
      type: ACL_TYPE.HO_SO_THONG_TIN,
      name: 'Hồ sơ thông tin',
      actionList: HO_SO_THONG_TIN_actions,
      row: 1,
    },
    {
      type: ACL_TYPE.LUONG_THUE_BH,
      name: 'Lương - Thuế - BH',
      actionList: LUONG_THUE_BH_actions,
      row: 1,
    },
    {
      type: ACL_TYPE.QUAN_TRI,
      name: 'Quản trị',
      actionList: QUAN_TRI_actions,
      row: 1,
    },
  ]

  return (
    <DashboardLayout>
      <Helmet>
        <title>Thiết lập phân quyền | Thiết lập phân quyền</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={aclCode ? 'Cập nhật phân quyền' : 'Tạo mới phân quyền'}
        showTitle={true}
      />
      <ContentBlockWrapper
        style={{
          display: isLoading ? 'none' : 'block',
          minHeight: 'calc(100vh - 120px)',
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <h2>Thiết lập phân quyền</h2>
          <Button
            loading={isSaving}
            onClick={handleSave}
            type={'primary'}
            style={{ minWidth: 130 }}
            icon={<FormOutlined />}>
            LƯU LẠI
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            gap: 12,
            marginTop: 6,
            marginBottom: 20,
            alignItems: 'center',
          }}>
          <div>Tên quyền thao tác:</div>
          <Input
            value={nameACL}
            style={{ width: 250 }}
            placeholder={'Nhập tên quyền...'}
            onChange={e => setNameACL(e.target.value)}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
          {ACLRows.map(row => {
            return (
              <div
                key={row}
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 30,
                }}>
                {ACLList.filter(item => item.row === row).map((item, i) => (
                  <ACLActionCard
                    key={i}
                    title={item.name}
                    actionList={item.actionList}
                  />
                ))}
              </div>
            )
          })}
        </div>
      </ContentBlockWrapper>
      {isLoading && (
        <ContentBlockWrapper
          style={{ position: 'relative', height: 'calc(100vh - 180px)' }}>
          <SpinnerInlineComponent
            noBackground
            style={{ borderRadius: 8 }}
            sizeSpin={'small'}
            isLoading={true}
            alignItems
            tip={'Đang tải dữ liệu...'}
          />
        </ContentBlockWrapper>
      )}
    </DashboardLayout>
  )
}

AclCreatePage.propTypes = {}

export default inject('aclStore')(observer(AclCreatePage))
