import React, { useCallback, useEffect, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import { toJS } from 'mobx'
import PageTitle from '../../../components/PageTitle'
import { HeadingButtonWrapper } from '../../MissionDetailPage/MissionDetailPageStyled'
import { Dropdown } from 'antd'
import {
  FileExcelOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileWordOutlined,
  FileZipOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import { blue, green, purple9, redPrimary } from '../../../color'
import {
  DATE_FORMAT_DEFAULT,
  PROPOSAL_TYPE,
  TYPE_STATUS,
} from '../../../constants'
import { StatusTag } from '../../../components/Common/StatusTag'
import {
  AttachmentList,
  BlockLeft,
  BlockRight,
  ContentWrapper,
  ViewDetailContent,
} from './ProposalDetailPageStyle'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import { EmptyText, TitleContentBox } from '../../../components/Common/CellText'
import { DocumentInfoList } from '../../../layouts/ThongTinChungLayout/ThongTinChungLayoutStyled'
import PopoverWatchTime from '../../../components/PopoverWatchTime'
import moment from 'moment'
import { useParams, useLocation } from 'react-router-dom'
import ProposalDetailAction from './ProposalDetailAction'
import FileItem from '../../../components/Common/FileItemComponent/FileItem'
import ProposalComment from './ProposalComment'
import utils from '../../../utils'
import SpinnerInlineComponent from '../../../components/SpinnerInlineComponent'

const ProposalDetail = props => {
  const {
    proposalStore,

    commonStore,
  } = props
  let proposalId = props.match.params.id
  const { otherDetail, otherCommentList } = proposalStore

  useEffect(() => {
    proposalStore.getOtherDetail(proposalId)
  }, [])

  const renderTitlePage = useMemo(() => {
    for (const [value] of Object.entries(PROPOSAL_TYPE)) {
      if (otherDetail?.proposal_type === value) {
        return PROPOSAL_TYPE[value].label
      }
    }
  }, [otherDetail])

  /** Hiển thị danh sách tài liệu đính kèm */
  const renderFileIcon = file_type => {
    let iconFile = <FileTextOutlined />
    if (file_type === 'pdf') {
      iconFile = <FilePdfOutlined style={{ color: redPrimary }} />
      return iconFile
    }
    if (file_type === 'doc' || file_type === 'docx') {
      iconFile = <FileWordOutlined style={{ color: blue }} />
      return iconFile
    }
    if (
      [
        'jpg',
        'bmp',
        'dib',
        'jpe',
        'jfif',
        'gif',
        'tif',
        'tiff',
        'png',
        'heic',
      ].includes(file_type)
    ) {
      iconFile = <FileImageOutlined style={{ color: purple9 }} />
      return iconFile
    }
    if (file_type === 'rar' || file_type === 'zip') {
      iconFile = <FileZipOutlined style={{ color: '#e67e22' }} />
      return iconFile
    }
    if (file_type === 'xlsx') {
      iconFile = <FileExcelOutlined style={{ color: green }} />
      return iconFile
    }
  }

  const renderAttachmentList = useCallback(() => {
    return otherDetail.attachments?.map(item => {
      return (
        <FileItem
          key={item.file_id}
          file_id={item.file_id}
          file_name={item.file_name}
          file_type={utils.getExtensionFile(item.file_name)}
        />
      )
    })
  }, [commonStore.appTheme, renderFileIcon, otherDetail])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Chi tiết hành chính| Hành Chính</title>
      </Helmet>
      <PageTitle
        location={props.location}
        showTitle={true}
        title={otherDetail?.title}
        status={StatusTag(TYPE_STATUS.PROPOSAL, otherDetail?.proposal_status)}>
        {/* <HeadingButtonWrapper>
          {checkShowHandleDocument() ? (
            <Dropdown overlay={menuHandleDocument} trigger={['click']}>
              <span className={'btn-style'}>
                <MoreOutlined style={{ color: blue }} />
              </span>
            </Dropdown>
          ) : (
            <></>
          )}
        </HeadingButtonWrapper> */}
      </PageTitle>
      {otherDetail && !otherDetail?.has_no_config_approval_levels ? (
        <ProposalDetailAction />
      ) : (
        <></>
      )}

      <ViewDetailContent>
        <ContentWrapper>
          <BlockLeft>
            <ContentBlockWrapper>
              <TitleContentBox>Nội dung</TitleContentBox>
              {/* {typePage === 'vehicle' ? (
                <ProposalDetailVehiclePage proposalId={proposalId} />
              ) : typePage === 'other' ? (
                <ProposalDetailOtherPage proposalId={proposalId} />
              ) : (
                <ProposalDetailLeavesPage proposalId={proposalId} />
              )} */}
              {otherDetail?.reason ? (
                <div
                  dangerouslySetInnerHTML={{ __html: otherDetail?.reason }}
                />
              ) : (
                <EmptyText>Không có nội dung.</EmptyText>
              )}
            </ContentBlockWrapper>
          </BlockLeft>
          <BlockRight>
            <ContentBlockWrapper>
              <TitleContentBox>Thông tin chung</TitleContentBox>
              <DocumentInfoList>
                <dt>Loại</dt>
                <dd>{renderTitlePage}</dd>
                {otherDetail?.proposal_type ===
                PROPOSAL_TYPE.LEAVE_ABNORMAL.key ? (
                  <>
                    <dt>Lý do</dt>
                    <dd>
                      {otherDetail?.reason_for_leaving ? (
                        <span>{otherDetail?.reason_for_leaving}</span>
                      ) : (
                        <EmptyText>Không có lý do.</EmptyText>
                      )}
                    </dd>
                  </>
                ) : (
                  <></>
                )}
                <dt>Tài liệu đính kèm</dt>
                <dd>
                  {otherDetail?.attachments?.length !== 0 ? (
                    <>
                      <AttachmentList>{renderAttachmentList()}</AttachmentList>
                    </>
                  ) : (
                    <EmptyText>Không có tài liệu đính kèm.</EmptyText>
                  )}
                </dd>

                <dt>Người theo dõi</dt>
                <dd>
                  {proposalStore.otherDetail?.followers &&
                  proposalStore.otherDetail?.followers.length > 0 ? (
                    <PopoverWatchTime
                      userList={proposalStore.otherDetail?.followers.map(
                        item => {
                          return {
                            full_name: item.name_lowercase,
                            id: item.user_name,
                            assignee_type: 'USER',
                            last_date_read: item.last_date_read,
                          }
                        }
                      )}
                      title={'Danh sách người theo dõi'}
                    />
                  ) : (
                    <EmptyText>Không có người theo dõi.</EmptyText>
                  )}
                </dd>
                <dt>Ngày bắt đầu</dt>
                <dd>
                  {otherDetail?.from_date == null ||
                  otherDetail?.from_date === '' ? (
                    <EmptyText>Không có ngày bắt đầu.</EmptyText>
                  ) : (
                    moment(otherDetail?.to_date).format(DATE_FORMAT_DEFAULT)
                  )}
                </dd>
                <dt>Ngày kết thúc</dt>
                <dd>
                  {otherDetail?.to_date == null ||
                  otherDetail?.to_date === '' ? (
                    <EmptyText>Không có ngày kết thúc.</EmptyText>
                  ) : (
                    moment(otherDetail?.to_date).format(DATE_FORMAT_DEFAULT)
                  )}
                </dd>

                {otherDetail?.proposal_type ===
                  PROPOSAL_TYPE.OVERTIME_NORMAL.key ||
                otherDetail?.proposal_type ===
                  PROPOSAL_TYPE.OVERTIME_DAYOFF.key ||
                otherDetail?.proposal_type ===
                  PROPOSAL_TYPE.OVERTIME_HOLIDAY.key ? (
                  <>
                    <dt>Số giờ làm thêm</dt>
                    <dd>
                      {otherDetail?.overtime ? (
                        <span>{otherDetail?.overtime} giờ</span>
                      ) : (
                        <EmptyText>Không có số giờ làm thêm.</EmptyText>
                      )}
                    </dd>
                    <dt>Số giờ được duyệt</dt>
                    <dd>
                      {otherDetail?.approvalOvertime ? (
                        <span>{otherDetail?.approvalOvertime} giờ</span>
                      ) : (
                        <EmptyText>Không có số giờ được duyệt.</EmptyText>
                      )}
                    </dd>
                  </>
                ) : (
                  <></>
                )}
              </DocumentInfoList>
            </ContentBlockWrapper>

            <div style={{ marginBottom: 15 }} />

            {/* <ContentBlockWrapper style={{ position: 'relative' }}>
              <ProposalComment proposalId={proposalId} />
              <SpinnerInlineComponent sizeSpin={'small'} />
            </ContentBlockWrapper> */}
          </BlockRight>
        </ContentWrapper>
      </ViewDetailContent>
    </DashboardLayout>
  )
}

export default inject(
  'proposalStore',
  'notificationStore',
  'aclStore',
  'commonStore',
  'authenticationStore'
)(observer(ProposalDetail))
