import { BankOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Space,
  Tag,
  Tooltip,
  message,
} from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { useHistory, useLocation } from 'react-router-dom'
import { blue } from '../../color'
import { AvatarHasName } from '../../components/Common/Avatar'
import { SelectList } from '../../components/Common/SelectListComponent/SelectListComponentStyled'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import SelectPeoplePopup from '../../components/SelectPeoplePopup/SelectPeoplePopup'
import {
  ASSIGNEE_TYPE,
  DATE_FORMAT_DEFAULT,
  ISO_DATE_FORMAT,
} from '../../constants'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'

const { RangePicker } = DatePicker

const TimekeepingSyncPage = props => {
  const { timekeepingStore, selectPeopleStore } = props
  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    setIsVisibleSelectUserDepartmentPopup,
  } = selectPeopleStore
  const history = useHistory()
  const location = useLocation()
  const [userList, setUserList] = useState([])
  const [loadingAcs, setLoadingAcs] = useState(false)
  const [loadingSync, setLoadingSync] = useState(false)
  const queryStringParse = queryString.parse(location.search)
  const [form] = Form.useForm()

  useEffect(() => {
    setUserList([
      ...selectUserData[ASSIGNEE_TYPE.USER],
      ...selectDepartmentData[ASSIGNEE_TYPE.USER],
      ...selectGroupData[ASSIGNEE_TYPE.USER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  useEffect(() => {
    return () => {
      selectPeopleStore.clearSelectPeopleStore()
    }
  }, [])

  const handleSyncTimekeeping = async () => {
    const { date_picker } = form.getFieldsValue()
    const username = userList[0]?.id ?? null

    let fromDate = null
    let toDate = null

    if (date_picker && date_picker.length === 2) {
      fromDate = moment(date_picker[0]).format(ISO_DATE_FORMAT)
      toDate = moment(date_picker[1]).format(ISO_DATE_FORMAT)
    }
    setLoadingAcs(true)
    timekeepingStore
      .syncTimekeeping(fromDate, toDate, username)
      .then(() => {
        message.info('Chấm công thành công!')
      })
      .catch(() => message.info('Đồng bộ thất bại!'))
      .finally(() => {
        setLoadingAcs(false)
      })
  }
  function disabledDate(currentDate) {
    // Lấy ngày hôm qua
    // const yesterday = moment().subtract(1, 'day').endOf('day')
    // Lấy đến ngày hiện tại
    const yesterday = moment().endOf('day')
    // Bỏ qua các ngày lớn hơn ngày hôm qua
    return currentDate && currentDate > yesterday
  }
  const onItemClick = async () => {
    const { date_picker } = form.getFieldsValue()
    let fromDate = null
    let toDate = null

    if (date_picker && date_picker.length === 2) {
      fromDate = moment(date_picker[0]).format(ISO_DATE_FORMAT)
      toDate = moment(date_picker[1]).format(ISO_DATE_FORMAT)
    }
    setLoadingSync(true)
    timekeepingStore
      .timekeepingSync({
        fromDate: fromDate,
        toDate: toDate,
      })
      .then(() => message.info('Đồng bộ thành công!'))
      .catch(() => message.info('Đồng bộ lỗi!'))
      .finally(() => setLoadingSync(false))
  }

  useEffect(() => {
    if (queryStringParse.assignee) {
      const itemInfo = queryStringParse.assignee.split(',')
      selectPeopleStore.setSelectUserData({
        ...selectPeopleStore.selectUserData,
        [ASSIGNEE_TYPE.USER]: [
          {
            assignee_type: ASSIGNEE_TYPE.USER,
            full_name: itemInfo[1],
            id: itemInfo[0],
            permission: ASSIGNEE_TYPE.USER,
            department_code: null,
            department_name: null,
            image_id: null,
            position_name: null,
          },
        ],
      })
    }
  }, [])

  useEffect(() => {
    if (userList?.length === 0) return
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        assignee: `${userList[0]?.id},${userList[0]?.full_name}`,
      },
      {
        skipNull: true,
      }
    )

    history.replace(`/management/timekeepingSync?${queryStringStringify}`)
  }, [userList])

  const onChangeDate = async date => {
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        fromDate: date ? moment(date[0]).format(ISO_DATE_FORMAT) : null,
        toDate: date ? moment(date[1]).format(ISO_DATE_FORMAT) : null,
      },
      {
        skipNull: true,
      }
    )

    history.replace(`/management/timekeepingSync?${queryStringStringify}`)
  }
  return (
    <DashboardLayout key="thead">
      <Helmet>
        <title>Chấm công</title>
      </Helmet>

      <PageTitle
        location={props.location}
        title={'Chấm công'}
        hiddenGoBack></PageTitle>

      <ContentBlockWrapper>
        <Row
          style={{ marginBottom: 30, display: 'flex', alignItems: 'center' }}>
          <Col
            xs={24}
            sm={12}
            md={10}
            style={{ display: 'flex', alignItems: 'center' }}>
            <span>Chọn người:</span>
            <Space>
              <div style={{ marginLeft: 5 }}>
                <SelectList>
                  {userList.map(el => (
                    <Tag
                      className={'tag-selected'}
                      color={'blue'}
                      key={el.id}
                      closable
                      onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                      <AvatarHasName
                        imgId={el.image_id}
                        size={22}
                        name={el.full_name}
                        icon={
                          (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                            <BankOutlined />
                          )) ||
                          (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                            <TeamOutlined />
                          ))
                        }
                      />
                    </Tag>
                  ))}
                </SelectList>
              </div>

              <Tooltip title="Chọn người dùng">
                <Button
                  style={{ backgroundColor: '#e6f7ff', borderColor: '#4d81b8' }}
                  icon={<UserOutlined style={{ color: blue }} />}
                  onClick={() =>
                    selectPeopleStore.handleOpenSelectUserDepartmentPopup(
                      ASSIGNEE_TYPE.USER
                    )
                  }
                />
              </Tooltip>
            </Space>
          </Col>
        </Row>

        <Form scrollToFirstError={true} form={form} layout={'vertical'}>
          <Row>
            <Col xs={24} sm={12} md={10}>
              <span style={{ marginRight: 13 }}>Chọn ngày:</span>
              <Space>
                <Form.Item name={'date_picker'}>
                  <RangePicker
                    onChange={onChangeDate}
                    defaultValue={
                      queryStringParse.fromDate && queryStringParse.toDate
                        ? [
                            moment(queryStringParse.fromDate),
                            moment(queryStringParse.toDate),
                          ]
                        : null
                    }
                    format={[DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT]}
                    style={{ width: '100%', borderColor: '#4d81b8' }}
                    disabledDate={disabledDate} // Giới hạn không cho chọn quá ngày hiện tại
                  />
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
        <Button
          type={'primary'}
          loading={loadingAcs}
          onClick={() => {
            handleSyncTimekeeping()
          }}>
          Chấm công
        </Button>
        <Button
          style={{ margin: '0 15px' }}
          type={'primary'}
          loading={loadingSync}
          onClick={() => {
            onItemClick()
          }}>
          Đồng bộ dữ liệu
        </Button>
        <SelectPeoplePopup
          id={'list-work-select-people-popup'}
          onlySelectOne
          disableSelectDepartment
          handleCancelSelectUser={handleCancelSelectUserDepartment}
          handleSubmitSelectUser={() =>
            setIsVisibleSelectUserDepartmentPopup(false)
          }
          isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
        />
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

TimekeepingSyncPage.propTypes = {}

export default inject(
  'timekeepingStore',
  'selectPeopleStore'
)(observer(TimekeepingSyncPage))
