import styled from 'styled-components'

export const TR = styled.tr`
  border: 1px solid;
  width: 500px;
`
export const TH = styled.th`
  border: 1px solid;
  width: 500px;
`
export const TD = styled.td`
  border: 1px solid;
  width: 500px;
`
