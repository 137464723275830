import React, { useEffect, useState } from 'react'
import { InfoList } from './MyProfilePageStyled'
import { Button, Col, message, Row, Space, Form, Input } from 'antd'
import { inject, observer } from 'mobx-react'
import { EmptyText } from '../../components/Common/CellText'
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  MailFilled,
} from '@ant-design/icons'
import validator from '../../validator'

const Job = props => {
  const {
    authenticationStore,
    loadingAnimationStore,
    userStore,
    aclStore,
  } = props

  const { currentUser } = authenticationStore
  const nullText = <EmptyText>Chưa cập nhật</EmptyText>

  const [editing, setEditing] = useState(false)

  const [fields, setFields] = useState([
    {
      name: ['occupation'],
      value: undefined,
    },
    {
      name: ['recruitment_day'],
      value: undefined,
    },
    {
      name: ['civil_servant_rank'],
      value: undefined,
    },
    {
      name: ['quota_code'],
      value: undefined,
    },
    {
      name: ['main_work'],
      value: undefined,
    },
    {
      name: ['salary_scale'],
      value: undefined,
    },
    {
      name: ['coefficient'],
      value: undefined,
    },
    {
      name: ['received_date'],
      value: undefined,
    },
    {
      name: ['position_allowance'],
      value: undefined,
    },
    {
      name: ['other_allowances'],
      value: undefined,
    },
  ])

  const setFieldsOrigin = () => {
    setFields([
      {
        name: ['occupation'],
        value: currentUser?.other?.occupation,
      },
      {
        name: ['recruitment_day'],
        value: currentUser?.other?.recruitment_day,
      },
      {
        name: ['civil_servant_rank'],
        value: currentUser?.other?.civil_servant_rank,
      },
      {
        name: ['quota_code'],
        value: currentUser?.other?.quota_code,
      },
      {
        name: ['main_work'],
        value: currentUser?.other?.main_work,
      },
      {
        name: ['salary_scale'],
        value: currentUser?.other?.salary_scale,
      },
      {
        name: ['coefficient'],
        value: currentUser?.other?.coefficient,
      },
      {
        name: ['received_date'],
        value: currentUser?.other?.received_date,
      },
      {
        name: ['position_allowance'],
        value: currentUser?.other?.position_allowance,
      },
      {
        name: ['other_allowances'],
        value: currentUser?.other?.other_allowances,
      },
    ])
  }
  const onUpdate = async value => {
    loadingAnimationStore.showSpinner(true)
    try {
      await userStore.updateCurrentUserJob(value)
      authenticationStore.checkCurrentUser()
      setEditing(false)
      message.success('Cập nhật thông tin thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  useEffect(() => {
    if (!currentUser) return
    setFieldsOrigin()
  }, [currentUser])

  return (
    <>
      <div className={'overview-info'}>
        <Form onFinish={onUpdate} fields={fields}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div></div>
            {!editing && (
              <Button
                type={'primary'}
                icon={<EditOutlined />}
                onClick={() => setEditing(true)}>
                Chỉnh sửa
              </Button>
            )}
            {editing && (
              <Space>
                <Button
                  onClick={() => {
                    setFieldsOrigin()
                    setEditing(false)
                  }}
                  danger
                  icon={<CloseOutlined />}>
                  Hủy bỏ
                </Button>
                <Button
                  type={'primary'}
                  icon={<CheckOutlined />}
                  htmlType={'submit'}>
                  Lưu thay đổi
                </Button>
              </Space>
            )}
          </div>
          <Row gutter={[20, 10]}>
            <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
              <InfoList>
                <dt>Phòng ban</dt>
                <dd>{currentUser?.department?.name || nullText}</dd>
                <dt>Chức danh</dt>
                <dd>{currentUser?.position?.name || nullText}</dd>
                <dt>Nghề nghiệp khi được tuyển dụng</dt>
                <dd>
                  {!editing ? (
                    <div>{currentUser?.other?.occupation || nullText}</div>
                  ) : (
                    <Form.Item name={'occupation'}>
                      <Input
                        placeholder={'Nghề nghiệp'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}
                </dd>
                <dt>Ngày tuyển dụng</dt>
                <dd>
                  {!editing ? (
                    <div>{currentUser?.other?.recruitment_day || nullText}</div>
                  ) : (
                    <Form.Item
                      rules={[{ validator: validator.validateDate }]}
                      name={'recruitment_day'}>
                      <Input
                        placeholder={'DD-MM-YYYY'}
                        style={{ maxWidth: 250 }}
                        type="text"
                      />
                    </Form.Item>
                  )}
                </dd>
                <dt>Ngạch công chức</dt>
                <dd>
                  {!editing ? (
                    <div>
                      {currentUser?.other?.civil_servant_rank || nullText}
                    </div>
                  ) : (
                    <Form.Item name={'civil_servant_rank'}>
                      <Input
                        placeholder={'Ngạch công chức'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}
                </dd>
                <dt>Mã ngạch</dt>
                <dd>
                  {!editing ? (
                    <div>{currentUser?.other?.quota_code || nullText}</div>
                  ) : (
                    <Form.Item name={'quota_code'}>
                      <Input
                        placeholder={'Mã ngạch'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}
                </dd>
              </InfoList>
            </Col>
            <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
              <InfoList>
                <dt>Công việc chính được giao</dt>
                <dd>
                  {!editing ? (
                    <div>{currentUser?.other?.main_work || nullText}</div>
                  ) : (
                    <Form.Item name={'main_work'}>
                      <Input
                        placeholder={'Công việc chính được giao'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}
                </dd>
                <dt>Bậc lương</dt>
                <dd>
                  {!editing ? (
                    <div>{currentUser?.other?.salary_scale || nullText}</div>
                  ) : (
                    <Form.Item name={'salary_scale'}>
                      <Input
                        placeholder={'Bậc lương'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}
                </dd>
                <dt>Hệ số</dt>
                <dd>
                  {!editing ? (
                    <div>{currentUser?.other?.coefficient || nullText}</div>
                  ) : (
                    <Form.Item name={'coefficient'}>
                      <Input placeholder={'Hệ số'} style={{ maxWidth: 250 }} />
                    </Form.Item>
                  )}
                </dd>
                <dt>Ngày hưởng</dt>
                <dd>
                  {!editing ? (
                    <div>{currentUser?.other?.received_date || nullText}</div>
                  ) : (
                    <Form.Item
                      rules={[{ validator: validator.validateDate }]}
                      name={'received_date'}>
                      <Input
                        placeholder={'DD-MM-YYYY'}
                        style={{ maxWidth: 250 }}
                        type="text"
                      />
                    </Form.Item>
                  )}
                </dd>
                <dt>Phụ cấp chức vụ</dt>
                <dd>
                  {!editing ? (
                    <div>
                      {currentUser?.other?.position_allowance || nullText}
                    </div>
                  ) : (
                    <Form.Item name={'position_allowance'}>
                      <Input placeholder={'VNĐ'} style={{ maxWidth: 250 }} />
                    </Form.Item>
                  )}
                </dd>
                <dt>Phụ cấp khác</dt>
                <dd>
                  {!editing ? (
                    <div>
                      {currentUser?.other?.other_allowances || nullText}
                    </div>
                  ) : (
                    <Form.Item name={'other_allowances'}>
                      <Input placeholder={'VNĐ'} style={{ maxWidth: 250 }} />
                    </Form.Item>
                  )}
                </dd>
              </InfoList>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

Job.propTypes = {}

export default inject(
  'authenticationStore',
  'commonStore',
  'loadingAnimationStore',
  'userStore',
  'aclStore'
)(observer(Job))
