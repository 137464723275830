// Component
import { EmptyText, subStringAvatar } from '../../components/Common/CellText'
//Styled Component
import { OverviewWrapper, UserName } from './MyProfilePageStyled'
// Other
import moment from 'moment'
import { format } from 'path'
import { DATE_FORMAT_SLASH } from '../../constants'
import date_format from './../../date_format'
import React, { useState } from 'react'
import Docxtemplater from 'docxtemplater'
import JSZipUtils from 'jszip-utils'
import { inject, observer } from 'mobx-react'
import PizZip from 'pizzip'
import PizZipUtils from 'pizzip/utils/index.js'
import { saveAs } from 'file-saver'
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'
import { Avatar, Button } from 'antd'
import { ExportOutlined } from '@ant-design/icons'

// hàm load cho file local
function loadFile(url, callback) {
  JSZipUtils.getBinaryContent(url, function (err, data) {
    if (err) {
      callback(err)
      return
    }
    callback(null, data)
  })
}
// hàm load cho link file
// function loadFile(url, callback) {
//   PizZipUtils.getBinaryContent(url, callback)
// }
const printC2Profile = props => {
  const {
    authenticationStore,
    loadingAnimationStore,
    userStore,
    aclStore,
  } = props
  const { currentUser, currentUserAvatar } = authenticationStore
  console.log('currentUser', currentUser)
  console.log('currentUserAvatar', currentUserAvatar)
  const [docs, setDocs] = useState([])
  const generateDocument = () => {
    loadFile('/file/Mau-2C-BNV.docx', function (error, content) {
      if (error) {
        throw error
      }
      var zip = new PizZip(content)
      var doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      })
      var imgData = null
      var xhr = new XMLHttpRequest()
      xhr.open('GET', `${URL.createObjectURL(currentUserAvatar)}`)
      xhr.onload = function (e) {
        if (this.status == 200) {
          imgData = this.response
        }
      }
      doc.setData({
        name_uppercase: currentUser?.name_uppercase,
        acl_group: currentUser?.acl_group,
        address: currentUser?.address,
        birthday: currentUser?.birthday,
        dd: moment(currentUser?.birthday).format('DD'),
        mm: moment(currentUser?.birthday).format('MM'),
        yyyy: moment(currentUser?.birthday).format('YYYY'),
        code: currentUser?.code,
        color: currentUser?.color,
        commands: currentUser?.commands,
        company: currentUser?.company,
        department: currentUser?.department,
        email: currentUser?.email,
        gender:
          currentUser?.gender == 2
            ? 'khác'
            : currentUser?.gender == 1
            ? 'nam'
            : 'nữ',
        home_phone: currentUser?.home_phone,
        imageId: currentUser?.imageId,
        is_admin: currentUser?.is_admin,
        ma_nv: currentUser?.ma_nv,
        name_lowercase: currentUser?.name_lowercase,
        permissions: currentUser?.permissions,
        phone: currentUser?.phone,
        position: currentUser?.position,
        roles: currentUser?.roles,
        status: currentUser?.status,
        updated_at: currentUser?.updated_at,
        username: currentUser?.username,
        IT: currentUser?.other.IT,
        army_rank: currentUser?.other.army_rank,
        blood_types: currentUser?.other.blood_types,
        c_id: currentUser?.other.c_id,
        child_policy_family: currentUser?.other.child_policy_family,
        image: imgData,
      })
      try {
        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.render()
      } catch (error) {
        // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
        function replaceErrors(key, value) {
          if (value instanceof Error) {
            return Object.getOwnPropertyNames(value).reduce(function (
              error,
              key
            ) {
              error[key] = value[key]
              return error
            },
            {})
          }
          return value
        }
        console.log(JSON.stringify({ error: error }, replaceErrors))

        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map(function (error) {
              return error.properties.explanation
            })
            .join('\n')
          console.log('errorMessages', errorMessages)
        }
        throw error
      }
      var out = doc.getZip().generate({
        type: 'blob',
        mimeType:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      }) //Output the document using Data-URI
      setDocs([{ uri: out }])
      saveAs(out, 'Output-Mau-2C-BNV.docx')
    })
  }

  return (
    <div className="p-2">
      <Button style={{ margin: '5px' }} onClick={() => generateDocument()}>
        <ExportOutlined /> Xuất VB Mẫu 2c-BNV
      </Button>
      {/* {docs.length > 0 && (
        <DocViewer pluginRenderers={DocViewerRenderers} documents={docs} />
      )} */}
    </div>
  )
}

printC2Profile.propTypes = {}

export default inject(
  'authenticationStore',
  'commonStore',
  'loadingAnimationStore',
  'userStore',
  'aclStore'
)(observer(printC2Profile))
