import { Button } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { ACL_ACTION_TYPE, PROPOSAL_STATUS } from '../../../constants'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import { TitleContentBox } from '../../../components/Common/CellText'
import AccessControlAction from '../../../components/AccessControlAction'
import ProposalStepApprove from '../../../components/SubmissionForm/ProposalStepApprove'
import ProposalActionConfirmModal from './ProposalActionConfirmModal'

const groupBtnStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 10,
  marginBottom: 10,
}

const ProposalDetailAction = props => {
  const { proposalStore, authenticationStore } = props
  const { currentUser } = authenticationStore
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [confirmModalType, setConfirmModalType] = useState(null)
  const [isShowButtonAction, setIsShowButtonAction] = useState(false)

  useEffect(() => {
    if (!currentUser) return
    const currentStepInfo = proposalStore.otherDetail.approval_levels?.find(
      item => item.status === PROPOSAL_STATUS.PENDING
    )
    if (currentUser.username === currentStepInfo?.username) {
      setIsShowButtonAction(true)
    } else {
      setIsShowButtonAction(false)
    }
  }, [proposalStore.otherDetail, currentUser])

  const handleCloseModal = useCallback(() => {
    setIsConfirmModalVisible(false)
    setConfirmModalType(null)
  }, [])

  const handleOpenConfirmModal = modalType => {
    setConfirmModalType(modalType)
    setIsConfirmModalVisible(true)
  }

  return (
    <ContentBlockWrapper style={{ marginBottom: 15 }}>
      <TitleContentBox>Tiến trình duyệt</TitleContentBox>
      <ProposalStepApprove
        approvalLevelList={proposalStore.otherDetail.approval_levels || []}
      />
      {isShowButtonAction && (
        <div style={groupBtnStyle}>
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.approve__PROPOSAL}>
            <Button
              danger
              onClick={() => handleOpenConfirmModal(PROPOSAL_STATUS.REJECT)}>
              Từ chối
            </Button>
          </AccessControlAction>
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.approve__PROPOSAL}>
            <Button
              type={'primary'}
              onClick={() => handleOpenConfirmModal(PROPOSAL_STATUS.APPROVAL)}>
              Duyệt yêu cầu
            </Button>
          </AccessControlAction>
        </div>
      )}

      <ProposalActionConfirmModal
        isModalVisible={isConfirmModalVisible}
        handleCloseModal={handleCloseModal}
        confirmModalType={confirmModalType}
      />
    </ContentBlockWrapper>
  )
}

ProposalDetailAction.propTypes = {}

export default inject(
  'proposalStore',
  'authenticationStore'
)(observer(ProposalDetailAction))
