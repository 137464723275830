import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { blue } from '../../color'
import { SORT_TYPE } from '../../constants'
// import { HeadingButtonWrapper } from '../../pages/WorkPage/DrawerWork/DrawerWorkStyled'

const MenuItem = styled(Menu.Item)`
  a {
    display: flex;
    gap: 4px;
    color: ${blue};
    align-items: center;
  }
`

const CommentsPopoverMenu = props => {
  const menuButton = () => {
    return (
      <Menu>
        {!props.hideSortMenu && (
          <MenuItem key="sort">
            <a
              style={{
                display: props.sortComment === SORT_TYPE.DESCENDING && 'none',
              }}
              onClick={() =>
                props.handleChangeSortComment(SORT_TYPE.DESCENDING)
              }>
              <ArrowUpOutlined />
              Cũ hơn
            </a>
            <a
              style={{
                display: props.sortComment === SORT_TYPE.ASCENDING && 'none',
              }}
              onClick={() =>
                props.handleChangeSortComment(SORT_TYPE.ASCENDING)
              }>
              <ArrowDownOutlined />
              Mới hơn
            </a>
          </MenuItem>
        )}
        <MenuItem key="showDelete" onClick={props.handleToggleMenu}>
          <a>
            <DeleteOutlined />
            {props.isShow ? 'Ẩn ý kiến đã xóa' : 'Hiện ý kiến đã xóa'}
          </a>
        </MenuItem>
      </Menu>
    )
  }

  return (
    <div>
      <Dropdown overlay={menuButton} trigger={['click']}>
        <a>Tùy chọn</a>
      </Dropdown>
    </div>
  )
}

CommentsPopoverMenu.propTypes = {
  handleShowDeletedComments: Function,
}

export default CommentsPopoverMenu
