import React, { Fragment } from 'react'
import { ProfileGroupWrapper } from './MyProfilePageStyled'
import { Button, Col, Form, Input, message, Row } from 'antd'
import { inject, observer } from 'mobx-react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

const ChangePassword = props => {
  const { userStore, loadingAnimationStore, finishChangePassword } = props

  const [form] = Form.useForm()

  const onFinish = async values => {
    loadingAnimationStore.showSpinner(true)
    try {
      await userStore.updateCurrentUserPassword(values)
      form.resetFields()
      finishChangePassword()
      message.success('Thay đổi mật khẩu thành công!')
    } catch (err) {
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const onCancel = () => {
    form.resetFields()
  }

  return (
    <Fragment>
      <ProfileGroupWrapper>
        <Form
          form={form}
          scrollToFirstError={true}
          onFinish={onFinish}
          colon={true}
          layout="vertical"
          name={'profile-change-password'}
          id={'profile-change-password'}>
          <Row type={'flex'} gutter={30} justify={'space-between'}>
            <Col xs={24} md={12}>
              <Form.Item
                name={'password_old'}
                label={'Mật khẩu cũ'}
                rules={[
                  {
                    required: true,
                    message: 'Mật khẩu cũ không được để trống!',
                  },
                ]}>
                <Input.Password placeholder={'Nhập lại mật khẩu cũ'} />
              </Form.Item>
            </Col>
            <Col span={24}></Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={'password_new'}
                label={'Mật khẩu mới'}
                rules={[
                  {
                    required: true,
                    message: ' Mật khẩu mới không được để trống!',
                  },
                  { min: 5, message: 'Mật khẩu cần có ít nhất 5 ký tự!' },
                ]}>
                <Input.Password placeholder={'Nhập mật khẩu mới'} />
              </Form.Item>
            </Col>
          </Row>
          <Fragment>
            <Button
              danger
              icon={<CloseOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => onCancel()}>
              Huỷ bỏ
            </Button>
            <Button
              icon={<CheckOutlined />}
              form="profile-change-password"
              type="primary"
              key="submit"
              htmlType="submit">
              Lưu thay đổi
            </Button>
          </Fragment>
        </Form>
      </ProfileGroupWrapper>
    </Fragment>
  )
}

ChangePassword.propTypes = {}
export default inject(
  'userStore',
  'loadingAnimationStore'
)(observer(ChangePassword))
