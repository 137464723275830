import React, { useCallback, useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import {
  Button,
  DatePicker,
  message,
  Pagination,
  Space,
  Switch,
  Table,
} from 'antd'
import acsOfficeDayStore from '../../stores/acsOfficeDayStore'
import { EditOutlined } from '@ant-design/icons'
import EditAcsOfficeDayNoteModal from '../../components/EditAcsOfficeDayNoteModal/EditAcsOfficeDayNoteModal'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { TableWrapper } from '../../components/Common/Table'
import { DATE_FORMAT_DEFAULT } from '../../constants'

const AcsOfficeDayPage = props => {
  const { loadingAnimationStore } = props

  const { queryParams } = acsOfficeDayStore
  const [officeDayList, setOfficeDayList] = useState()
  const [officePageSize, setOfficePageSize] = useState()

  const GetAcsOfficeDayList = async () => {
    loadingAnimationStore.showSpinner(true)
    await acsOfficeDayStore.getAcsOfficeDayList().then(response => {
      setOfficeDayList(response.data.content)
      setOfficePageSize(response.data.totalElements)
    })
    loadingAnimationStore.showSpinner(false)
  }

  const handleUpdateOfficeDayStatus = useCallback(
    officeDayId => {
      acsOfficeDayStore
        .updateAcsOfficeDayStatus(officeDayId)
        .then(() => {
          return new Promise((resolve, reject) => {
            GetAcsOfficeDayList()
              .then(() => resolve)
              .catch(() => reject)
            message.success(`Đã thay đổi trạng thái`)
          })
        })
        .catch(error => {
          message.error(error.vi)
        })
    },
    [acsOfficeDayStore]
  )
  const [offId, setOffId] = useState()
  const [offNote, setOffNote] = useState()
  const [isVisibleEditNoteModal, setIsVisibleEditNoteModal] = useState(false)

  const onOpen = (id, note) => {
    setIsVisibleEditNoteModal(true)
    setOffId(id)
    setOffNote(note)
  }

  const changeDateRange = dateRange => {
    acsOfficeDayStore.setQueryParams({
      ...queryParams,
      fromDate: dateRange?.[0].format('YYYY-MM-DD'),
      toDate: dateRange?.[1].format('YYYY-MM-DD'),
      page: 0,
    })
    GetAcsOfficeDayList()
  }

  const handleCloseEditNoteModal = () => {
    setIsVisibleEditNoteModal(false)
  }

  useEffect(() => {
    GetAcsOfficeDayList()
    return () => {
      acsOfficeDayStore.setQueryParams(acsOfficeDayStore.defaultQueryParams)
    }
  }, [])

  const columns = [
    {
      title: 'Ngày làm việc',
      key: 'timeKeeping',
      render: record => (
        <span>
          {' '}
          {record.timeKeeping
            ? moment(record.timeKeeping)
                .format('dddd, DD/MM/YYYY')
                .toString()
                .charAt(0)
                .toUpperCase() +
              moment(record.timeKeeping)
                .format('dddd, DD/MM/YYYY')
                .toString()
                .slice(1)
            : 'Chưa cập nhật'}{' '}
        </span>
      ),
    },
    {
      title: 'Trạng thái',
      key: 'status',
      render: record => (
        <Switch
          onChange={() => handleUpdateOfficeDayStatus(record?.officeDayId)}
          checked={record?.status}
        />
      ),
    },
    {
      title: 'Ghi chú',
      key: 'note',
      render: record => (
        <span>{record.note ? record.note : 'Chưa có ghi chú'}</span>
      ),
    },
    {
      title: 'Tác vụ',
      key: 'tasks',
      render: record => (
        <Button
          onClick={() => onOpen(record.officeDayId, record.note)}
          type="primary">
          <EditOutlined />
          Chỉnh sửa ghi chú
        </Button>
      ),
    },
  ]

  const handlePageChange = e => {
    acsOfficeDayStore.setQueryParams({
      ...queryParams,
      page: e - 1,
      size: 10,
    })
    GetAcsOfficeDayList()
  }

  return (
    <>
      <DashboardLayout>
        <Helmet>
          <title>Quản lý ngày làm việc</title>
        </Helmet>
        <PageTitle
          location={props.location}
          title={'Quản lý ngày làm việc'}
          hiddenGoBack></PageTitle>
        <ContentBlockWrapper style={{ minHeight: 'calc(100vh - 150px)' }}>
          <Space style={{ marginBottom: 16 }}>
            <DatePicker.RangePicker
              onChange={changeDateRange}
              format={[DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT]}
            />
          </Space>
          <TableWrapper>
            <Table
              rowKey={render => render.officeDayId}
              columns={columns}
              dataSource={officeDayList}
              pagination={false}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '15px 0',
              }}>
              <Pagination
                current={queryParams.page + 1}
                pageSize={10}
                total={officePageSize}
                hideOnSinglePage={true}
                showSizeChanger={false}
                showLessItems
                onChange={e => handlePageChange(e)}
              />
            </div>
          </TableWrapper>
        </ContentBlockWrapper>
      </DashboardLayout>

      <EditAcsOfficeDayNoteModal
        isVisibleEditNoteModal={isVisibleEditNoteModal}
        handleCloseEditNoteModal={handleCloseEditNoteModal}
        GetAcsOfficeDayList={GetAcsOfficeDayList}
        officeDayId={offId}
        officeDayNote={offNote}
        loadingAnimationStore={loadingAnimationStore}
      />
    </>
  )
}

export default inject('loadingAnimationStore')(observer(AcsOfficeDayPage))
