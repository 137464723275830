import { action, observable, toJS } from 'mobx'
// Request
import { TimekeepingSyncRequest } from '../requests/TimekeepingSyncRequest'

class timekeepingInfoStore {
  @action getTimekeepingForUserNew = param => {
    return new Promise((resolve, reject) => {
      TimekeepingSyncRequest.getTimekeeping(param)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getTimekeepingById = param => {
    return new Promise((resolve, reject) => {
      TimekeepingSyncRequest.getById(param)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getProposalsByDay = date => {
    return new Promise((resolve, reject) => {
      TimekeepingSyncRequest.getProposalsByDay(date)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getWorkingTimeReport = ({ username, fromDate, toDate }) => {
    return TimekeepingSyncRequest.getWorkingTimeReport({
      username,
      fromDate,
      toDate,
    })
  }
}

export default new timekeepingInfoStore()
