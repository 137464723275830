import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const AcsOfficeDayRequest = {
  getAcsOfficeDayList: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/office-day?${
        params.assignee ? params.assignee : ''
      }`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: { ...params, assignee: null },
    }),

  updateAcsOfficeDayStatus: id =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/office-day/status/${id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  updateAcsOfficeDayNote: (id, note) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/office-day/note/${id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: note?.trim(),
    }),
}
