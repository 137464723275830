import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
} from 'antd'
import { inject, observer } from 'mobx-react'
import validator from '../../validator'
import { FormButtonGroup } from './AddUserFormStyled'
import departmentHrStore from '../../stores/departmentHrStore'

const { Option } = Select

const AddUserForm = props => {
  const [form] = Form.useForm()
  const [selectedCompanyCode, setSelectedCompanyCode] = useState(null)

  const {
    modalVisible,
    onCancel,
    userStore,
    loadingAnimationStore,
    positionStore,
    departmentStore,
    authenticationStore,
    currentDepartment,
    onRefreshListUser,
    isAdmin = undefined,
    typeCompany,
    loadAdminsList,
  } = props

  const { positionList } = positionStore
  const { departmentHrList } = departmentHrStore

  useEffect(() => {
    if (authenticationStore.currentUser && modalVisible) {
      handleSelectCompany(authenticationStore.currentCompanyCode())

      if (currentDepartment) {
        form.setFieldsValue({
          department_hr_code: currentDepartment.code,
        })
      }
    }
  }, [authenticationStore.currentUser, modalVisible])

  const onFinish = values => {
    let payload = {
      ...values,
      company_code: authenticationStore.currentCompanyCode(),
      is_admin: false,
    }

    const payloadHasAdmin = {
      ...values,
      company_code: typeCompany,
      is_admin: isAdmin,
    }
    loadingAnimationStore.showSpinner(true)
    userStore
      .createUser(isAdmin ? payloadHasAdmin : payload)
      .then(() => {
        handleCancelAdd()
        isAdmin
          ? loadAdminsList().then(() => {
              loadingAnimationStore.showSpinner(false)
              message.success('Tạo tài khoản thành công')
            })
          : userStore.getUserList().then(() => {
              loadingAnimationStore.showSpinner(false)
              message.success('Tạo tài khoản thành công')
            })

        if (onRefreshListUser) {
          onRefreshListUser()
        }
      })
      .finally(() => loadingAnimationStore.showSpinner(false))
  }

  const onReset = useCallback(() => {
    form.resetFields()
  }, [form])

  const handleCancelAdd = useCallback(() => {
    onCancel()
    setSelectedCompanyCode(null)
    form.resetFields()
  }, [form, onCancel])

  const handleSelectCompany = companyCode => {
    setSelectedCompanyCode(companyCode)
    form.setFieldsValue({
      department_hr_code: null,
    })
    loadingAnimationStore.showSpinner(true)
    departmentHrStore
      .getAllDepartmentHrList(companyCode)
      .catch(error => message.error(error.vi))
      .finally(() => loadingAnimationStore.showSpinner(false))
  }

  return (
    <Modal
      title={isAdmin ? 'Thêm mới quản trị viên' : 'Thêm mới người dùng'}
      onCancel={handleCancelAdd}
      getContainer={false}
      footer={null}
      scrollToFirstError
      visible={modalVisible}>
      <Form
        scrollToFirstError={true}
        form={form}
        onFinish={onFinish}
        name="add-user-form"
        layout={'vertical'}>
        <Row type={'flex'} justify={'space-between'} gutter={15}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Tên đăng nhập"
              name="username"
              rules={[
                { required: true, message: 'Vui lòng nhập tên đăng nhập!' },
                { validator: validator.validateUsername },
              ]}>
              <Input placeholder={'Tên đăng nhập'} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Mật khẩu"
              name="password"
              rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}>
              <Input.Password
                autoComplete={'new-password'}
                placeholder={'Mật khẩu'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Phòng ban"
          name="department_hr_code"
          rules={[{ required: true, message: 'Vui lòng chọn phòng ban!' }]}>
          <Select
            showSearch
            placeholder={'Phòng ban'}
            filterOption={true}
            disabled={!selectedCompanyCode}
            optionFilterProp={'name'}>
            {departmentHrList.map(department => {
              return (
                <Option
                  key={department.code}
                  name={department.name}
                  value={department.code}>
                  {department.name}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Họ tên"
          name="name"
          rules={[
            { required: true, message: 'Vui lòng nhập họ tên!' },
            { validator: validator.validateInputString },
          ]}>
          <Input placeholder={'Họ tên'} />
        </Form.Item>
        <Form.Item label="Mã nhân viên" name="ma_nv">
          <Input placeholder={'Mã nhân viên'} />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Vui lòng nhập email!' },
            { validator: validator.validateEmail },
          ]}>
          <Input placeholder={'Email'} />
        </Form.Item>
        <Form.Item
          label={'Giới tính'}
          name={'gender'}
          rules={[{ required: true, message: 'Vui lòng chọn giới tính' }]}>
          <Select placeholder={'Giới tính'}>
            <Option value={1}>Nam</Option>
            <Option value={0}>Nữ</Option>
            <Option value={2}>Khác</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Điện thoại"
          name="phone"
          rules={[
            { required: true, message: 'Vui lòng nhập số điện thoại!' },
            { validator: validator.validatePhoneNumber },
          ]}>
          <Input placeholder={'Điện thoại'} />
        </Form.Item>
        <Form.Item label="Số ngày phép trong năm" name="take_leave">
          <Input placeholder={'Năm : số ngày'} />
        </Form.Item>
        <Form.Item label="Chức danh" name="position_code" rules={[]}>
          <Select
            showSearch
            placeholder={'Chức danh'}
            filterOption={true}
            optionFilterProp={'name'}>
            {positionList.map(position => {
              return (
                <Option
                  key={position.code}
                  name={position.name}
                  value={position.code}>
                  {position.name}
                </Option>
              )
            })}
          </Select>
        </Form.Item>

        <FormButtonGroup>
          <Button type={'danger'} ghost onClick={onCancel}>
            Huỷ bỏ
          </Button>
          <Space size={'middle'}>
            <Button onClick={onReset}>Nhập lại</Button>
            <Button type={'primary'} htmlType={'submit'}>
              Tạo mới
            </Button>
          </Space>
        </FormButtonGroup>
      </Form>
    </Modal>
  )
}

export default inject(
  'userStore',
  'loadingAnimationStore',
  'positionStore',
  'departmentStore',
  'authenticationStore'
)(observer(AddUserForm))
