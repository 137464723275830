import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, message, Modal } from 'antd'
import RichEditor from '../../components/RichEditor/RichEditor'
import { EditOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
// import { FormButtonRow } from '../../components/SubmissionForm/SubmissionFormStyled'

const ContentTemplateEditModal = props => {
  const {
    keyContentTemplate,
    isVisible,
    handleCloseModal,
    contentTemplateStore,
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const { content } = contentTemplateStore

  const [form] = Form.useForm()
  const EDITOR_REF = useRef()

  const [editorContent, setEditorContent] = useState(null)

  useEffect(() => {
    // console.log('keyContentTemplate', keyContentTemplate)
    if (isVisible && keyContentTemplate) {
      contentTemplateStore.getDetails(keyContentTemplate)
    }
  }, [isVisible, keyContentTemplate])

  useEffect(() => {
    setEditorContent(content)
  }, [content])

  const onFinish = async values => {
    setIsLoading(true)

    await contentTemplateStore.update(
      keyContentTemplate,
      EDITOR_REF.current.editor.getData()
    )

    closeAndResetForm()

    setIsLoading(false)

    message.success('Cập nhật thành công')
  }

  const closeAndResetForm = () => {
    form.resetFields()
    handleCloseModal()
  }

  return (
    <Modal
      title="Chỉnh sửa Biểu mẫu"
      footer={null}
      closable={true}
      width={1000}
      visible={isVisible}
      onCancel={handleCloseModal}>
      <Form form={form} layout={'vertical'} onFinish={onFinish}>
        <Form.Item name={'content'} label={'Nội dung:'}>
          <RichEditor
            EDITOR_REF={EDITOR_REF}
            placeholder={'Nhập nội dung'}
            height={'350px'}
            editorContent={editorContent}
          />
        </Form.Item>

        <div>
          <Button
            icon={<EditOutlined />}
            loading={isLoading}
            htmlType={'submit'}
            type={'primary'}>
            Cập nhật
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

ContentTemplateEditModal.propTypes = {}

export default inject('contentTemplateStore')(
  observer(ContentTemplateEditModal)
)
