import {
  CloseCircleOutlined,
  EditOutlined,
  FilterOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import {
  Avatar,
  Button,
  Col,
  Comment,
  Input,
  Pagination,
  Row,
  Select,
  Switch,
  Tooltip,
  message,
} from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { withRouter } from 'react-router-dom'
import AddUserForm from '../../components/AddUserForm'
import { EmptyText } from '../../components/Common/CellText'
import {
  CellEclipseBox,
  TableBottomPaginationBlock,
} from '../../components/Common/Table'
import TableComponent from '../../components/Common/TableComponent'
import EmptyContent from '../../components/EmptyContent'
import PageTitle from '../../components/PageTitle'
import UserAccountEditForm from '../../components/UserAccountEditForm'
import UserListFilterBlock from '../../components/UserListFilterBlock'
import departmentHrStore from '../../stores/departmentHrStore'
import DocumentsLayout from '../UserManagementPage/DocumentsLayout'
import utils from './../../utils'
import ModalImportExcel from './ModalImportExcel'
import { FilterButton, SearchBar } from './UserAcountManagementPageStyled'

const { Search } = Input

const UserAccountManagementPage = props => {
  const {
    userStore,
    loadingAnimationStore,
    companyStore,
    authenticationStore,
    history,
    positionStore,
    departmentStore,
    roleStore,
  } = props

  const {
    userListPageIndex,
    userListPageSize,
    userListKeyword,
    userListStatus,
    userIgnoreAcs,
    userListGenderCode,
    userListQualification,
    userListDepartmentCode,
    userListDepartmentHrCode,
    userListSortDirection,
    userListSortBy,
    userListFromDateCreate,
    userListToDateCreate,
    userListFromDateInactive,
    userListToDateInactive,
  } = userStore

  const [showUserInfo, setShowUserInfo] = useState(false)
  const [showAddUser, setShowAddUser] = useState(false)
  const [showImportExcel, setShowImportExcel] = useState(false)
  const [showFilter, setShowFilter] = useState(false)

  const tableColumns = useMemo(
    () => [
      {
        title: 'Họ tên',
        key: 'fullname',
        fixed: 'left',
        width: 240,
        render: record => (
          <Comment
            author={
              <span style={{ textTransform: 'capitalize' }}>
                {record.name_lowercase}
              </span>
            }
            avatar={
              <Avatar
                size={36}
                style={{ backgroundColor: '#1890ff' }}
                src={
                  record?.image &&
                  `url("data:image/gif;base64,${record?.image}")`
                }>
                {record?.name_uppercase.split('')[0].toString().toUpperCase()}
              </Avatar>
            }
          />
        ),
      },
      {
        title: 'User name',
        key: 'username',
        width: 100,
        render: record => record?.username,
      },
      {
        title: 'Giới tính',
        key: 'gender',
        width: 100,
        render: record => utils.getGenderName(record?.gender),
      },
      {
        title: 'Bộ phận',
        key: 'departmentHr',
        width: 120,
        render: record =>
          record?.departmentHr ? (
            record?.departmentHr.name
          ) : (
            <EmptyText>Không rõ</EmptyText>
          ),
      },
      {
        title: 'Vị trí công việc',
        key: 'position',
        width: 100,
        render: record =>
          record?.position ? (
            record.position.name
          ) : (
            <EmptyText>Không rõ</EmptyText>
          ),
      },

      {
        title: 'Trạng thái chấm công',
        key: 'acs_state',
        width: 120,
        render: record => (
          <Select
            defaultValue={record?.other?.acs_state}
            style={{ width: 90 }}
            onChange={status =>
              handleUpdateUserStatusTimekeeping(record?.code, status)
            }
            options={[
              { value: '', label: '' },
              { value: 'EMPTY', label: 'Chấm' },
              { value: 'DEFAULT', label: 'Mặc định' },
              { value: 'IGNORE', label: 'Bỏ qua' },
            ]}
          />
        ),
      },
      {
        title: 'Ngày vào công ty',
        key: 'other',
        width: 100,
        render: record =>
          record?.other ? (
            record?.other.recruitment_day
          ) : (
            <EmptyText>Không rõ</EmptyText>
          ),
      },
      {
        title: 'Ngày tạo',
        key: 'createdAt',
        width: 120,
        render: record => (
          <span>
            {' '}
            {record.created_at
              ? moment(record.created_at)
                  .format('dddd, DD/MM/YYYY')
                  .toString()
                  .charAt(0)
                  .toUpperCase() +
                moment(record.created_at)
                  .format('dddd, DD/MM/YYYY')
                  .toString()
                  .slice(1)
              : 'Chưa cập nhật'}{' '}
          </span>
        ),
      },
      {
        title: 'Ngày tạm dừng hoạt động',
        key: 'inactivedAt',
        width: 120,
        render: record => (
          <span>
            {' '}
            {record.inactived_at ? (
              moment(record.inactived_at)
                .format('dddd, DD/MM/YYYY')
                .toString()
                .charAt(0)
                .toUpperCase() +
              moment(record.inactived_at)
                .format('dddd, DD/MM/YYYY')
                .toString()
                .slice(1)
            ) : (
              <EmptyText>Chưa cập nhật</EmptyText>
            )}{' '}
          </span>
        ),
      },
      {
        title: 'Trạng thái',
        key: 'status',
        width: 100,
        render: record => (
          <Switch
            onChange={status => handleUpdateUserStatus(record?.code, status)}
            checked={record?.status}
          />
        ),
      },
      {
        title: 'Mã nhân viên',
        key: 'ma_nv',
        width: 120,
        render: record =>
          record?.ma_nv ? record?.ma_nv : <EmptyText>Không rõ</EmptyText>,
      },
      {
        title: 'Email',
        key: 'email',
        width: 100,
        render: record => record?.email,
      },
      {
        title: 'Số ĐT',
        key: 'phone',
        width: 100,
        render: record => <strong>{record?.phone}</strong>,
      },
      {
        title: 'Số máy nội bộ',
        key: 'home_phone',
        width: 100,
        render: record => (
          <strong>
            {record?.home_phone ? (
              record?.home_phone
            ) : (
              <EmptyText>Không rõ.</EmptyText>
            )}
          </strong>
        ),
      },
      {
        title: 'Trình độ chuyên môn cao nhất',
        key: 'other',
        width: 120,
        render: item =>
          item?.other ? (
            item?.other.qualification
          ) : (
            <EmptyText>Không rõ</EmptyText>
          ),
      },
      {
        title: 'Công ty',
        key: 'companyName',
        width: 100,
        render: record => (
          <Tooltip title={record?.company && record?.company.name}>
            <CellEclipseBox>
              {record?.company && record?.company.name}
            </CellEclipseBox>
          </Tooltip>
        ),
      },
      {
        title: 'Tác vụ',
        key: 'action',
        fixed: 'right',
        width: 130,
        render: record => (
          <Button onClick={() => handleViewUserInfo(record)} type="primary">
            <EditOutlined />
            Chỉnh sửa
          </Button>
        ),
      },
    ],
    []
  )

  const handleViewUserInfo = useCallback(
    userInfo => {
      const companyCode = userInfo?.company?.code
      userStore.setSelectedUser(userInfo)
      loadingAnimationStore.showSpinner(true)
      departmentHrStore
        .getAllDepartmentHrList()
        .then(() => setShowUserInfo(true))
        .catch(error => message.error(error.vi))
        .finally(() => loadingAnimationStore.showSpinner(false))
    },
    [departmentHrStore, loadingAnimationStore, userStore]
  )

  const handleCancelViewUserInfo = useCallback(() => {
    userStore.clearSelectedUser()
    setShowUserInfo(false)
  }, [userStore])

  const handleChangePageIndex = useCallback(
    page => {
      userStore.changeUserListPageIndex(page - 1)
    },
    [userStore]
  )

  const handleShowAdvancedFilter = useCallback(
    status => {
      setShowFilter(status)
      if (!status) {
        userStore.clearUserListFilter()
      }
    },
    [userStore]
  )

  const handleUpdateUserStatus = useCallback(
    (userCode, status) => {
      loadingAnimationStore.showSpinner(true)
      userStore
        .updateUserStatus(userCode, status)
        .then(() => {
          return new Promise((resolve, reject) => {
            userStore
              .getUserList()
              .then(() => resolve())
              .catch(() => reject())
          })
        })
        .then(() => {
          message.success(`Đã ${status ? 'kích hoạt' : 'tạm dừng'} người dùng!`)
          loadingAnimationStore.showSpinner(false)
        })
        .catch(error => {
          message.error(error.vi)
          loadingAnimationStore.showSpinner(false)
        })
    },
    [loadingAnimationStore, userStore]
  )
  const handleUpdateUserStatusTimekeeping = useCallback(
    (user_code, acs_state) => {
      const userInfo = { acs_state, user_code }
      loadingAnimationStore.showSpinner(true)
      userStore
        .updateCurrentUserIsIgnoreAcs(userInfo)
        .then(() => {
          return new Promise((resolve, reject) => {
            userStore
              .getUserList()
              .then(() => resolve())
              .catch(() => reject())
          })
        })
        .then(() => {
          message.success(`Cập nhật chấm công!`)
        })
        .catch(error => {
          message.error(error.vi)
        })
        .finally(() => {
          loadingAnimationStore.showSpinner(false)
        })
    },
    [loadingAnimationStore, userStore]
  )
  const handleCancelAddUser = useCallback(() => {
    setShowAddUser(false)
  }, [])

  const handleCancelImportExcel = useCallback(() => {
    setShowImportExcel(false)
  }, [])

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    Promise.all([
      companyStore.getCompanyList(),
      positionStore.getPositionList(),
      roleStore.getRoles(),
    ])
      .then(() => loadingAnimationStore.showSpinner(false))
      .catch(() => loadingAnimationStore.showSpinner(false))
    return () => {
      userStore.clearUserListFilter()
      userStore.changeUserListKeyword(undefined)
      userStore.changeUserListPageIndex(0)
    }
  }, [companyStore, loadingAnimationStore, positionStore, roleStore, userStore])

  useEffect(() => {
    if (authenticationStore.currentUser) {
      loadingAnimationStore.showSpinner(true)
      // userStore.setFilter('userListHasAdmin', true)
      // userStore.setFilter(
      //   'companyCode',
      //   authenticationStore.currentCompanyCode()
      // )
      userStore
        .getUserList()
        .finally(() => loadingAnimationStore.showSpinner(false))
    }
  }, [
    userListPageIndex,
    userListKeyword,
    userListStatus,
    userIgnoreAcs,
    userListGenderCode,
    userListQualification,
    userListDepartmentCode,
    userListDepartmentHrCode,
    userListSortDirection,
    userListSortBy,
    loadingAnimationStore,
    userStore,
    authenticationStore.currentUser,
    userListFromDateCreate,
    userListToDateCreate,
    userListFromDateInactive,
    userListToDateInactive,
  ])

  useEffect(() => {
    if (!authenticationStore.currentUser) return
    // if (
    //   authenticationStore.currentUser &&
    //   authenticationStore.currentUser.roles.includes('SUPER_ADMIN')
    // )
    //   return
    // history.push('/')
    // message.error(
    //   'Bạn không có quyền truy cập trang này, vui lòng liên hệ admin'
    // )
  }, [authenticationStore.currentUser, history])

  useEffect(() => {
    return () => {
      userStore.clearStore()
    }
  }, [])

  return (
    <>
      {!props.isTab ? (
        <DocumentsLayout
          title={
            <PageTitle location={props.location} title={'Quản lý Tài khoản'}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ marginRight: '10px' }}>
                  <Button type={'primary'} onClick={() => setShowAddUser(true)}>
                    <PlusCircleOutlined />
                    Thêm mới người dùng
                  </Button>
                </div>
                <div>
                  <Button onClick={() => setShowImportExcel(true)}>
                    <PlusCircleOutlined />
                    Import excel
                  </Button>
                </div>
              </div>
            </PageTitle>
          }
          selectedKey={'user-account-management'}>
          <Helmet>
            <title>Quản lý tài khoản</title>
          </Helmet>
          <Row style={{ marginBottom: 16 }}>
            <Col span={12}>
              <SearchBar>
                <Search
                  allowClear
                  onSearch={value => {
                    // const sanitizedValue = removeDiacritics(value)
                    //   .replace(/^\s+|\s+$/g, '')
                    //   .replace(/\s+/g, ' ')
                    //   .replace(/[^\w\s]/g, '')
                    // userStore.changeUserListKeyword(sanitizedValue)
                    userStore.changeUserListKeyword(value)
                  }}
                  placeholder={'Tìm kiếm theo tên hoặc username'}
                />
              </SearchBar>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <FilterButton
                onClick={() => handleShowAdvancedFilter(!showFilter)}
                type={showFilter ? 'primary' : 'default'}
                ghost={showFilter}>
                {showFilter ? (
                  <Fragment>
                    <CloseCircleOutlined />
                    &nbsp; Tắt tìm kiếm nâng cao
                  </Fragment>
                ) : (
                  <Fragment>
                    <FilterOutlined />
                    &nbsp; Tìm kiếm nâng cao
                  </Fragment>
                )}
              </FilterButton>
            </Col>
          </Row>
          {showFilter ? <UserListFilterBlock /> : null}
          <TableComponent
            rowKey={record => record?.code}
            dataSource={userStore.userHasAdminList}
            columns={tableColumns}
            pagination={false}
            scroll={{ x: 1000 }}
            locale={{
              emptyText: <EmptyContent />,
            }}
          />
          <TableBottomPaginationBlock>
            <Pagination
              onChange={handleChangePageIndex}
              pageSize={userListPageSize}
              showSizeChanger={false}
              showLessItems
              current={userListPageIndex + 1}
              total={userStore.userListTotalCount}
              hideOnSinglePage={true}
            />
          </TableBottomPaginationBlock>
        </DocumentsLayout>
      ) : (
        <>
          <Row style={{ marginBottom: 16 }}>
            <Col span={12}>
              <SearchBar>
                <Search
                  allowClear
                  onSearch={userStore.changeUserListKeyword}
                  placeholder={'Tìm kiếm theo tên hoặc username'}
                />
              </SearchBar>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <FilterButton
                onClick={() => handleShowAdvancedFilter(!showFilter)}
                type={showFilter ? 'primary' : 'default'}
                ghost={showFilter}>
                {showFilter ? (
                  <Fragment>
                    <CloseCircleOutlined />
                    &nbsp; Tắt tìm kiếm nâng cao
                  </Fragment>
                ) : (
                  <Fragment>
                    <FilterOutlined />
                    &nbsp; Tìm kiếm nâng cao
                  </Fragment>
                )}
              </FilterButton>
            </Col>
          </Row>
          {showFilter ? <UserListFilterBlock /> : null}
          <TableComponent
            rowKey={record => record?.code}
            dataSource={userStore.userHasAdminList}
            columns={tableColumns}
            pagination={false}
            scroll={{ x: 1000 }}
            locale={{
              emptyText: <EmptyContent />,
            }}
          />
          <TableBottomPaginationBlock>
            <Pagination
              onChange={handleChangePageIndex}
              pageSize={userListPageSize}
              showSizeChanger={false}
              showLessItems
              current={userListPageIndex + 1}
              total={userStore.userListTotalCount}
              hideOnSinglePage={true}
            />
          </TableBottomPaginationBlock>
        </>
      )}
      <UserAccountEditForm
        modalVisible={showUserInfo}
        onCancel={handleCancelViewUserInfo}
      />
      {showAddUser && (
        <AddUserForm
          modalVisible={showAddUser}
          onCancel={handleCancelAddUser}
        />
      )}
      <ModalImportExcel
        modalVisible={showImportExcel}
        onCancel={handleCancelImportExcel}></ModalImportExcel>
    </>
  )
}

export default withRouter(
  inject(
    'userStore',
    'loadingAnimationStore',
    'companyStore',
    'authenticationStore',
    'positionStore',
    'departmentStore',
    'roleStore'
  )(observer(UserAccountManagementPage))
)
