import React, { memo, useRef, useState } from 'react'
// Components
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import ChangePassword from './ChangePassword'
import DashboardAuthLayout from '../../layouts/DashboardAuthLayout'
import Contact from './Contact'
import Job from './Job'
import Education from './Education'
import Health from './Health'
import PageTitle from '../../components/PageTitle'
import { TitleContentBox } from '../../components/Common/CellText'
import Overview from './Overview'
// Styled component
import { MenuWrapper } from './MyProfilePageStyled'
// Antd design
import { Button, Menu } from 'antd'
import {
  ContactsFilled,
  HeartFilled,
  ReadFilled,
  RedditCircleFilled,
  ScheduleFilled,
  UnlockFilled,
} from '@ant-design/icons'
// Other
import { inject, observer } from 'mobx-react'
import { blue } from '../../color'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import CKEditor from '@ckeditor/ckeditor5-react'
import { EditorWrapper } from '../../components/RichEditor/RichEditorStyled'
import { CKEDITOR_HEIGHT } from '../../ui_constants'
import { MyUploadAdapter } from '../../components/CustomCKEditorImageUpload/MyUploadAdapter'
import PrintC2Profile from './printC2Profile'
import { styled } from 'styled-components'
import DashboardLayout from '../../layouts/DashboardLayout'

const toolbar = [
  'insertTable',
  'Alignment',
  'Autoformat',
  'AutoLink',
  'BlockQuote',
  'Bold',
  'Essentials',
  'FontBackgroundColor',
  'FontColor',
  'FontFamily',
  'FontSize',
  'Heading',
  'Image',
  'ImageInsert',
  'ImageResize',
  'ImageStyle',
  'ImageToolbar',
  'ImageUpload',
  'Indent',
  'IndentBlock',
  'Italic',
  'Link',
  'List',
  'ListStyle',
  'MediaEmbed',
  'MediaEmbedToolbar',
  'Mention',
  'Paragraph',
  'PasteFromOffice',
  'Strikethrough',
  'Table',
  'TableCellProperties',
  'TableProperties',
  'TableToolbar',
  'TextTransformation',
  'TodoList',
  'Underline',
  'WordCount',
  'ExportPdf',
  'Preview',
  'document',
  'doctools',
]
const MyProfilePage = props => {
  const menuList = [
    {
      key: 'overview',
      title: 'Tổng quan',
      icon: <RedditCircleFilled style={{ color: blue, fontSize: 16 }} />,
    },
    {
      key: 'contact',
      title: 'Thông tin liên hệ',
      icon: <ContactsFilled style={{ color: blue, fontSize: 16 }} />,
    },
    {
      key: 'job',
      title: 'Công việc',
      icon: <ScheduleFilled style={{ color: blue, fontSize: 16 }} />,
    },
    {
      key: 'education',
      title: 'Trình độ học vấn',
      icon: <ReadFilled style={{ color: blue, fontSize: 16 }} />,
    },
    {
      key: 'health',
      title: 'Sức khỏe',
      icon: <HeartFilled style={{ color: blue, fontSize: 16 }} />,
    },
    {
      key: 'change-password',
      title: 'Thay đổi mật khẩu',
      icon: <UnlockFilled style={{ color: blue, fontSize: 16 }} />,
    },
  ]

  const renderInfo = () => {
    if (keyInfoActive.key === 'overview') return <Overview />
    if (keyInfoActive.key === 'contact') return <Contact />
    if (keyInfoActive.key === 'job') return <Job />
    if (keyInfoActive.key === 'education') return <Education />
    if (keyInfoActive.key === 'health') return <Health />
    if (keyInfoActive.key === 'change-password')
      return (
        <ChangePassword
          finishChangePassword={() =>
            setKeyInfoActive({
              key: 'overview',
              title: 'Tổng quan',
            })
          }
        />
      )
  }

  const [keyInfoActive, setKeyInfoActive] = useState({
    key: 'overview',
    title: 'Tổng quan',
  })

  const EDITOR_REF = useRef()
  const [editorContent, setEditorContent] = useState(null)

  return (
    <>
      <DashboardLayout>
        {/* <DashboardAuthLayout> */}
        <div
          style={{
            display: 'flex',
            // marginLeft: 330,
            // marginTop: 50,
            justifyContent: 'space-between',
          }}>
          <PageTitle location={props.location} />
          <PrintC2Profile />
        </div>
        <div style={{ display: 'flex', gap: 24 }}>
          <div style={{ width: 300, flex: '0 0 300px' }}>
            <ContentBlockWrapper>
              <TitleContentBox>Thông tin của bạn</TitleContentBox>
              <MenuWrapper
                selectedKeys={[keyInfoActive.key]}
                onSelect={item => {
                  setKeyInfoActive({
                    key: item.key,
                    title: item.item.props.title,
                  })
                }}>
                {menuList.map(menuItem => (
                  <Menu.Item
                    key={menuItem.key}
                    icon={menuItem.icon}
                    title={menuItem.title}>
                    {menuItem.title}
                  </Menu.Item>
                ))}
              </MenuWrapper>
            </ContentBlockWrapper>
          </div>
          <div style={{ flexGrow: 1 }}>
            <ContentBlockWrapper>
              <TitleContentBox>{keyInfoActive.title}</TitleContentBox>
              <div style={{ minHeight: 288 }}>{renderInfo()}</div>
              <div style={{ flexGrow: 1 }}></div>
            </ContentBlockWrapper>
          </div>
        </div>
        {/* </DashboardAuthLayout> */}
      </DashboardLayout>
    </>
  )
}

export default memo(
  inject(
    'authenticationStore',
    'commonStore',
    'loadingAnimationStore'
  )(observer(MyProfilePage))
)
