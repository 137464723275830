import React, { Fragment, memo, useCallback, useEffect, useState } from 'react'
import { Col, DatePicker, Form, Input, message, Row, Select } from 'antd'
import { inject, observer } from 'mobx-react'
import { FilterBoxWrapper } from './UserListFilterBlockStyled'
import moment from 'moment'
import { DATE_FORMAT_DEFAULT, ISO_DATE_FORMAT } from '../../constants'
import departmentHrStore from '../../stores/departmentHrStore'

const { Option } = Select
const { Search } = Input
const UserListFilterBlock = props => {
  const [form] = Form.useForm()

  const {
    userStore,
    departmentStore,
    loadingAnimationStore,
    authenticationStore,
  } = props

  const { departmentHrList } = departmentHrStore

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    ;(async () => {
      if (authenticationStore.currentCompanyCode()) {
        try {
          departmentHrStore.getAllDepartmentHrList(
            authenticationStore.currentCompanyCode()
          )
        } catch (error) {
          console.log(error)
          message.error(error?.vi || 'Đã có lỗi xảy ra!')
        } finally {
          loadingAnimationStore.showSpinner(false)
        }
      }
    })()
  }, [authenticationStore.currentUser])

  const handleFilterByDepartmentHr = value => {
    userStore.changeUserListDepartmentHr(value)
  }

  const handleFilterByStatus = useCallback(
    value => {
      if (value === 2) {
        userStore.changeUserIgnoreAcs(true)
      } else {
        userStore.changeUserIgnoreAcs(false)
        userStore.changeUserListStatus(value)
      }
    },
    [userStore]
  )
  const handleFilterByGender = useCallback(
    value => {
      userStore.changeUserListGender(value)
    },
    [userStore]
  )
  const handleSortByDirection = useCallback(
    value => {
      userStore.changeUserListSortDirection(value)
    },
    [userStore]
  )
  const handleSortByType = useCallback(
    value => {
      userStore.changeUserListSortBy(value)
    },
    [userStore]
  )

  const { RangePicker } = DatePicker

  const onChangeCreatedAt = useCallback(
    value => {
      userStore.changeUserListCreatedAt(value)
    },
    [userStore]
  )

  const onChangeInactivedAt = useCallback(
    value => {
      userStore.changeUserListInactivedAt(value)
    },
    [userStore]
  )

  return (
    <Fragment>
      <FilterBoxWrapper>
        <Form
          form={form}
          scrollToFirstError={true}
          name={'user-filter-form'}
          layout={'vertical'}>
          <Row type={'flex'} gutter={16}>
            <Col xs={24} md={6}>
              <Form.Item label={'Sắp xếp theo'} name="sort_by">
                <Select
                  allowClear
                  onChange={handleSortByType}
                  placeholder={'Sắp xếp theo'}
                  showArrow>
                  <Option value={'nameUppercase'}>Họ tên</Option>
                  <Option value={'username'}>Tên đăng nhập</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item label={'Thứ tự'} name="direction">
                <Select
                  allowClear
                  onChange={handleSortByDirection}
                  placeholder={'Lựa chọn'}
                  showArrow>
                  <Option value={'ASC'}>Tăng dần</Option>
                  <Option value={'DESC'}>Giảm dần</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item label={'Trạng thái'} name="status">
                <Select
                  allowClear
                  onChange={handleFilterByStatus}
                  placeholder={'Trạng thái'}
                  showArrow>
                  <Option value={true}>Active</Option>
                  <Option value={false}>Inactive</Option>
                  <Option value={2}>Bỏ qua chấm công</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item label={'Giới tính'} name="gender">
                <Select
                  allowClear
                  onChange={handleFilterByGender}
                  placeholder={'Giới tính'}
                  showArrow>
                  <Option value={1}>Nam</Option>
                  <Option value={0}>Nữ</Option>
                  <Option value={2}>Khác</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item
                label={'Trình độ chuyên môn cao nhất'}
                name="qualification">
                {/* <Select
                  allowClear
                  onChange={userStore.changeUserListQualification}
                  placeholder={'Trình độ chuyên môn cao nhất'}
                  showArrow>
                  <Option value={"Đại Học"}>Đại Học</Option>
                </Select> */}
                <Search
                  allowClear
                  onSearch={userStore.changeUserListQualification}
                  placeholder={'Tìm kiếm theo trình độ chuyên môn cao nhất'}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item label={'Phòng ban'} name="department">
                <Select
                  allowClear
                  showSearch
                  onChange={handleFilterByDepartmentHr}
                  placeholder={'Phòng ban'}
                  filterOption={true}
                  optionFilterProp={'name'}>
                  {departmentHrList.map(department => {
                    return (
                      <Option
                        key={department.code}
                        name={department.name}
                        value={department.code}>
                        {department.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item label={'Ngày tạo'} name="createdAt">
                <RangePicker
                  style={{ width: '100%' }}
                  onChange={onChangeCreatedAt}
                  value={
                    userStore.userListFromDateCreate &&
                    userStore.userListToDateCreate
                      ? [
                          moment(userStore.userListFromDateCreate),
                          moment(userStore.userListToDateCreate),
                        ]
                      : null
                  }
                  format={[DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item label={'Ngày tạm dừng hoạt động'} name="inactivedAt">
                <RangePicker
                  style={{ width: '100%' }}
                  onChange={onChangeInactivedAt}
                  value={
                    userStore.userListFromDateInactive &&
                    userStore.userListToDateInactive
                      ? [
                          moment(userStore.userListFromDateInactive),
                          moment(userStore.userListToDateInactive),
                        ]
                      : null
                  }
                  format={[DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT]}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </FilterBoxWrapper>
      <div style={{ marginBottom: 15 }} />
    </Fragment>
  )
}

export default memo(
  inject(
    'userStore',
    'departmentStore',
    'loadingAnimationStore',
    'authenticationStore'
  )(observer(UserListFilterBlock))
)
