import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const DepartmentHrRequest = {
  getAllDepartmentHrList: companyCode =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/department-hrs`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: { companyCode: companyCode },
    }),

  getDepartmentHrListFromCompanyCode: companyCode =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/department-hrs/company`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: { companyCode: companyCode },
    }),
  createHr: name =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/department-hrs`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        name: name,
      },
    }),
  updateHr: (code, name) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/department-hrs/${code}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        name: name,
      },
    }),
  deleteHr: code =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/department-hrs/${code}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  sortHr: codes =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/department-hrs/sort`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        data: codes,
      },
    }),
}
