import React, { useCallback, useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import {
  Button,
  Col,
  Empty,
  Input,
  message,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Table,
} from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import {
  TableBottomPaginationBlock,
  TableWrapper,
} from '../../components/Common/Table'
import styled from 'styled-components'
import { TYPE_STATUS } from '../../constants'
import { IssueInfo } from '../Administrative/Proposal/ProposalPage/ProposalPageStyled'
import { StatusTag } from '../../components/Common/StatusTag'
import { EmptyText } from '../../components/Common/CellText'
import { useHistory } from 'react-router-dom'
import utils from '../../utils'
import { PROPOSAL_STATUS } from '../../constants'
import './ProposalPageStyled.css'

const SearchBar = styled.div`
  width: 300px;
`
const { Search } = Input

const ProposalPage = props => {
  const history = useHistory()
  const {
    loadingAnimationStore,
    proposalStore,
    departmentStore,
    authenticationStore,
  } = props

  const {
    proposalList,
    totalCountProposal,
    pageIndex,
    pageSize,
    keyword,
    proposalPermission,
    type,
    proposalStatus,
    department,
    time,
  } = proposalStore
  const [exportLoading, setExportLoading] = useState(false)
  useEffect(() => {
    loadingAnimationStore.setTableLoading(true)
    proposalStore
      .getProposals()
      .finally(() => loadingAnimationStore.setTableLoading(false))
      .catch(error => {
        loadingAnimationStore.setTableLoading(false)
        console.log(error)
      })
  }, [
    pageIndex,
    pageSize,
    keyword,
    proposalStatus,
    type,
    proposalPermission,
    department,
    time,
  ])

  const showProposalType = proposal_type => {
    switch (proposal_type) {
      case 'BUSINESS_TRIP':
        return 'Đăng ký công tác'
      case 'LEAVE':
        return 'Đăng ký nghỉ phép'
      case 'LEAVE_ABNORMAL':
        return 'Đăng ký đi muộn về sớm'
      case 'LEAVE_NO_SALARY':
        return 'Đăng ký nghỉ phép không lương'
      case 'LEAVE_ANNUAL':
        return 'Đăng ký nghỉ phép hàng năm'
      case 'SICK':
        return 'Đăng ký nghỉ ốm'
      case 'SICK_OTHER':
        return 'Đăng ký nghỉ chăm người thân ốm'
      case 'OVERTIME_NORMAL':
        return 'Đăng ký làm thêm giờ'
      case 'OVERTIME_DAYOFF':
        return 'Đăng ký làm thêm giờ ngày nghỉ'
      case 'OVERTIME_HOLIDAY':
        return 'Đăng ký làm thêm giờ ngày lễ'
      case 'RETIMEKEEPING':
        return 'Đăng ký chấm công bù'
      default:
        return ''
    }
  }

  const [overtimeValues, setOvertimeValues] = useState()

  const handleChangeOvertime = id => event => {
    let value
    if (event.target.value < 12) value = event.target.value || ''
    setOvertimeValues(value)
    console.log('', overtimeValues)
  }

  const handleApprove = async (id, overtime) => {
    await proposalStore.approve(id, overtime)
    await proposalStore.getProposals()
    message.success('Duyệt thành công!')
  }

  const [oT, setOT] = useState(null)

  const handleChangeTime = event => {
    setOT(event.target.value)
  }

  const handleConfirm = async id => {
    await proposalStore.approve(id, oT)
    await proposalStore.getProposals()
    message.success('Duyệt thành công!')
  }

  const columns = [
    {
      title: 'Loại',
      key: 'type',
      fixed: 'left',
      render: record =>
        record.proposal_type ? (
          <span
            onClick={() =>
              history.push(`/management/proposal/view/${record.proposal_code}`)
            }>
            {showProposalType(record.proposal_type)}
          </span>
        ) : (
          <div
            onClick={() =>
              history.push(`/management/proposal/view/${record.proposal_code}`)
            }>
            <EmptyText>Không có dữ liệu</EmptyText>
          </div>
        ),
    },
    {
      title: 'Tiêu đề',
      key: 'title',
      render: record =>
        record.title ? (
          <span
            onClick={() =>
              history.push(`/management/proposal/view/${record.proposal_code}`)
            }>
            {record.title}
          </span>
        ) : (
          <div
            onClick={() =>
              history.push(`/management/proposal/view/${record.proposal_code}`)
            }>
            <EmptyText>Không có dữ liệu</EmptyText>
          </div>
        ),
    },
    {
      title: 'Người tạo',
      key: 'creater',
      render: record =>
        record.create_user ? (
          <span
            onClick={() =>
              history.push(`/management/proposal/view/${record.proposal_code}`)
            }>
            {utils.getNameInCapitalize(record.create_user.name_lowercase)}
          </span>
        ) : (
          <div
            onClick={() =>
              history.push(`/management/proposal/view/${record.proposal_code}`)
            }>
            <EmptyText>Không có dữ liệu</EmptyText>
          </div>
        ),
    },
    {
      title: 'Phòng ban',
      key: 'department',
      render: record =>
        record.department ? (
          <span
            onClick={() =>
              history.push(`/management/proposal/view/${record.proposal_code}`)
            }>
            {record.department}
          </span>
        ) : (
          <div
            onClick={() =>
              history.push(`/management/proposal/view/${record.proposal_code}`)
            }>
            <EmptyText>Không có dữ liệu</EmptyText>
          </div>
        ),
    },
    {
      title: 'Thời gian',
      key: 'time',
      render: record =>
        record.time ? (
          <span
            onClick={() =>
              history.push(`/management/proposal/view/${record.proposal_code}`)
            }>
            {moment(record.time)
              .format('dddd, DD/MM/YYYY')
              .toString()
              .charAt(0)
              .toUpperCase() +
              moment(record.time)
                .format('dddd, DD/MM/YYYY')
                .toString()
                .slice(1)}
          </span>
        ) : (
          <div
            onClick={() =>
              history.push(`/management/proposal/view/${record.proposal_code}`)
            }>
            <EmptyText>Không có dữ liệu</EmptyText>
          </div>
        ),
    },
    {
      title: 'Trạng thái',
      key: 'status',
      render: record =>
        record.proposal_status ? (
          <IssueInfo
            onClick={() =>
              history.push(`/management/proposal/view/${record.proposal_code}`)
            }>
            {StatusTag(TYPE_STATUS.PROPOSAL, record.proposal_status)}
          </IssueInfo>
        ) : (
          <div
            onClick={() =>
              history.push(`/management/proposal/view/${record.proposal_code}`)
            }>
            <EmptyText>Không có dữ liệu</EmptyText>
          </div>
        ),
    },
    {
      title: 'Giờ làm việc',
      key: 'overtime',
      render: record =>
        record.approvalOvertime || record.overtime ? (
          record.approvalOvertime ? (
            <span
              onClick={() =>
                history.push(
                  `/management/proposal/view/${record.proposal_code}`
                )
              }>
              {record.approvalOvertime} giờ
            </span>
          ) : (
            <span
              onClick={() =>
                history.push(
                  `/management/proposal/view/${record.proposal_code}`
                )
              }>
              {record.overtime} giờ
            </span>
          )
        ) : (
          <div
            onClick={() =>
              history.push(`/management/proposal/view/${record.proposal_code}`)
            }>
            <EmptyText>Không có dữ liệu</EmptyText>
          </div>
        ),
    },
    {
      title: 'Tác vụ',
      key: 'actions',
      fixed: 'right',
      render: record =>
        record.proposal_type === 'BUSINESS_TRIP' ||
        record.proposal_type === 'LEAVE' ||
        record.proposal_type === 'LEAVE_ABNORMAL' ||
        record.proposal_type === 'LEAVE_NO_SALARY' ||
        record.proposal_type === 'LEAVE_ANNUAL' ||
        record.proposal_type === 'RETIMEKEEPING' ||
        record.proposal_type === 'SICK' ||
        record.proposal_type === 'SICK_OTHER' ? (
          <>
            <Button
              id="last-column-component-classname"
              disabled={record.proposal_status === 'PENDING' ? false : true}
              style={{ width: '80px' }}
              htmlType="submit"
              type="primary"
              onClick={() => handleApprove(record.id, '')}>
              Duyệt
            </Button>
          </>
        ) : (
          <>
            <Popconfirm
              id="last-column-component-classname-2"
              // style={{ display: 'flex', justifyContent: 'space-between' }}
              title={
                <Input
                  id="last-column-component-classname"
                  style={{ width: '80px' }}
                  placeholder="Nhập giờ"
                  onChange={handleChangeTime}
                />
              }
              icon={false}
              onConfirm={() => handleConfirm(record.id)}
              okText={
                <span id="last-column-component-classname-span">Duyệt</span>
              }
              cancelText={
                <span id="last-column-component-classname-span">Hủy</span>
              }>
              <Button
                id="last-column-component-classname"
                style={{ width: '80px' }}
                htmlType={
                  record.proposal_status === 'APPROVAL' ? null : 'submit'
                }
                type={record.proposal_status === 'APPROVAL' ? null : 'primary'}>
                {record.proposal_status === 'APPROVAL' ? (
                  <span id="last-column-component-classname-span">Sửa giờ</span>
                ) : (
                  <span id="last-column-component-classname-span">Duyệt</span>
                )}
              </Button>
            </Popconfirm>
          </>
        ),
    },
  ]

  const handleChangePageProposalLeave = useCallback(page => {
    const pageIndex = page - 1
    proposalStore.setFilter('pageIndex', pageIndex)
  }, [])

  const [keywordSearch, setKeywordSearch] = useState()

  const onSearchProposal = useCallback(values => {
    proposalStore.setFilter('pageIndex', 0)
    proposalStore.setFilter('keyword', values && values.trim())
  }, [])

  const { departmentList } = departmentStore
  const [forExportProposalList, setForExportProposalList] = useState(null)
  const getForExportProposalList = async () => {
    await proposalStore.getProposalsForExport().then(response => {
      setForExportProposalList(response.data)
    })
  }
  const exportProposals = useCallback(async () => {
    try {
      setExportLoading(true)
      await getForExportProposalList()
      proposalStore.setFilter('pageSize', 10)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setExportLoading(false)
    }
  }, [getForExportProposalList])
  const renderStatusName = status => {
    if (status === PROPOSAL_STATUS.PENDING) {
      return 'Chờ phê duyệt'
    }
    if (status === PROPOSAL_STATUS.APPROVAL) {
      return 'Đã phê duyệt'
    }
    if (status === PROPOSAL_STATUS.REJECT) {
      return 'Từ chối'
    }
  }
  useEffect(() => {
    if (forExportProposalList && forExportProposalList.data) {
      const col = [
        'Loại',
        'Tiêu đề',
        'Người tạo',
        'Phòng ban',
        'Thời gian',
        'Trạng thái',
        'Giờ làm việc',
      ]
      let rows = []
      rows.push(col)
      forExportProposalList.data.map(item => {
        rows.push([
          item.proposal_type
            ? showProposalType(item.proposal_type)
            : 'Không có dữ liệu',
          item.title ? item.title : 'Không có dữ liệu',
          item.create_user
            ? utils.getNameInCapitalize(item.create_user.name_lowercase)
            : 'Không có dữ liệu',
          item.department ? item.department : 'Không có dữ liệu',
          item.time
            ? moment(item.time)
                .format('dddd, DD/MM/YYYY')
                .toString()
                .charAt(0)
                .toUpperCase() +
              moment(item.time).format('dddd, DD/MM/YYYY').toString().slice(1)
            : 'Không có dữ liệu',
          item.proposal_status
            ? renderStatusName(item.proposal_status)
            : 'Không có dữ liệu',
          item.approvalOvertime || item.overtime
            ? item.approvalOvertime
              ? item.approvalOvertime + ' giờ'
              : item.overtime + ' giờ'
            : 'Không có dữ liệu',
        ])
      })
      utils.exportExcel(
        rows,
        'Hành chính tổng hợp - ' + moment().format('YYYY-MM-DD hmms')
      )
    }
  }, [forExportProposalList])
  useEffect(() => {
    ;(async () => {
      if (authenticationStore.currentCompanyCode()) {
        try {
          departmentStore.getDepartmentListFromCompanyCode(
            authenticationStore.currentCompanyCode()
          )
        } catch (error) {
          console.log(error)
          message.error(error?.vi || 'Đã có lỗi xảy ra!')
        } finally {
        }
      }
    })()
  }, [authenticationStore.currentUser])

  const handleFilterByDepartment = useCallback(value => {
    proposalStore.setFilter('pageIndex', 0)
    proposalStore.setFilter('department', value)
  }, [])

  const handleFilterByTime = useCallback(value => {
    proposalStore.setFilter('pageIndex', 0)
    proposalStore.setFilter('time', value ? value.format('MM/YYYY') : null)
  }, [])

  const handleFilterStatus = useCallback(value => {
    proposalStore.setFilter('pageIndex', 0)
    proposalStore.setFilter('proposalStatus', value)
  }, [])

  useEffect(() => {
    proposalStore.clearFilterProposal()
  }, [])

  return (
    <>
      <DashboardLayout>
        <Helmet>
          <title>Quản lý hành chính</title>
        </Helmet>
        <PageTitle
          location={props.location}
          title={'Quản lý hành chính'}
          hiddenGoBack></PageTitle>
        <ContentBlockWrapper style={{ minHeight: 'calc(100vh - 150px)' }}>
          <Row style={{ marginBottom: 16 }}>
            <Col style={{ marginRight: '-50px', marginTop: '5px' }}>
              <SearchBar>
                <Search
                  style={{ width: '80%' }}
                  allowClear
                  value={keywordSearch}
                  onChange={e => setKeywordSearch(e.target.value)}
                  onSearch={onSearchProposal}
                  placeholder={'Tìm kiếm theo tiêu đề'}
                />
              </SearchBar>
            </Col>
            <Col style={{ marginRight: '-50px', marginTop: '5px' }}>
              <SearchBar>
                <Select
                  style={{ width: '80%' }}
                  allowClear
                  onChange={handleFilterByDepartment}
                  placeholder={'-- Chọn phòng ban --'}>
                  {departmentList.map(department => {
                    return (
                      <Option value={department.name}>{department.name}</Option>
                    )
                  })}
                </Select>
              </SearchBar>
            </Col>
            <Col style={{ marginRight: '-50px', marginTop: '5px' }}>
              <SearchBar>
                {/* <DatePicker
                  allowClear
                  style={{ width: '100%' }}
                  onChange={handleFilterByTime}
                  picker="month"
                  placeholder="-- Chọn thời gian --"
                /> */}
                <Select
                  style={{ minWidth: '80%' }}
                  placeholder={'-- Lọc theo trạng thái --'}
                  allowClear
                  onChange={handleFilterStatus}>
                  <Option value={'PENDING'}>Chờ phê duyệt</Option>
                  <Option value={'APPROVAL'}>Đã phê duyệt</Option>
                  <Option value={'REJECT'}>Từ chối</Option>
                </Select>
              </SearchBar>
            </Col>
            <Button
              style={{ marginRight: '10px', marginTop: '5px' }}
              loading={exportLoading}
              onClick={exportProposals}>
              <PrinterOutlined />
              Xuất dữ liệu
            </Button>
          </Row>
          <TableWrapper>
            <Table
              rowKey={record => record.id}
              columns={columns}
              dataSource={proposalList}
              pagination={false}
              scroll={{
                x: 1500,
              }}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={'Không có dữ liệu'}
                  />
                ),
              }}
              // onRow={record => {
              //   return {
              //     onClick: event => {
              //       const isLastColumn =
              //         event.target.cellIndex ===
              //         event.currentTarget.cells.length - 1
              //       const isLastColumnComponent = event.target.id.includes(
              //         'last-column-component-classname'
              //       )
              //       if (!isLastColumn && !isLastColumnComponent) {
              //         history.push(
              //           `/management/proposal/view/${record.proposal_code}`
              //         )
              //       }
              //     },
              //   }
              // }}
              loading={loadingAnimationStore.tableLoading}
            />
            <TableBottomPaginationBlock>
              <Pagination
                current={pageIndex + 1}
                pageSize={pageSize}
                total={totalCountProposal}
                hideOnSinglePage={true}
                onChange={handleChangePageProposalLeave}
                showSizeChanger={false}
              />
            </TableBottomPaginationBlock>
          </TableWrapper>
        </ContentBlockWrapper>
      </DashboardLayout>
    </>
  )
}

export default inject(
  'loadingAnimationStore',
  'proposalStore',
  'departmentStore',
  'authenticationStore'
)(observer(ProposalPage))
