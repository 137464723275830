import { action, observable, toJS } from 'mobx'
// Request
import { TimekeepingSyncRequest } from '../requests/TimekeepingSyncRequest'

import { clone } from 'lodash'
import moment from 'moment'

class TimekeepingStore {
  defaultQueryParams = {
    page: 0,
    size: 10,
    keyword: null,
    isIgnore: null,
    isActive: null,
    late: null,
    early: null,
    absent: null,
    fromDate: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
    toDate: moment(new Date()).endOf('month').format('YYYY-MM-DD'),
    deName: null,
    deId: null,
    checked: false,
    existProposal: false,
  }

  @action clearQueryParams = () => {
    this.queryParams = clone(this.defaultQueryParams)
  }

  @action setQueryParams = payload => {
    this.queryParams = payload
  }
  @action syncTimekeeping = (fromDate, toDate, username) => {
    return new Promise((resolve, reject) => {
      TimekeepingSyncRequest.syncTimekeepingV2(fromDate, toDate, username)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action updateIoInfo = (emCode, time) => {
    return new Promise((resolve, reject) => {
      TimekeepingSyncRequest.updateIoInfo(emCode, time)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @observable
  queryParams = clone(this.defaultQueryParams)
  @action timekeepingSync = param => {
    return new Promise((resolve, reject) => {
      TimekeepingSyncRequest.syncTimekeeping(param)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @observable.ref employeeIoList = []
  @observable employeeList = []
  @observable employeePageList = []
  @observable employeeACSList = []
  @action getEmployee = params => {
    return new Promise((resolve, reject) => {
      TimekeepingSyncRequest.getEmployee(params)
        .then(response => {
          this.employeeList = toJS(response.data.content)
          this.employeePageList = response.data
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  @action exportToExcel = params => {
    return TimekeepingSyncRequest.exportToExcel(params).catch(console.log)
  }
}

export default new TimekeepingStore()
