import React, { useEffect, useState } from 'react'
import { InfoList } from './MyProfilePageStyled'
import { Button, Col, message, Row, Space, Form, Input } from 'antd'
import { inject, observer } from 'mobx-react'
import { EmptyText } from '../../components/Common/CellText'
import validator from '../../validator'

import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  MailFilled,
} from '@ant-design/icons'

const Education = props => {
  const {
    authenticationStore,
    loadingAnimationStore,
    userStore,
    aclStore,
  } = props

  const { currentUser } = authenticationStore
  const nullText = <EmptyText>Chưa cập nhật</EmptyText>

  const [editing, setEditing] = useState(false)

  const [fields, setFields] = useState([
    {
      name: ['education_level'],
      value: undefined,
    },
    {
      name: ['qualification'],
      value: undefined,
    },
    {
      name: ['political_theory'],
      value: undefined,
    },
    {
      name: ['IT'],
      value: undefined,
    },
    {
      name: ['foreign_language'],
      value: undefined,
    },
    {
      name: ['state_management'],
      value: undefined,
    },
    {
      name: ['laudatory'],
      value: undefined,
    },
    {
      name: ['discipline'],
      value: undefined,
    },
    {
      name: ['join_date'],
      value: undefined,
    },
    {
      name: ['official_date'],
      value: undefined,
    },
    {
      name: ['join_political'],
      value: undefined,
    },
    {
      name: ['date_of_enlistment'],
      value: undefined,
    },
    {
      name: ['demobilization_date'],
      value: undefined,
    },
    {
      name: ['army_rank'],
      value: undefined,
    },
    {
      name: ['forte'],
      value: undefined,
    },
    {
      name: ['title'],
      value: undefined,
    },
  ])

  const setFieldsOrigin = () => {
    setFields([
      {
        name: ['education_level'],
        value: currentUser?.other?.education_level,
      },
      {
        name: ['qualification'],
        value: currentUser?.other?.qualification,
      },
      {
        name: ['political_theory'],
        value: currentUser?.other?.political_theory,
      },
      {
        name: ['IT'],
        value: currentUser?.other?.IT,
      },
      {
        name: ['foreign_language'],
        value: currentUser?.other?.foreign_language,
      },
      {
        name: ['state_management'],
        value: currentUser?.other?.state_management,
      },
      {
        name: ['laudatory'],
        value: currentUser?.other?.laudatory,
      },
      {
        name: ['discipline'],
        value: currentUser?.other?.discipline,
      },{
        name: ['join_date'],
        value: currentUser?.other?.join_date,
      },
      {
        name: ['official_date'],
        value: currentUser?.other?.official_date,
      },
      {
        name: ['join_political'],
        value: currentUser?.other?.join_political,
      },
      {
        name: ['date_of_enlistment'],
        value: currentUser?.other?.date_of_enlistment,
      },{
        name: ['demobilization_date'],
        value: currentUser?.other?.demobilization_date,
      },
      {
        name: ['army_rank'],
        value: currentUser?.other?.army_rank,
      },
      {
        name: ['forte'],
        value: currentUser?.other?.forte,
      },
      {
        name: ['title'],
        value: currentUser?.other?.title,
      },
    ])
  }
  const onUpdate = async value => {
    loadingAnimationStore.showSpinner(true)
    try {
      await userStore.updateCurrentUserEducation(value)
      authenticationStore.checkCurrentUser()
      setEditing(false)
      message.success('Cập nhật thông tin thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  useEffect(() => {
    if (!currentUser) return
    setFieldsOrigin()
  }, [currentUser])
  return (
    <>
      <div className={'overview-info'}>
        <Form onFinish={onUpdate} fields={fields}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div></div>
            {!editing && (
              <Button
                type={'primary'}
                icon={<EditOutlined />}
                onClick={() => setEditing(true)}>
                Chỉnh sửa
              </Button>
            )}
            {editing && (
              <Space>
                <Button
                  onClick={() => {
                    setFieldsOrigin()
                    setEditing(false)
                  }}
                  danger
                  icon={<CloseOutlined />}>
                  Hủy bỏ
                </Button>
                <Button
                  type={'primary'}
                  icon={<CheckOutlined />}
                  htmlType={'submit'}>
                  Lưu thay đổi
                </Button>
              </Space>
            )}
          </div>
          <Row gutter={[20, 10]}>
            <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
              <InfoList >
                <dt>Trình độ giáo dục phổ thông</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.education_level || nullText}</div>
                  ) : (
                    <Form.Item
                      name={'education_level'}>
                      <Input
                        placeholder={'Trình độ giáo dục phổ thông'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Trình độ chuyên môn cao nhất</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.qualification || nullText}</div>
                  ) : (
                    <Form.Item
                      name={'qualification'}>
                      <Input
                        placeholder={'Trình độ chuyên môn cao nhất'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Lý luận chính trị</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.political_theory || nullText}</div>
                  ) : (
                    <Form.Item
                      name={'political_theory'}>
                      <Input
                        placeholder={'Lý luận chính trị'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Tin học</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.IT || nullText}</div>
                  ) : (
                    <Form.Item
                      name={'IT'}>
                      <Input
                        placeholder={'trình độ tin học'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Ngoại ngữ</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.foreign_language || nullText}</div>
                  ) : (
                    <Form.Item
                      name={'foreign_language'}>
                      <Input
                        placeholder={'Trình độ ngoại ngữ'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Quản lý nhà nước</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.state_management || nullText}</div>
                  ) : (
                    <Form.Item
                      name={'state_management'}>
                      <Input
                        placeholder={'Quản lý nhà nước'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Khen thưởng</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.laudatory || nullText}</div>
                  ) : (
                    <Form.Item
                      name={'laudatory'}>
                      <Input
                        placeholder={'Khen thưởng'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Kỷ luật</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.discipline || nullText}</div>
                  ) : (
                    <Form.Item
                      name={'discipline'}>
                      <Input
                        placeholder={'Kỷ luật'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}</dd>
              </InfoList>
            </Col>
            <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
              <InfoList >
                <dt>Ngày vào Đảng Cộng sản Việt Nam</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.join_date || nullText}</div>
                  ) : (
                    <Form.Item
                      rules={[{ validator: validator.validateDate }]}
                      name={'join_date'}>
                      <Input
                        placeholder={'DD-MM-YYYY'}
                        style={{ maxWidth: 250 }}
                        type="text"
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Ngày chính thức</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.official_date || nullText}</div>
                  ) : (
                    <Form.Item
                      rules={[{ validator: validator.validateDate }]}
                      name={'official_date'}>
                      <Input
                        placeholder={'DD-MM-YYYY'}
                        style={{ maxWidth: 250 }}
                        type="text"
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Ngày tham gia tổ chức chính trị xã hội</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.join_political || nullText}</div>
                  ) : (
                    <Form.Item
                      rules={[{ validator: validator.validateDate }]}
                      name={'join_political'}>
                      <Input
                        placeholder={'DD-MM-YYYY'}
                        style={{ maxWidth: 250 }}
                        type="text"
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Ngày nhập ngũ</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.date_of_enlistment || nullText}</div>
                  ) : (
                    <Form.Item
                      rules={[{ validator: validator.validateDate }]}
                      name={'date_of_enlistment'}>
                      <Input
                        placeholder={'DD-MM-YYYY'}
                        style={{ maxWidth: 250 }}
                        type="text"
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Ngày xuất ngũ</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.demobilization_date || nullText}</div>
                  ) : (
                    <Form.Item
                      rules={[{ validator: validator.validateDate }]}
                      name={'demobilization_date'}>
                      <Input
                        placeholder={'DD-MM-YYYY'}
                        style={{ maxWidth: 250 }}
                        type="text"
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Quân hàm cao nhất</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.army_rank || nullText}</div>
                  ) : (
                    <Form.Item
                      name={'army_rank'}>
                      <Input
                        placeholder={'Quân hàm'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Sở trường công tác</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.forte || nullText}</div>
                  ) : (
                    <Form.Item
                      name={'forte'}>
                      <Input
                        placeholder={'Sở trường công tác'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Danh hiệu được phong tặng cao nhất</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.title || nullText}</div>
                  ) : (
                    <Form.Item
                      name={'title'}>
                      <Input
                        placeholder={'Danh hiệu'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}</dd>
              </InfoList>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

Education.propTypes = {}

export default inject(
  'authenticationStore',
  'commonStore',
  'loadingAnimationStore',
  'userStore',
  'aclStore'
)(observer(Education))
