import React, { useEffect, useState } from 'react'
import { Button, Form, Input, message, Modal } from 'antd'
import validator from '../../validator'
import departmentStore from '../../stores/departmentStore'
import authenticationStore from '../../stores/authenticationStore'
import { MODAL_MODE } from '../../ui_constants'
import departmentHrStore from '../../stores/departmentHrStore'

const CreateUserAccountModal = props => {
  const {
    setCheckUpdate,
    isVisibleCreateUserAccountModal,
    mode,
    handleCloseCreateUserAccountModal,
    currentDepartment,
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [initialValue, setInitialValue] = useState('')

  const [form] = Form.useForm()

  useEffect(() => {
    const fetchData = async () => {
      if (isVisibleCreateUserAccountModal && mode === MODAL_MODE.UPDATE) {
        setInitialValue(currentDepartment?.name)
        await form.setFieldsValue({
          name: currentDepartment?.name,
        })
      } else {
        form.setFieldsValue({
          name: '',
        })
      }
    }

    fetchData()
  }, [isVisibleCreateUserAccountModal, mode])

  const onFinish = async values => {
    try {
      setIsLoading(true)

      const { name } = values
      if (mode === MODAL_MODE.UPDATE) {
        if (name.trim() !== currentDepartment.name)
          await departmentHrStore.updateHr(currentDepartment.code, name)
      } else {
        await departmentHrStore.createHr(name)
      }

      closeAndResetForm()

      message.success(
        `${
          mode === MODAL_MODE.UPDATE ? `Chỉnh sửa` : `Tạo mới`
        } phòng ban thành công`
      )
      setCheckUpdate(true)
      await departmentHrStore.getAllDepartmentHrList(
        authenticationStore.currentCompanyCode()
      )
    } catch (e) {
      if (
        (e.errorCode && e.errorCode === 'DEPARTMENT-102') ||
        (e.vi && e.vi === 'Đã có lỗi xảy ra trong quá trình kết nối!') ||
        (e.errorCode && e.errorCode === 'API-501')
      ) {
        message.error('Tên phòng ban đã tồn tại')
      } else {
        message.error('Đã có lỗi xảy ra!')
      }
    } finally {
      setIsLoading(false)
      setCheckUpdate(false)
    }
  }

  const closeAndResetForm = () => {
    form.resetFields()
    form.setFieldsValue({
      name: initialValue,
    })
    handleCloseCreateUserAccountModal()
  }

  return (
    <Modal
      title={`${
        mode === MODAL_MODE.UPDATE ? `Chỉnh sửa` : `Tạo mới`
      } phòng ban`}
      footer={null}
      closable={true}
      visible={isVisibleCreateUserAccountModal}
      onCancel={closeAndResetForm}>
      <Form form={form} layout={'vertical'} onFinish={onFinish}>
        <Form.Item
          name={'name'}
          rules={[
            { required: true, message: 'Vui lòng nhập tên phòng ban!' },
            { validator: validator.validateInputString },
          ]}
          label={'Tên phòng ban:'}>
          <Input placeholder={'Nhập tên phòng ban'} />
        </Form.Item>

        <Form.Item>
          <Button loading={isLoading} htmlType={'submit'} type={'primary'}>
            {mode === MODAL_MODE.UPDATE ? `Chỉnh sửa` : `Tạo mới`}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

CreateUserAccountModal.propTypes = {}

export default CreateUserAccountModal
