import React, { useEffect, useState } from 'react'
import { InfoList } from './MyProfilePageStyled'
import { Button, Col, message, Row, Space, Form, Input } from 'antd'
import { inject, observer } from 'mobx-react'
import { EmptyText } from '../../components/Common/CellText'
import moment from 'moment'
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  MailFilled,
} from '@ant-design/icons'
import validator from '../../validator'

const Health = props => {
  const {
    authenticationStore,
    loadingAnimationStore,
    userStore,
    aclStore,
  } = props

  const { currentUser } = authenticationStore
  const { aclAclGroupName } = aclStore
  const nullText = <EmptyText>Chưa cập nhật</EmptyText>

  const [editing, setEditing] = useState(false)

  const [fields, setFields] = useState([
    {
      name: ['health_status'],
      value: undefined,
    },
    {
      name: ['height'],
      value: undefined,
    },
    {
      name: ['weight'],
      value: undefined,
    },
    {
      name: ['blood_types'],
      value: undefined,
    },
    {
      name: ['wounded_soldier_class'],
      value: undefined,
    },
    {
      name: ['child_policy_family'],
      value: undefined,
    },
  ])

  const setFieldsOrigin = () => {
    setFields([
      {
        name: ['health_status'],
        value: currentUser?.other?.health_status,
      },
      {
        name: ['height'],
        value: currentUser?.other?.height,
      },
      {
        name: ['weight'],
        value: currentUser?.other?.weight,
      },
      {
        name: ['blood_types'],
        value: currentUser?.other?.blood_types,
      },
      {
        name: ['license_date'],
        value: currentUser?.other?.license_date,
      },
      {
        name: ['wounded_soldier_class'],
        value: currentUser?.other?.wounded_soldier_class,
      },
      {
        name: ['child_policy_family'],
        value: currentUser?.other?.child_policy_family,
      },
    ])
  }
  const onUpdate = async value => {
    loadingAnimationStore.showSpinner(true)
    try {
      await userStore.updateCurrentUserHealth(value)
      authenticationStore.checkCurrentUser()
      setEditing(false)
      message.success('Cập nhật thông tin thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  useEffect(() => {
    if (!currentUser) return
    setFieldsOrigin()
  }, [currentUser])
  return (
    <>
      <div className={'overview-info'}>
        <Form onFinish={onUpdate} fields={fields}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h2 style={{ marginBottom: 0, textTransform: 'capitalize' }}>
              {currentUser?.name_lowercase || nullText}
              <span className={'username'}>({currentUser?.username})</span>
            </h2>
            {!editing && (
              <Button
                type={'primary'}
                icon={<EditOutlined />}
                onClick={() => setEditing(true)}>
                Chỉnh sửa
              </Button>
            )}
            {editing && (
              <Space>
                <Button
                  onClick={() => {
                    setFieldsOrigin()
                    setEditing(false)
                  }}
                  danger
                  icon={<CloseOutlined />}>
                  Hủy bỏ
                </Button>
                <Button
                  type={'primary'}
                  icon={<CheckOutlined />}
                  htmlType={'submit'}>
                  Lưu thay đổi
                </Button>
              </Space>
            )}
          </div>
          <Row gutter={[20, 10]}>
            <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
              <InfoList>
                <dt>Tình trạng sức khỏe</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.health_status || nullText}</div>
                  ) : (
                    <Form.Item
                      name={'health_status'}>
                      <Input
                        placeholder={'Tình trạng sức khỏe'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Chiều cao</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.height || nullText}</div>
                  ) : (
                    <Form.Item
                      name={'height'}>
                      <Input
                        placeholder={'Chiều cao'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Cân nặng</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.weight || nullText}</div>
                  ) : (
                    <Form.Item
                      name={'weight'}>
                      <Input
                        placeholder={'Cân nặng'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Nhóm máu</dt>
                <dd>
                  {!editing ? (
                    <div>{currentUser?.other?.blood_types || nullText}</div>
                  ) : (
                    <Form.Item
                      name={'blood_types'}>
                      <Input
                        placeholder={'Nhóm máu'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}
                </dd>
              </InfoList>
            </Col>
            <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
              <InfoList>
                <dt>Là thương binh hạng</dt>
                <dd>
                  {!editing ? (
                    <div>{currentUser?.other?.wounded_soldier_class || nullText}</div>
                  ) : (
                    <Form.Item name={'wounded_soldier_class'}>
                      <Input
                        placeholder={'hạng thương binh'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}
                </dd>
                <dt>Là con gia đình chính sách</dt>
                <dd>
                  {!editing ? (
                    <div>{currentUser?.other?.child_policy_family || nullText}</div>
                  ) : (
                    <Form.Item name={'child_policy_family'}>
                      <Input
                        placeholder={'gia đình chính sách'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}
                </dd>
              </InfoList>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

Health.propTypes = {}

export default inject(
  'authenticationStore',
  'commonStore',
  'loadingAnimationStore',
  'userStore',
  'aclStore'
)(observer(Health))
