import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const TimekeepingSyncRequest = {
  syncTimekeeping: data =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/timekeepingSync`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),
  getEmployee: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/timekeepingSync`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: { ...params, assignee: null },
    }),
  getTimekeeping: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/timekeepingSync/users?${
        params.assignee ? params.assignee : ''
      }`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: { ...params, assignee: null },
    }),
  getById: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/timekeepingSync/users?${params}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: { ...params, assignee: null },
    }),

  syncTimekeepingV2: (fromDate, toDate, username) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/syncTimekeepingV2/${fromDate}/${toDate}/${username}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/text',
      },
    }),
  updateIoInfo: (emCode, time) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/syncTimekeepingV2/${emCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/text',
      },
      params: { time: time },
    }),

  exportToExcel: ({ keyword, deId, fromDate, toDate }) => {
    return axios({
      method: 'GET',
      url: `${apiUrl}/api/v1/timekeepingSync/export`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        keyword,
        deId,
        fromDate,
        toDate,
      },
      responseType: 'blob',
    })
  },
  getProposalsByDay: date =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/timekeepingSync/proposal/${date}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  getWorkingTimeReport: ({ username, fromDate, toDate }) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/timekeepingSync/${username}`,
      params: {
        fromDate,
        toDate,
      },
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
}
