import React, { useCallback } from 'react'
import { Button, Form, Input, message, Modal } from 'antd'
import acsOfficeDayStore from '../../stores/acsOfficeDayStore'
import { useEffect } from 'react'

const EditAcsOfficeDayNoteModal = props => {
  const {
    isVisibleEditNoteModal,
    handleCloseEditNoteModal,
    GetAcsOfficeDayList,
    officeDayId,
    officeDayNote,
    loadingAnimationStore,
  } = props

  const [form] = Form.useForm()

  const onFinish = async values => {
    try {
      const { note } = values
      loadingAnimationStore.showSpinner(true)
      await acsOfficeDayStore.updateAcsOfficeDayNote(officeDayId, note)
      handleCloseEditNoteModal()
      GetAcsOfficeDayList()
      message.success(`Đã thay đổi ghi chú`)
    } catch {
      message.error
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const closeAndResetForm = () => {
    form.resetFields()
    handleCloseEditNoteModal()
  }

  const setDefaultInfo = useCallback(() => {
    form.setFieldsValue({ note: officeDayNote })
  }, [officeDayNote])

  useEffect(() => {
    setDefaultInfo()
  }, [officeDayNote])

  return (
    <Modal
      title={`Chỉnh sửa ghi chú`}
      footer={null}
      closable={true}
      visible={isVisibleEditNoteModal}
      onCancel={closeAndResetForm}
      forceRender>
      <Form form={form} layout={'vertical'} onFinish={onFinish}>
        <Form.Item
          name={'note'}
          label={'Ghi chú:'}
          initialValue={officeDayNote}>
          <Input placeholder={'Nhập ghi chú'} allowClear />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type={'primary'}>
            Chỉnh sửa
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditAcsOfficeDayNoteModal
