import React, { useCallback, useEffect, useState } from 'react'
import { Button, Form, Modal, Space } from 'antd'
import { inject, observer } from 'mobx-react'
import { FormButtonGroup } from '../../components/AddUserForm/AddUserFormStyled'
import { message, Upload } from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons'
import XLSX from 'xlsx'
import { saveAs } from 'file-saver'

const { Dragger } = Upload

const ModelImportExcel = props => {
  const [form] = Form.useForm()

  const {
    modalVisible,
    authenticationStore,
    departmentStore,
    positionStore,
    onCancel,
  } = props

  const { currentUser } = authenticationStore
  const { departmentList } = departmentStore
  const { positionList } = positionStore

  const [departmentName, setDepartmentName] = useState([])
  const [positionName, setPositionName] = useState([])

  useEffect(() => {
    const newDepartmentName = departmentList.map(department => department?.name)
    setDepartmentName(newDepartmentName)
  }, [departmentList])

  useEffect(() => {
    const newPositionName = positionList.map(position => position?.name)
    setPositionName(newPositionName)
  }, [positionList])
  const companyName = currentUser?.company?.name
  useEffect(() => {
    const fetchDepartmentList = async () => {
      try {
        const companyCode = authenticationStore.currentCompanyCode()
        if (companyCode) {
          await departmentStore.getDepartmentListFromCompanyCode(companyCode)
        }
      } catch (error) {
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      }
    }

    fetchDepartmentList()
  }, [currentUser?.company?.code])

  // TODO
  useEffect(() => {
    const fetchPositionList = async () => {
      try {
        await positionStore.getPositionList()
      } catch (error) {
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      }
    }
    fetchPositionList()
  }, [])

  const handleExportTemplate = () => {
    // Define the data for the Excel file
    // Tạo workbook mới
    const workbook = XLSX.utils.book_new()
    // Tạo sheet mới
    const sheet = XLSX.utils.json_to_sheet([])
    // Tạo header row
    XLSX.utils.sheet_add_aoa(
      sheet,
      [
        [
          'STT',
          'Họ tên',
          'Username',
          'Giới tính',
          'Bộ phận',
          'Vị trí công việc',
          'Email',
          'Số điện thoại',
          'Số máy nội bộ',
          'Tên công ty',
        ],
      ],
      { origin: 'A1' }
    )
    XLSX.utils.sheet_add_aoa(
      sheet,
      [['STT', 'Tên phòng ban', 'STT', 'Tên vị trí']],
      {
        origin: 'M1',
      }
    )
    // Thêm dữ liệu vào sheet
    const rowData = [
      '1',
      'Nguyễn Văn A',
      'anv',
      'Nam',
      departmentName[0],
      positionName[0],
      'anv@gmail.com',
      '0966666666',
      '123',
      companyName,
    ]
    const dataDept = departmentName.map((name, index) => [index + 1, name])
    const dataPos = positionName.map((name, index) => [index + 1, name])

    XLSX.utils.sheet_add_aoa(sheet, [rowData], { origin: 'A2' })
    XLSX.utils.sheet_add_aoa(sheet, dataDept, { origin: 'M2' })
    XLSX.utils.sheet_add_aoa(sheet, dataPos, { origin: 'O2' })
    //TODO
    //Thêm kiểm tra dữ liệu vào sheet
    // const validationValues1 = departmentNames
    // const validationValues2 = positionNames
    // const validationData1 = {
    //   type: 'list',
    //   allowBlank: true,
    //   formulae: [`"${validationValues1.join(',')}"`],
    // }
    // const validationData2 = {
    //   type: 'list',
    //   allowBlank: true,
    //   formulae: [`"${validationValues2.join(',')}"`],
    // }
    // sheet.addDataValidation({
    //   type: 'list',
    //   allowBlank: true,
    //   formulae: [
    //     `"${validationValues1.join(',')}"`,
    //     `"${validationValues2.join(',')}"`,
    //   ],
    //   ranges: [
    //     { s: { c: 4, r: 1 }, e: { c: 4, r: 1 } },
    //     { s: { c: 5, r: 1 }, e: { c: 5, r: 1 } },
    //   ],
    // })

    // Thêm sheet vào workbook
    XLSX.utils.book_append_sheet(workbook, sheet, 'Danh sách nhân viên')
    // Xuất file Excel
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    })
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
    saveAs(blob, 'danh-sach-nhan-vien.xlsx')
  }
  const handleCancelModal = useCallback(() => {
    onCancel()
  }, [form, onCancel])
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff
    }
    return buf
  }
  return (
    <Modal
      className="cus-modal"
      title={'Thêm mới nhân viên'}
      getContainer={false}
      footer={null}
      visible={modalVisible}
      onCancel={handleCancelModal}>
      <Form
        scrollToFirstError={true}
        form={form}
        name="add-user-form"
        layout={'horizontal'}>
        <Dragger
          className="custom-dragger"
          accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          beforeUpload={() =>  false}
          multiple={false}>
          <CloudUploadOutlined style={{ fontSize: '45px' }} />
          <p>Kéo thả hoặc click vào để tải lên tài liệu</p>
        </Dragger>
        <FormButtonGroup style={{ textAlign: 'right' }}>
          <Space size={'middle'}>
            <Button onClick={handleExportTemplate}>Tải bản mẫu</Button>
            <Button type={'primary'}>Nhập</Button>
          </Space>
          <Button type={'danger'} onClick={handleCancelModal}>
            Huỷ bỏ
          </Button>
        </FormButtonGroup>
      </Form>
    </Modal>
  )
}

export default inject(
  'authenticationStore',
  'loadingAnimationStore',
  'departmentStore',
  'positionStore'
)(observer(ModelImportExcel))
