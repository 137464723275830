import { Button, Form, Input, List, message } from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { memo, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import { useSSO } from '../../config'
import { ACL_ACTION_TYPE, MODULE_CODE } from '../../constants'
import DashboardLayout from '../../layouts/DashboardLayout'
import utils from '../../utils'
import validator from '../../validator'
import { LoginWrapper } from '../PortalPageV2/PortalPageV2Styled'
import { GeneralNotif, RowWrapper } from './DashboardPageV2Styled'
import { Bar, Pie } from 'react-chartjs-2'
import { toJS } from 'mobx'
import acsOfficeDayStore from '../../stores/acsOfficeDayStore'

const DashboardPageV2 = props => {
  const {
    history,
    authenticationStore,
    accountStore,
    userStore,
    moduleStore,
    aclStore,
    tintucStore,
    timekeepingInfoStore,
    departmentStore,
  } = props

  const { currentUser, isAccountAdmin, isSuperAdmin } = authenticationStore
  const { aclActionsByUser } = aclStore
  const { newsList } = tintucStore
  const [isLoadingTinTuc, setIsLoadingTinTuc] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [, setShowDialog] = useState(false)

  const isAccessControl = (moduleCode, accessControlType) => {
    if (isSuperAdmin) return true
    if (!moduleStore.checkAccessModule(moduleCode)) return false
    if (accessControlType)
      return (
        isAccountAdmin ||
        !!aclActionsByUser.find(item => item.code === accessControlType)?.status
      )
    return true
  }

  const [timekeeping, setTimekeeping] = useState([])
  const [valids, setValids] = useState(0)
  const [invalids, setInvalids] = useState(0)
  const [proposals, setProposals] = useState(0)
  const currentMonth = new Date().getMonth() + 1
  const currentYear = new Date().getFullYear()
  const [officeDay, setOfficeDay] = useState([])
  const [total, setTotal] = useState(0)
  const [queryParamTimekeeping] = useState({
    page: 0,
    size: getDaysInMonth(currentMonth, currentYear),
    fromDate: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
    toDate: moment(new Date()).endOf('month').format('YYYY-MM-DD'),
  })
  const [queryParamOfficeDay] = useState({ page: 0, size: 1000 })
  const getTimeKeeping = async queryParamTimekeeping => {
    await timekeepingInfoStore
      .getTimekeepingForUserNew(queryParamTimekeeping)
      .then(response => {
        setTimekeeping(toJS(response.data.content))
      })
  }
  const checkIfWeekend = date => {
    const dayOfWeek = date.getDay()
    return dayOfWeek === 0 || dayOfWeek === 6
  }
  const validsByCurrentMonth = () => {
    setValids(0)
    let count = 0
    timekeeping.forEach(item => {
      const isWeekend = new Date(item?.timekeepingDay)
      if (
        item.absent === true &&
        item.isValid === true &&
        !checkIfWeekend(isWeekend)
      ) {
        count++
      }
    })
    setValids(count)
  }

  const invalidsByCurrentMonth = () => {
    setInvalids(0)
    let count = 0
    timekeeping.forEach(item => {
      if (
        (item.absent === false &&
          item.late === false &&
          item.early === true &&
          item.isValid === false) ||
        (item.absent === true &&
          item.late === null &&
          item.early === null &&
          item.isValid === false)
      ) {
        count++
      }
    })
    setInvalids(count)
  }
  const proposalsByCurrentMonth = () => {
    setProposals(0)
    let count = 0
    timekeeping.forEach(item => {
      if (item.proposalList.length > 0) {
        count++
      }
    })
    setProposals(count)
  }

  const totalByCurrentMonth = () => {
    setTotal(0)
    let count = 0
    officeDay.forEach(item => {
      if (
        item.status === true &&
        moment(item.timeKeeping) >= moment(new Date()).startOf('month') &&
        moment(item.timeKeeping) <= moment(new Date()).endOf('month')
      ) {
        count++
      }
    })
    setTotal(count)
  }

  function getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate()
  }

  const getAcsOfficeDays = async queryParamOfficeDay => {
    await acsOfficeDayStore
      .getAcsOfficeDayListNew(queryParamOfficeDay)
      .then(response => {
        setOfficeDay(response.data.content)
      })
  }

  useEffect(() => {
    if (currentUser != null) {
      getTimeKeeping(queryParamTimekeeping)
      getAcsOfficeDays(queryParamOfficeDay)
    }
  }, [queryParamTimekeeping, queryParamOfficeDay, currentUser])

  useEffect(() => {
    validsByCurrentMonth()
    invalidsByCurrentMonth()
    proposalsByCurrentMonth()
    totalByCurrentMonth()
  }, [timekeeping, officeDay])

  const { departmentListAndCount } = departmentStore

  useEffect(() => {
    if (currentUser != null) {
      departmentStore.getNumberOfUsersInDepartment(
        authenticationStore.currentCompanyCode()
      )
    }
  }, [authenticationStore.currentCompanyCode()])

  useEffect(() => {
    return () => {
      tintucStore.clear()
    }
  }, [])

  const handleOk = async values => {
    setConfirmLoading(true)
    try {
      const response = await authenticationStore.userLogin(
        values.identifier,
        values.password
      )
      if (response.status === 200) {
        const res = await authenticationStore.checkCurrentUser()
        await authenticationStore.getCurrentUserAvatar()
        await userStore.getMentionUserList()
        await accountStore.getCurrentUserAccount()
        await moduleStore.getModuleList()
        await aclStore.getACLDetailByUser(res.data.username)
        setShowDialog(false)
        message.success(
          `Xin chào, ${utils.getNameInCapitalize(res.data.name_uppercase)}!`
        )
      }
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Login failed response status!')
    } finally {
      setConfirmLoading(false)
    }
  }

  const loginPage = (
    <>
      <LoginWrapper
        bgImage={`${process.env.PUBLIC_URL}/assets/photos/auth-bg-2.jpg`}>
        {useSSO ? (
          <></>
        ) : (
          <Form layout={'vertical'} name={'loginForm'} onFinish={handleOk}>
            <Form.Item style={{ textAlign: 'center' }}>
              <img
                alt="login_logo"
                height={32}
                src={`${process.env.PUBLIC_URL}/assets/photos/portal_logo_white.png`}
              />
            </Form.Item>
            <Form.Item
              label="Tên đăng nhập"
              name="identifier"
              rules={[
                { required: true, message: 'Vui lòng nhập tên đăng nhập!' },
                { validator: validator.validateUsername },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Mật khẩu"
              name="password"
              rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}>
              <Input.Password />
            </Form.Item>
            <Button
              type={'primary'}
              block
              htmlType={'submit'}
              loading={confirmLoading}>
              {' '}
              Đăng nhập
            </Button>
            <Button style={{ marginTop: 8 }} type={'link'} block>
              {' '}
              Quên mật khẩu?
            </Button>
            {/*<Button style={{ marginTop: 0 }} type={'link'} block onClick={showModal}> Lịch cơ quan</Button>*/}
          </Form>
        )}
      </LoginWrapper>
    </>
  )

  const valid = Math.round((valids / total) * 100) || 0
  const invalid = Math.round((invalids / total) * 100) || 0
  const proposal = Math.round((proposals / total) * 100) || 0
  const noInfo =
    Math.round(((total - valids - invalids - proposals) / total) * 100) || 100

  const pieData = {
    labels: [
      'Hợp lệ',
      'Không hợp lệ',
      'Có đơn hành chính',
      'Chưa có thông tin',
    ],
    datasets: [
      {
        label: 'Thông tin chấm công',
        backgroundColor: [
          'rgba(0, 170, 0, 0.8)',
          'rgba(255, 0, 0, 0.8)',
          'rgba(255, 205, 85, 0.8)',
          'rgb(245, 245, 245)',
        ],
        borderColor: 'white',
        hoverBackgroundColor: [
          'rgba(0, 170, 0, 0.8)',
          'rgba(255, 0, 0, 0.8)',
          'rgba(255, 206, 86, 0.8)',
          'rgb(245, 245, 245)',
        ],
        data: [valid, invalid, proposal, noInfo],
      },
    ],
  }

  const pieOptions = {
    maintainAspectRatio: true,
    responsive: true,
    animation: false,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex]
          var label = data.labels[tooltipItem.index]
          var currentValue = dataset.data[tooltipItem.index]
          return label + ': ' + currentValue + '%'
        },
      },
    },
  }

  const PieChart = () => {
    return (
      <div>
        <h2>Giải trình chấm công</h2>
        <div style={{ cursor: 'pointer' }}>
          <Pie
            data={pieData}
            options={pieOptions}
            onElementsClick={event => {
              if (event.length === 1) {
                history.push(`/timekeeping-info`)
              }
            }}
          />
        </div>
      </div>
    )
  }

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoadingTinTuc(true)
        await tintucStore.getAllNews()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
        setIsLoadingTinTuc(false)
      } finally {
        setIsLoadingTinTuc(false)
      }
    })()
  }, [])

  function getRandomColor() {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  const usedColor = []

  const [barData, setBarData] = useState({
    labels: [''],
    datasets: [],
  })

  useEffect(() => {
    const datasets = departmentListAndCount.map(department => {
      let color
      do {
        color = getRandomColor()
      } while (usedColor.includes(color))
      usedColor.push(color)
      return {
        label: department.name,
        backgroundColor: color,
        hoverBackgroundColor: color,
        data: [department.number_of_users],
      }
    })
    const sortedData = [...datasets].sort((a, b) => a.data[0] - b.data[0])
    setBarData({ ...barData, datasets: sortedData })
  }, [departmentListAndCount])

  const barOptions = {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontSize: 10,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          scaleFontSize: 12,
        },
      ],
    },
    animation: false,
  }

  const BarChart = () => {
    return (
      <div
        style={{
          width: '80%',
          height: '100%',
          display: 'inline-block',
        }}>
        <h2>Nhân sự các phòng ban</h2>
        <Bar data={barData} options={barOptions} />
      </div>
    )
  }

  return (
    <div>
      {currentUser != null ? (
        <DashboardLayout
          title={'Link People - Link the World'}
          backgroundColor={'#f2f3f8'}>
          <Helmet>
            <title>Dashboard | VIMC Portal</title>
          </Helmet>
          <div style={{ position: 'relative' }}>
            <RowWrapper>
              <div
                style={{
                  textAlign: 'center',
                  display: 'block',
                  width: '49%',
                  border: 'rgba(255, 255, 255, 1) solid 2px',
                  borderRadius: '2%',
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                  padding: '2%',
                }}>
                <PieChart />
              </div>
              {
                <GeneralNotif
                  header={<div>Thông báo chung </div>}
                  footer={false}
                  bordered
                  dataSource={newsList.slice(0, 4)}
                  renderItem={item => (
                    <List.Item
                      key={item.id}
                      onClick={() => {
                        history.push(
                          `/utility/general-notifications/view/${item.id}`
                        )
                      }}>
                      {item.subject}
                    </List.Item>
                  )}>
                  <SpinnerInlineComponent
                    sizeSpin={'small'}
                    isLoading={isLoadingTinTuc}
                    backGround={'#ffffffc7'}
                    tip={'Đang tải thông báo chung...'}
                  />
                </GeneralNotif>
              }
            </RowWrapper>
            <div
              style={{
                textAlign: 'center',
                border: 'rgba(255, 255, 255, 1) solid 2px',
                borderRadius: '2%',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                marginTop: '2%',
                paddingTop: '2%',
              }}>
              <BarChart />
            </div>
          </div>
        </DashboardLayout>
      ) : (
        loginPage
      )}
    </div>
  )
}

export default memo(
  inject(
    'authenticationStore',
    'accountStore',
    'userStore',
    'notificationStore',
    'moduleStore',
    'tintucStore',
    'aclStore',
    'timekeepingInfoStore',
    'departmentStore',
    'acsOfficeDayStore'
  )(observer(DashboardPageV2))
)
