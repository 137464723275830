import { action, observable, toJS } from 'mobx'
import { AcsOfficeDayRequest } from '../requests/AcsOfficeDayRequest'
import { clone } from 'lodash'

class AcsOfficeDayStore {
  defaultQueryParams = {
    page: 0,
    size: 10,
  }

  @observable officeDayList = []
  @observable pageList = []
  @observable queryParams = clone(this.defaultQueryParams)
  @observable officeDayById = []

  @action setQueryParams = payload => {
    this.queryParams = payload
  }

  @action getAcsOfficeDayList = () => {
    return new Promise((resolve, reject) => {
      AcsOfficeDayRequest.getAcsOfficeDayList(this.queryParams)
        .then(response => {
          this.officeDayList = response.data.content
          this.pageList = response.data
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  @action getAcsOfficeDayListNew = param => {
    return new Promise((resolve, reject) => {
      AcsOfficeDayRequest.getAcsOfficeDayList(param)
        .then(response => {
          this.officeDayList = response.data.content
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  @action updateAcsOfficeDayStatus = id => {
    return new Promise((resolve, reject) => {
      AcsOfficeDayRequest.updateAcsOfficeDayStatus(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  @action updateAcsOfficeDayNote = (officeDayId, note) => {
    return new Promise((resolve, reject) => {
      AcsOfficeDayRequest.updateAcsOfficeDayNote(officeDayId, note)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }
}

export default new AcsOfficeDayStore()
