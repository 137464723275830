import { ExportOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import * as FileSaver from 'file-saver'
import React, { useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import RichText from '../../components/RichEditor/RichText'
import date_format from '../../date_format'
import { HeadingButtonWrapper } from '../MissionDetailPage/MissionDetailPageStyled'
import { TD, TH, TR } from './tableStyle'
// import timekeepingStore from '../../stores/timekeepingStore'
import { inject, observer } from 'mobx-react'
import { PO } from '../../constants'
import loadingAnimationStore from '../../stores/loadingAnimationStore'
import { dowloaderUtils } from '../../utils/dowloader.utils'

const ExportTimeKeepingToExcel = props => {
  const {
    employeeIoList,
    onCloser,
    timekeepingStore,
    commandStore,
    params,
    departmentList,
  } = props

  const [isVisibleModal, setVisibleModal] = useState(false)
  const [table, setTable] = useState({})
  const [link, setLink] = useState([])
  const [exportLoading, setExportLoading] = useState(false)
  const [exportSplitLoading, setExportSplitLoading] = useState(false)
  const { commandList } = commandStore
  useEffect(() => {
    commandStore.getCommandList()
  }, [])
  useEffect(() => {
    const menuCodes = [PO]
    setLink(commandList.filter(item => menuCodes.includes(item.code)))
  }, [commandList])
  const url = link?.map(item => item.url)

  const pageType = proposal_type => {
    switch (proposal_type) {
      case 'LEAVE':
      case 'LEAVE_ANNUAL':
      case 'LEAVE_NO_SALARY':
      case 'BUSINESS_TRIP':
      case 'EVALUATE_MEMBER':
      case 'EVALUATE_LEADER':
      case 'TIMEKEEPER':
      case 'STATIONERY':
      case 'MEETING':
      case 'VEHICLE':
      case 'SICK':
        return 'other'
      default:
        return ''
    }
  }

  useEffect(() => {
    if (employeeIoList) {
      const columns = (
        <TR>
          {/* <td>STT</td> */}
          <TH>Tài khoản</TH>
          <TH>Họ tên</TH>
          <TH>Phòng ban</TH>
          <TH>Ngày</TH>
          <TH>Vào - sáng</TH>
          <TH>Ra - chiều</TH>
          <TH>Đi muộn</TH>
          <TH>Về sớm</TH>
          <TH>Vắng mặt</TH>
          <TH>Liên kết hành chính</TH>
        </TR>
      )
      const rows = employeeIoList.map((item, index) => (
        <TR key={index}>
          {/* <td>{item.stt}</td> */}

          <TD>
            <RichText
              htmlText={
                item.username === null ? 'chưa cập nhật' : item.username
              }
            />
          </TD>
          <TD>
            <RichText
              htmlText={item.emName === null ? 'chưa cập nhật' : item.emName}
            />
          </TD>

          <TD>
            <RichText
              htmlText={item.deName === null ? 'chưa cập nhật' : item.deName}
            />
          </TD>

          <TD>
            {item.timekeepingDay === null
              ? 'chưa cập nhật'
              : date_format.DATE_FORMAT(item.timekeepingDay)}
          </TD>
          <TD>
            {item.ioTimeIn === null
              ? 'chưa cập nhật'
              : date_format.HH_mm(item.ioTimeIn)}
          </TD>
          <TD>
            {item.ioTimeOut === null
              ? 'chưa cập nhật'
              : date_format.HH_mm(item.ioTimeOut)}
          </TD>
          <TD>{!item.absent && item.late ? 'Đã đi muộn' : ''}</TD>
          <TD>{!item.absent && item.early ? 'Đã về sớm' : ''}</TD>
          <TD>{item.absent ? 'Đã vắng mặt' : ''}</TD>
          <TD>
            {item && item?.proposalList?.length >= 1 ? (
              <div
                style={{
                  width: 'auto',
                  overflow: 'overlay',
                }}>
                {item.proposalList[0] !== null
                  ? item.proposalList?.map(item => (
                      <div key={item.proposalCode}>
                        <a
                          href={`${url}/proposal/${pageType(
                            item.proposalType
                          )}/view/${item.proposalCode}`}
                          target="_blank"
                          color="fff">
                          <span style={{ marginRight: '3px' }}>
                            {item.title}
                          </span>
                        </a>
                      </div>
                    ))
                  : ''}
              </div>
            ) : (
              ''
            )}
          </TD>
        </TR>
      ))

      setTable({
        columns: columns,
        rows: rows,
      })
    } else {
      setTable({})
    }
  }, [employeeIoList])

  const htmlTableToExcel = async () => {
    var wb = XLSX.utils.table_to_book(document.getElementById('tableTime'), {
      raw: true,
    })
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const fileName = 'export-timekeeping'
    const data = new Blob([excelBuffer], { type: fileType })

    console.log(fileName + fileExtension)
    FileSaver.saveAs(data, fileName + fileExtension)
    onClosers()
  }
  const onClosers = () => {
    onCloser()
    setVisibleModal(false)
  }
  const openExport = async () => {
    loadingAnimationStore.showSpinner(true)
    setExportLoading(true)
    timekeepingStore
      .exportToExcel(params)
      .then(response => {
        const { fromDate, toDate, keyword, deId } = params
        let deName = ''
        if (deId && deId.length > 0) {
          let deCode = deId[0]
          let deList = departmentList.filter(de => de.code === deCode)
          if (deList && deList.length > 0) {
            deName = deList[0].name + ' '
          }
        }
        dowloaderUtils.dowloadFileFromResponse(response.data, {
          filename: `${deName}${
            keyword ? `${keyword} c` : 'C'
          }hấm công ${fromDate} - ${toDate}.xlsx`,
        })
      })
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
        setExportLoading(false)
      })
  }

  const openExportSplit = async () => {
    departmentList?.forEach(department => {
      let deId = [department.code]
      const paramRequest = { ...params, deId: deId }

      timekeepingStore
        .exportToExcel(paramRequest)
        .then(response => {
          const { fromDate, toDate, keyword, deId } = paramRequest
          let deName = ''
          if (deId && deId.length > 0) {
            let deCode = deId[0]
            let deList = departmentList.filter(de => de.code === deCode)
            if (deList && deList.length > 0) {
              deName = deList[0].name + ' '
            }
          }
          dowloaderUtils.dowloadFileFromResponse(response.data, {
            filename: `${deName}${
              keyword ? `${keyword} c` : 'C'
            }hấm công ${fromDate} - ${toDate}.xlsx`,
          })
        })
        .finally(() => {})
    })
  }

  return (
    <>
      <Button
        icon={<ExportOutlined />}
        loading={exportLoading}
        onClick={openExport}>
        Xuất dữ liệu tổng
      </Button>
      <Button
        style={{ marginLeft: 5 }}
        icon={<ExportOutlined />}
        loading={exportSplitLoading}
        onClick={openExportSplit}>
        Xuất dữ liệu ban
      </Button>

      <Modal
        width={1200}
        style={{ top: 40 }}
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div>Xem trước dữ liệu xuất ra</div>
            <div style={{ display: 'flex', gap: 10 }}>
              <Button onClick={onClosers} danger>
                Đóng
              </Button>
              <HeadingButtonWrapper>
                <Button
                  onClick={htmlTableToExcel}
                  type={'primary'}
                  icon={<ExportOutlined />}>
                  Thực hiện
                </Button>
              </HeadingButtonWrapper>
            </div>
          </div>
        }
        visible={isVisibleModal}
        maskClosable={false}
        closable={false}
        footer={null}>
        {isVisibleModal ? (
          <table id="tableTime" key={'tableTime'}>
            <thead>{table.columns && table.columns}</thead>
            <tbody>{table.rows && table.rows}</tbody>
          </table>
        ) : (
          <></>
        )}
      </Modal>
    </>
  )
}

ExportTimeKeepingToExcel.propTypes = {}

export default inject(
  'loadingAnimationStore',
  'timekeepingStore',
  'commandStore'
)(observer(ExportTimeKeepingToExcel))
