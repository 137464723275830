import { DoubleRightOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { memo } from 'react'
import date_format from '../../date_format'
import { EmptyText } from './../../components/Common/CellText'

const TimekeepingInfoDayOfAbsence = props => {
  const { date, link, proposalsByDay } = props
  const pageType = proposal_type => {
    // switch (proposal_type) {
    //   case 'LEAVE':
    //   case 'LEAVE_ANNUAL':
    //   case 'LEAVE_NO_SALARY':
    //   case 'BUSINESS_TRIP':
    //   case 'EVALUATE_MEMBER':
    //   case 'EVALUATE_LEADER':
    //   case 'TIMEKEEPER':
    //   case 'STATIONERY':
    //   case 'MEETING':
    //   case 'VEHICLE':
    //   case 'SICK':
    //     return 'other'
    //   default:
    //     return ''
    // }
    return ''
  }
  return (
    <div
      style={{
        margin: '10px',
        border: '1px solid #2c65ac',
        borderRadius: '5px',
      }}>
      <div>
        <h3
          style={{
            display: 'flex',
            backgroundColor: '#2c65ac',
            height: '40px',
            width: '100%',
            color: 'white',
            padding: '0 10px',
            alignItems: 'center',
          }}>
          <span style={{ marginRight: '3px' }}>
            Chi tiết thông tin chấm công ngày:{' '}
          </span>
          {date_format.DATE_FORMAT(date)}
        </h3>
        <div style={{ display: 'flex', margin: '5px' }}>
          <div key={'timekeeping-s'} style={{ width: '100%' }}>
            <dt>Vào làm lúc:</dt>
            <dd style={{ margin: '0 5px', borderBottom: '1px solid gray' }}>
              <EmptyText>Không có dữ liệu</EmptyText>
            </dd>
            <dt>Tan làm lúc:</dt>
            <dd style={{ margin: '0 5px', borderBottom: '1px solid gray' }}>
              <EmptyText>Không có dữ liệu</EmptyText>
            </dd>
            <dt>Trạng thái đi làm:</dt>
            <dd style={{ margin: '0 5px', borderBottom: '1px solid gray' }}>
              <EmptyText>Không có dữ liệu</EmptyText>
            </dd>
            <dt>Trạng thái tan làm:</dt>
            <dd style={{ margin: '0 5px', borderBottom: '1px solid gray' }}>
              <EmptyText>Không có dữ liệu</EmptyText>
            </dd>
            <dt>Kết quả chấm công:</dt>
            <dd style={{ margin: '0 5px', borderBottom: '1px solid gray' }}>
              <EmptyText>Không có dữ liệu</EmptyText>
            </dd>
            <dt>Danh sách hành chính liên quan:</dt>
            <dd style={{ margin: '0 5px', borderBottom: '1px solid gray' }}>
              {proposalsByDay.length >= 1 ? (
                proposalsByDay?.map(item => (
                  <div key={item.proposalCode}>
                    {link?.map((link, index) => (
                      <Tooltip
                        key={index}
                        title={
                          item.status === 'APPROVAL'
                            ? 'Đơn đã duyệt'
                            : 'Đơn chưa được duyệt'
                        }>
                        <a
                          href={`${link.url}/proposal/other/view/${item.proposalCode}`}
                          target="_blank"
                          color={
                            item.status === 'APPROVAL' ? '#2C65AC' : 'red'
                          }>
                          <span style={{ marginRight: '3px' }}>
                            {item.title}
                          </span>
                        </a>
                      </Tooltip>
                    ))}
                  </div>
                ))
              ) : (
                <EmptyText>Không có dữ liệu</EmptyText>
              )}
            </dd>
            <button style={{ margin: '5px' }}>
              {link?.map(item => (
                <a href={`${item.url}/proposal`} target="_blank" color="fff">
                  Tạo hành chính <DoubleRightOutlined />
                </a>
              ))}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

TimekeepingInfoDayOfAbsence.propTypes = {}

export default memo(
  inject('loadingAnimationStore')(observer(TimekeepingInfoDayOfAbsence))
)
