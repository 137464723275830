import { CloseCircleOutlined, FilterOutlined } from '@ant-design/icons'
import {
  Checkbox,
  Col,
  DatePicker,
  Empty,
  Input,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  message,
} from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { useLocation } from 'react-router-dom'
import { EmptyText } from '../../components/Common/CellText'
import { TableWrapper } from '../../components/Common/Table'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import {
  DATE_FORMAT_DEFAULT,
  ISO_DATE_FORMAT,
  PO,
  STATUS_DEFAULT,
} from '../../constants'
import date_format from '../../date_format'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import EditSignAccountModal from '../SignAccountPage/EditSignAccountModal/EditSignAccountModal'
import {
  FilterButton,
  SearchBar,
} from '../UserAccountManagementPage/UserAcountManagementPageStyled'
import AdvanceFilterBlock from './AdvanceFilterBlock'
import ExportTimeKeepingToExcel from './ExportTimeKeepingToExcel'
import './index.css'
const { Option } = Select
const TimekeepingPage = props => {
  const location = useLocation()
  const [queryStringParsed, setQueryStringParsed] = useState(
    queryString.parse(location.search, {
      arrayFormat: 'index',
    })
  )

  const { isAdmin, keyword } = queryStringParsed
  const {
    loadingAnimationStore,
    timekeepingStore,
    history,
    commandStore,
    aclStore,
    authenticationStore,
    departmentHrStore,
  } = props
  const [employeePageList, setEmployeePageList] = useState([])
  const [employeeList, setEmployeeList] = useState([])
  const { Search } = Input
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false)
  const { commandList } = commandStore
  const [link, setLink] = useState([])
  const [hour, setHour] = useState(null)
  const [minute, setMinute] = useState(null)

  const [departmentList, setDepartmentList] = useState([])
  const [departmentCodeList, setDepartmentCodeList] = useState([])

  const isChiefOfDepartment = aclStore.aclActionsByUser.some(
    aclAction => aclAction.code === 'view_TRUONG_BAN' && aclAction.status
  )

  const isHr = aclStore.aclActionsByUser.some(
    aclAction => aclAction.code === 'view_CHAM_CONG_QLNS' && aclAction.status
  )

  const isChiefOfDepartmentButNotAdmin =
    isChiefOfDepartment &&
    !authenticationStore.isAdmin &&
    !authenticationStore.isSuperAdmin

  useEffect(() => {
    if (!authenticationStore.currentUser) {
      return
    }

    const currentUserDepartmentCode =
      authenticationStore?.currentUser?.departmentHr?.code
    if (
      isChiefOfDepartmentButNotAdmin &&
      departmentCodeList !== currentUserDepartmentCode
    ) {
      let deIds = []
      deIds.push(currentUserDepartmentCode)
      console.log(deIds)
      setDepartmentCodeList(deIds)
      const queryStringParsedNew = {
        ...queryStringParsed,
        ...{ deId: deIds },
      }

      setQueryStringParsed(queryStringParsedNew)
      history.push(
        `/management/timekeeping?${queryString.stringify(queryStringParsedNew)}`
      )
    }
  }, [authenticationStore, isChiefOfDepartmentButNotAdmin])

  useEffect(() => {
    commandStore.getCommandList()
    departmentHrStore.getAllDepartmentHrList().then(({ data }) => {
      setDepartmentList(data)
    })
  }, [])

  useEffect(() => {
    const menuCodes = [PO]
    setLink(commandList.filter(item => menuCodes.includes(item.code)))
  }, [commandList])

  const getEmployee = async () => {
    loadingAnimationStore.showSpinner(true)

    let deIdArray =
      queryStringParsed.deId !== null && queryStringParsed.deId !== undefined
        ? Array.isArray(queryStringParsed.deId)
          ? queryStringParsed.deId
          : [queryStringParsed.deId]
        : []
    // let existProposal = queryStringParsed.existProposal === 'false';
    let params = {
      page: queryStringParsed.page,
      size: queryStringParsed.size,
      absent: queryStringParsed.absent || null,
      keyword: queryStringParsed.keyword || null,
      isIgnore: queryStringParsed.isIgnore || null,
      isActive: queryStringParsed.isActive || null,
      late: queryStringParsed.late || null,
      early: queryStringParsed.early || null,
      fromDate:
        moment(queryStringParsed.fromDate).format('YYYY-MM-DD') ||
        moment(new Date()).startOf('month').format('YYYY-MM-DD'),
      toDate:
        moment(queryStringParsed.toDate).format('YYYY-MM-DD') ||
        moment(new Date()).endOf('month').format('YYYY-MM-DD'),
      // fromDate: fromDate,
      // toDate: toDate,
      deName: queryStringParsed.deName || null,
      deId: deIdArray ? deIdArray : null,
      checked: queryStringParsed.checked,
      existProposal: queryStringParsed.existProposal || null,
    }

    timekeepingStore
      .getEmployee(params)
      .then(response => {
        setEmployeePageList(response.data)
        setEmployeeList(toJS(response.data.content))
      })
      .catch(error => message.error(error.vi))
      .finally(() => loadingAnimationStore.showSpinner(false))
  }
  const url = link?.map(item => item.url)

  useEffect(() => {
    getEmployee()
  }, [queryStringParsed])
  useEffect(() => {
    if (!queryStringParsed.fromDate || !queryStringParsed.toDate) {
      const updatedQueryString = {
        ...queryStringParsed,
        page: 0,
        size: 10,
        fromDate: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
        toDate: moment(new Date()).endOf('month').format('YYYY-MM-DD'),
        checked: false,
      }

      setQueryStringParsed(updatedQueryString)

      const updatedQueryStringStringified = queryString.stringify(
        updatedQueryString
      )

      history.push(`/management/timekeeping?${updatedQueryStringStringified}`)
    }
  }, [])
  useEffect(() => {
    history.push(
      `/management/timekeeping?${queryString.stringify(queryStringParsed)}`
    )
  }, [queryStringParsed])

  const randomNumberInRange = max => {
    return Math.floor(Math.random() * (max + 1))
  }

  const sendUpdateIoInfo = async (emCode, time) => {
    let date = time.format('YYYY-MM-DD HH:mm:ss')
    timekeepingStore
      .updateIoInfo(emCode, date)
      .then(() => {
        message.success(date)
      })
      .catch(error => message.error(error.vi))
  }

  const updateIoInfo = (record, type) => {
    const randomTime = randomNumberInRange(20)
    const randomSec = randomNumberInRange(59)

    if (type === 'm') {
      const time = moment(record?.timekeepingDay)
        .add(7, 'hours')
        .add(35 + randomTime, 'minutes')
        .add(randomSec, 'seconds')
      sendUpdateIoInfo(record.emCode, time)
    }
    if (type === 'a') {
      const time = moment(record?.timekeepingDay)
        .startOf('day')
        .add(17, 'hours')
        .add(randomTime, 'minutes')
        .add(randomSec, 'seconds')
      sendUpdateIoInfo(record.emCode, time)
    }
    if (type === 'all') {
      const time = moment(record?.timekeepingDay)
        .add(7, 'hours')
        .add(35 + randomTime, 'minutes')
        .add(randomSec, 'seconds')
      sendUpdateIoInfo(record.emCode, time)
      const timeEnd = moment(record?.timekeepingDay)
        .add(17, 'hours')
        .add(randomTime, 'minutes')
        .add(randomSec, 'seconds')
      sendUpdateIoInfo(record.emCode, timeEnd)
    }
    if (type === 'cus') {
      if (!hour || !minute) {
        alert('Not init')
      } else {
        const time = moment(record?.timekeepingDay)
          .add(hour, 'hours')
          .add(minute, 'minutes')
          .add(randomSec, 'seconds')
        sendUpdateIoInfo(record.emCode, time)
      }
    }
    if (type === 'do') {
      const username = record.username ?? null

      let fromDate = null
      let toDate = null

      fromDate = moment(record?.timekeepingDay).format(ISO_DATE_FORMAT)
      toDate = moment(record?.timekeepingDay).format(ISO_DATE_FORMAT)
      timekeepingStore
        .syncTimekeeping(fromDate, toDate, username)
        .then(() => {
          message.info('Chấm công thành công!')
        })
        .catch(() => message.info('Đồng bộ thất bại!'))
        .finally(() => {})
    }
  }

  /**
   * @type {import('antd').TableColumnProps[]}
   */
  const columns = [
    {
      width: 150,
      title: 'Tài khoản',
      key: 'username',
      fixed: 'left',
      render: record => (
        <div>
          {record && record.username === null ? (
            <EmptyText>Không có.</EmptyText>
          ) : (
            record.username
          )}
        </div>
      ),
    },
    {
      title: 'Bộ phận',
      key: 'deName',
      render: record => (
        <div>
          {record && record.deName === null ? (
            <EmptyText>Không có.</EmptyText>
          ) : (
            record.deName
          )}
        </div>
      ),
    },
    {
      title: 'Họ tên',
      key: 'emName',
      render: record => (
        <div>
          {record && record.emName === null ? (
            <EmptyText>Không có.</EmptyText>
          ) : (
            <span style={{ textTransform: 'capitalize' }}>{record.emName}</span>
          )}
        </div>
      ),
    },
    {
      width: 100,
      align: 'center',
      key: 'timekeepingDay',
      title: <div style={{ textAlign: 'center' }}>Ngày</div>,
      render: record => (
        <div style={{ textAlign: 'center' }}>
          {date_format.DATE_FORMAT(record.timekeepingDay)}
        </div>
      ),
    },
    {
      key: 'ioTimeIn',
      title: 'Vào-sáng',
      render: record => (
        <div>
          {(moment(record?.timekeepingDay).isoWeekday() === 6 &&
            record.ioTimeIn === null) ||
          (moment(record?.timekeepingDay).isoWeekday() === 6 &&
            record.ioTimeOut === null) ||
          (moment(record?.timekeepingDay).isoWeekday() === 7 &&
            record.ioTimeOut === null) ||
          (moment(record?.timekeepingDay).isoWeekday() === 7 &&
            record.ioTimeIn === null) ? (
            <span>-----------------</span>
          ) : (
            <div>
              {record && record.absent === true ? (
                <span>-----------------</span>
              ) : record && record.ioTimeIn ? (
                date_format.HH_mm(record.ioTimeIn)
              ) : (
                <EmptyText>Không có.</EmptyText>
              )}
            </div>
          )}
        </div>
      ),
    },

    {
      title: 'Ra-chiều',
      key: 'ioTimeOut',
      render: record => (
        <div>
          {(moment(record?.timekeepingDay).isoWeekday() === 6 &&
            record.ioTimeIn === null) ||
          (moment(record?.timekeepingDay).isoWeekday() === 6 &&
            record.ioTimeOut === null) ||
          (moment(record?.timekeepingDay).isoWeekday() === 7 &&
            record.ioTimeOut === null) ||
          (moment(record?.timekeepingDay).isoWeekday() === 7 &&
            record.ioTimeIn === null) ? (
            <span>-----------------</span>
          ) : (
            <div>
              {record && record.absent === true ? (
                <span>-----------------</span>
              ) : record && record.ioTimeOut ? (
                date_format.HH_mm(record.ioTimeOut)
              ) : (
                <EmptyText>Không có.</EmptyText>
              )}
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Đi muộn',
      key: 'late',
      width: 100,
      render: record => (
        <div>
          {(moment(record?.timekeepingDay).isoWeekday() === 6 &&
            record.ioTimeIn === null) ||
          (moment(record?.timekeepingDay).isoWeekday() === 6 &&
            record.ioTimeOut === null) ||
          (moment(record?.timekeepingDay).isoWeekday() === 7 &&
            record.ioTimeOut === null) ||
          (moment(record?.timekeepingDay).isoWeekday() === 7 &&
            record.ioTimeIn === null) ? (
            <span>-----------------</span>
          ) : (
            <>
              {record && record.absent === true ? (
                <span>-----------------</span>
              ) : record && record.late == true ? (
                <span>Đã đi muộn</span>
              ) : (
                <EmptyText>Đúng giờ</EmptyText>
              )}
            </>
          )}
        </div>
      ),
    },
    {
      title: 'Thứ',
      key: 'day',
      width: 100,
      render: record => (
        <div>
          {moment(record?.timekeepingDay).isoWeekday() === 7
            ? 'CN'
            : 'T' + (moment(record?.timekeepingDay).isoWeekday() + 1)}
        </div>
      ),
    },
    {
      title: 'Về sớm',
      key: 'early',
      width: 100,
      render: record => (
        <div>
          {(moment(record?.timekeepingDay).isoWeekday() === 6 &&
            record.ioTimeIn === null) ||
          (moment(record?.timekeepingDay).isoWeekday() === 6 &&
            record.ioTimeOut === null) ||
          (moment(record?.timekeepingDay).isoWeekday() === 7 &&
            record.ioTimeOut === null) ||
          (moment(record?.timekeepingDay).isoWeekday() === 7 &&
            record.ioTimeIn === null) ? (
            <span>-----------------</span>
          ) : (
            <>
              {record && record.absent === true ? (
                <span>-----------------</span>
              ) : record && record.early == true ? (
                <span>Đã về sớm</span>
              ) : (
                <EmptyText>Đúng giờ</EmptyText>
              )}
            </>
          )}
        </div>
      ),
    },
    // {
    //   title: 'Vắng mặt',
    //   key: 'absent',
    //   render: record => (
    //     <div>
    //       <div>
    //         {(moment(record?.timekeepingDay).isoWeekday() === 6 &&
    //           record.ioTimeIn === null) ||
    //         (moment(record?.timekeepingDay).isoWeekday() === 6 &&
    //           record.ioTimeOut === null) ||
    //         (moment(record?.timekeepingDay).isoWeekday() === 7 &&
    //           record.ioTimeOut === null) ||
    //         (moment(record?.timekeepingDay).isoWeekday() === 7 &&
    //           record.ioTimeIn === null) ? (
    //           <span>-----------------</span>
    //         ) : (
    //           <>
    //             {record.absent === true ? (
    //               <span>Đã vắng mặt</span>
    //             ) : (
    //               <EmptyText>Có mặt</EmptyText>
    //             )}
    //           </>
    //         )}
    //       </div>
    //     </div>
    //   ),
    // },
    {
      title: 'Kết quả',
      key: 'proposalList',
      width: 100,
      fixed: 'right',
      render: record => <div>{record.summaryText}</div>,
    },
    {
      title: 'Liên kết hành chính',
      key: 'proposalList',
      width: 400,
      fixed: 'right',
      render: record => (
        <div
          style={{
            display: 'flex',
          }}>
          {isAdmin && (
            <div>
              <FilterButton onClick={() => updateIoInfo(record, 'm')}>
                m
              </FilterButton>
              <FilterButton onClick={() => updateIoInfo(record, 'a')}>
                a
              </FilterButton>
              <FilterButton onClick={() => updateIoInfo(record, 'all')}>
                all
              </FilterButton>
              <FilterButton onClick={() => updateIoInfo(record, 'cus')}>
                cus
              </FilterButton>
              <FilterButton onClick={() => updateIoInfo(record, 'do')}>
                do
              </FilterButton>
            </div>
          )}
          {record && record?.proposalList?.length >= 1 ? (
            <div
              style={{
                width: 'auto',
                overflow: 'overlay',
              }}>
              {record.proposalList[0] !== null ? (
                record.proposalList?.map(item => (
                  <div key={item.proposalCode}>
                    <a
                      href={`${url}/proposal/other/view/${item.proposalCode}`}
                      target="_blank"
                      style={{
                        color:
                          item.status == STATUS_DEFAULT.APPROVAL ? '0' : 'red',
                      }}>
                      <span style={{ marginRight: '3px' }}>{item.title}</span>
                    </a>
                  </div>
                ))
              ) : (
                <EmptyText>Không có.</EmptyText>
              )}
            </div>
          ) : (
            <EmptyText>Không có.</EmptyText>
          )}
        </div>
      ),
    },
  ]

  const onChangePagination = async e => {
    setQueryStringParsed({
      ...queryStringParsed,
      ...{ page: e - 1 },
      ...{ size: 10 },
    })
    history.push(
      `/management/timekeeping?${queryString.stringify(queryStringParsed)}`
    )
  }

  const onChangeExport = async () => {
    setQueryStringParsed({
      ...queryStringParsed,
      ...{ page: 0 },
      ...{ size: 1000000 },
    })
    history.push(
      `/management/timekeeping?${queryString.stringify(queryStringParsed)}`
    )
  }

  const onClose = async () => {
    setQueryStringParsed({
      ...queryStringParsed,
      ...{ page: 0 },
      ...{ size: 10 },
    })
    history.push(
      `/management/timekeeping?${queryString.stringify(queryStringParsed)}`
    )
  }

  const handleCloseModal = () => {
    setIsVisibleModal(false)
  }

  const handleCloseAdvanceFilter = async () => {
    const updatedQueryStringParsed = {
      ...queryStringParsed,
      page: 0,
      size: 10,
      late: null,
      early: null,
      absent: null,
      keyword: keyword ? keyword : null,
      deName: null,
      existProposal: null,
      deId: null,
    }

    setQueryStringParsed(updatedQueryStringParsed)

    history.push(
      `/management/timekeeping?${queryString.stringify(
        updatedQueryStringParsed
      )}`
    )
  }

  const onQuickSearch = async title => {
    const queryStringParsedNew = {
      ...queryStringParsed,
      ...{ keyword: title, page: 0 },
    }

    setQueryStringParsed(queryStringParsedNew)
    history.push(
      `/management/timekeeping?${queryString.stringify(queryStringParsedNew)}`
    )
  }

  const handleChangeFilterImportant = async value => {
    // setShowAdvanceFilter(false)
    setQueryStringParsed({
      ...queryStringParsed,
      ...{ checked: value.target.checked },
      page: 0,
    })
    history.push(
      `/management/timekeeping?${queryString.stringify(queryStringParsed)}`
    )
  }

  const handleSearchByProposal = async value => {
    setQueryStringParsed({
      ...queryStringParsed,
      ...{ existProposal: value?.target?.checked },
      page: 0,
    })
  }

  const handleSearchByDe = async value => {
    setDepartmentCodeList(value)
    setQueryStringParsed(prevState => ({
      ...prevState,
      deId: value,
      page: 0,
      size: 10,
    }))
  }

  const { RangePicker } = DatePicker

  const onChange = value => {
    setQueryStringParsed({
      ...queryStringParsed,
      ...{
        fromDate: value ? moment(value[0]._d).format(ISO_DATE_FORMAT) : null,
      },
      ...{ toDate: value ? moment(value[1]._d).format(ISO_DATE_FORMAT) : null },
    })
    history.push(
      `/management/timekeeping?${queryString.stringify(queryStringParsed)}`
    )
  }

  return (
    <DashboardLayout key="thead">
      <Helmet>
        <title>Chấm công</title>
      </Helmet>

      <PageTitle location={props.location} title={'Chấm công'} hiddenGoBack>
        <div>
          <RangePicker
            style={{ marginRight: 5 }}
            onChange={onChange}
            value={
              queryStringParsed.fromDate && queryStringParsed.toDate
                ? [
                    moment(queryStringParsed.fromDate),
                    moment(queryStringParsed.toDate),
                  ]
                : null
            }
            format={[DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT]}
          />
          <ExportTimeKeepingToExcel
            employeeIoList={employeeList}
            onChangeExport={onChangeExport}
            onCloser={onClose}
            params={queryStringParsed}
            departmentList={departmentList}
          />
        </div>
      </PageTitle>

      <ContentBlockWrapper>
        <TableWrapper>
          <div>
            <Row>
              <Col span={18}>
                <div
                  style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                  <SearchBar>
                    <Search
                      allowClear
                      onSearch={onQuickSearch}
                      defaultValue={keyword}
                      // value={keyword}
                      placeholder={'Tìm kiếm theo tài khoản và tên người dùng'}
                    />
                  </SearchBar>
                </div>
                <div
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    paddingLeft: '20px',
                  }}>
                  <div
                    style={{
                      display: 'inline-block',
                      verticalAlign: 'middle',
                    }}>
                    <Checkbox
                      onChange={handleChangeFilterImportant}
                      defaultChecked={queryStringParsed.is_important}
                      // checked={queryParams.checked}
                    >
                      <span
                        style={{
                          color: '#000',
                          fontWeight: 'bold',
                          transform: 'translateY(-1px)',
                          display: 'inline-block',
                        }}>
                        Lọc ngày sai phạm
                      </span>
                    </Checkbox>
                    <Checkbox
                      name="existProposal"
                      onChange={handleSearchByProposal}>
                      <span
                        style={{
                          color: '#000',
                          fontWeight: 'bold',
                          transform: 'translateY(-1px)',
                          display: 'inline-block',
                        }}>
                        Lọc ngày có hành chính
                      </span>
                    </Checkbox>
                    <Select
                      mode="multiple"
                      disabled={isChiefOfDepartmentButNotAdmin && !isHr}
                      style={{ width: '200px', display: 'inline-block' }}
                      allowClear
                      onChange={handleSearchByDe}
                      value={
                        departmentCodeList && departmentCodeList.length > 0
                          ? departmentCodeList
                          : []
                      }
                      filterOption={(inputValue, option) => {
                        return option.children
                          .toLowerCase()
                          .includes(inputValue.toLowerCase().trim())
                      }}
                      placeholder={'-- Chọn bộ phận --'}>
                      {departmentList
                        .slice()
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((department, index) => {
                          return (
                            <Option key={index} value={department.code}>
                              {department.name}
                            </Option>
                          )
                        })}
                    </Select>
                  </div>
                </div>
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                {isAdmin && (
                  <div>
                    <Input
                      defaultValue={8}
                      onChange={event => setHour(event?.target?.value)}
                      style={{ display: 'inline-block', width: 50 }}
                    />
                    <Input
                      defaultValue={0}
                      onChange={event => setMinute(event?.target?.value)}
                      style={{ display: 'inline-block', width: 50 }}
                    />
                  </div>
                )}
                <FilterButton
                  type={showAdvanceFilter ? 'primary' : 'default'}
                  ghost={showAdvanceFilter}
                  onClick={() => setShowAdvanceFilter(!showAdvanceFilter)}
                  style={{ marginBottom: showAdvanceFilter ? 5 : 0 }}>
                  {showAdvanceFilter ? (
                    <Space onClick={handleCloseAdvanceFilter}>
                      <CloseCircleOutlined />
                      Tắt tìm kiếm nâng cao
                    </Space>
                  ) : (
                    <Space>
                      <FilterOutlined />
                      Tìm kiếm nâng cao
                    </Space>
                  )}
                </FilterButton>
              </Col>
            </Row>
            {showAdvanceFilter && (
              <AdvanceFilterBlock
                visible={showAdvanceFilter}
                setQueryStringParsed={setQueryStringParsed}
                queryStringParsed={queryStringParsed}
                departmentList={departmentList}
              />
            )}
          </div>
          <Table
            className="custom-table-timekeeping"
            bordered
            rowKey={record => record.id}
            dataSource={employeeList}
            scroll={{
              x: 1500,
            }}
            columns={columns}
            pagination={false}
            sticky
            scroll={{ x: 1300, y: 'calc(100vh - 250px)' }}
            locale={{
              emptyText: (
                <Empty
                  style={employeeList == null ? { opacity: 0 } : {}}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={'Không có dữ liệu'}
                />
              ),
            }}
          />
        </TableWrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '25px 0',
          }}>
          <Pagination
            onChange={e => onChangePagination(e)}
            hideOnSinglePage={true}
            total={employeePageList.totalElements}
            pageSize={10}
            current={queryStringParsed.page + 1}
            showSizeChanger={false}
            showLessItems
          />
        </div>
        <EditSignAccountModal
          isVisibleModal={isVisibleModal}
          handleCloseModal={handleCloseModal}
        />
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

TimekeepingPage.propTypes = {}

export default inject(
  'loadingAnimationStore',
  'timekeepingStore',
  'commandStore',
  'aclStore',
  'authenticationStore',
  'departmentHrStore'
)(observer(TimekeepingPage))
