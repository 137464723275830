import React, { memo, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'

import { CheckCircleOutlined } from '@ant-design/icons'
import { Calendar, Carousel, Col, Row, Spin, message } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import EmptyContent from '../../components/EmptyContent/EmptyContent'
import PageTitle from '../../components/PageTitle'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent/SpinnerInlineComponent'
import { PO, PROPOSAL_TYPE } from '../../constants'
import date_format from '../../date_format'
import TimekeepingInfo from './TimekeepingInfo'
import TimekeepingInfoDayOfAbsence from './TimekeepingInfoDayOfAbsence'
import './customiez.css'

export const submissionList = [
  {
    id: 'BUSINESS_TRIP',
    name: PROPOSAL_TYPE.BUSINESS_TRIP.label,
  },
  { id: 'LEAVE', name: PROPOSAL_TYPE.LEAVE.label },
  {
    id: 'LEAVE_ABNORMAL',
    name: PROPOSAL_TYPE.LEAVE_ABNORMAL.label,
  },
  {
    id: 'LEAVE_NO_SALARY',
    name: PROPOSAL_TYPE.LEAVE_NO_SALARY.label,
  },
  {
    id: 'LEAVE_ANNUAL',
    name: PROPOSAL_TYPE.LEAVE_ANNUAL.label,
  },
  { id: 'SICK', name: PROPOSAL_TYPE.SICK.label },
  { id: 'SICK_OTHER', name: PROPOSAL_TYPE.SICK_OTHER.label },
  {
    id: 'OVERTIME_NORMAL',
    name: PROPOSAL_TYPE.OVERTIME_NORMAL.label,
  },
  {
    id: 'OVERTIME_DAYOFF',
    name: PROPOSAL_TYPE.OVERTIME_DAYOFF.label,
  },
  {
    id: 'OVERTIME_HOLIDAY',
    name: PROPOSAL_TYPE.OVERTIME_HOLIDAY.label,
  },
  {
    id: 'RETIMEKEEPING',
    name: PROPOSAL_TYPE.RETIMEKEEPING.label,
  },
]

const TimekeepingPage = props => {
  const {
    loadingAnimationStore,
    commandStore,
    isLoading,
    history,
    timekeepingInfoStore,
    authenticationStore,
  } = props
  const [openIo, setOpenIo] = useState(false)
  const [openAbsence, setOpenAbsence] = useState(false)
  const [loading, setLoading] = useState(false)
  const [timekeepingList, setTimekeeping] = useState([])
  const [timekeepingForYearList, setTimekeepingYear] = useState([])
  const [timekeepingIdList, setTimekeepingId] = useState([])
  const [proposalsByDay, setProposalsByDay] = useState([])
  const [buttonClicked, setButtonClicked] = useState(false)
  const [listProposalType, setListProposalType] = useState([])
  const [listProposalTypeByYear, setListProposalTypeByYear] = useState([])
  const [montlyWorkingHourReport, setMontlyWorkingHourReport] = useState({
    workingHour: 0,
    leaveHour: 0,
    overtimeHour: 0,
  })
  const [
    montlyWorkingHourReportByYear,
    setMontlyWorkingHourReportByYear,
  ] = useState({
    workingHour: 0,
    leaveHour: 0,
    overtimeHour: 0,
  })
  const { timekeeping_day_query } = useParams()
  const currentURL = window.location.href
  const url = new URL(currentURL)
  let timekeeping_day = url.searchParams.get('timekeeping_day')
  const dateTime = []
  const { commandList } = commandStore
  const [link, setLink] = useState()

  useEffect(() => {
    commandStore.getCommandList()
  }, [])
  useEffect(() => {
    // Lấy tất cả các item trong proposalList của timekeepingList(list data trong 1 tháng)
    const proposalLists = timekeepingList.flatMap(item => item.proposalList)

    // Lấy proposalType của cái list proposalLists ra
    const proposalTypes = proposalLists.map(item => item.proposalType)

    // Đếm xem cái list proposalTypes có cái type nào trùng, thêm total
    const groupedProposalTypes = proposalTypes.reduce((result, id) => {
      if (!result[id]) {
        result[id] = { total: 0, id }
      }
      result[id].total += 1
      return result
    }, {})

    // Lọc groupedProposalTypes với submissionList, nếu id của submissionList giống với id của groupedProposalTypes => lấy ra cái item giống nhau ở groupedProposalTypes, kèm theo cái name ở bên submissionList
    const result = Object.values(groupedProposalTypes)
      .filter(itemA => {
        return submissionList.some(itemB => itemB.id === itemA.id)
      })
      .map(itemA => {
        const matchingItemB = submissionList.find(
          itemB => itemB.id === itemA.id
        )
        return {
          id: itemA.id,
          name: matchingItemB.name,
          total: itemA.total,
        }
      })

    setListProposalType(result)
  }, [timekeepingList])
  useEffect(() => {
    // Lấy tất cả các item trong proposalList của timekeepingForYearList(list data trong năm)
    const proposalLists = timekeepingForYearList.flatMap(
      item => item.proposalList
    )

    // Lấy proposalType của cái list proposalLists ra
    const proposalTypes = proposalLists.map(item => item.proposalType)

    // Đếm xem cái list proposalTypes có cái type nào trùng, thêm total
    const groupedProposalTypes = proposalTypes.reduce((result, id) => {
      if (!result[id]) {
        result[id] = { total: 0, id }
      }
      result[id].total += 1
      return result
    }, {})

    // Lọc groupedProposalTypes với submissionList, nếu id của submissionList giống với id của groupedProposalTypes => lấy ra cái item giống nhau ở groupedProposalTypes, kèm theo cái name ở bên submissionList
    const result = Object.values(groupedProposalTypes)
      .filter(itemA => {
        return submissionList.some(itemB => itemB.id === itemA.id)
      })
      .map(itemA => {
        const matchingItemB = submissionList.find(
          itemB => itemB.id === itemA.id
        )
        return {
          id: itemA.id,
          name: matchingItemB.name,
          total: itemA.total,
        }
      })

    setListProposalTypeByYear(result)
  }, [timekeepingForYearList])
  const [queryParamTimekeeping, setQueryParamTimekeeping] = useState({
    page: 0,
    size: 31,
    fromDate: timekeeping_day
      ? moment(timekeeping_day, 'DD-MM-YYYY')
          .startOf('month')
          .format('YYYY-MM-DD')
      : moment(new Date()).startOf('month').format('YYYY-MM-DD'),
    toDate: timekeeping_day
      ? moment(timekeeping_day, 'DD-MM-YYYY')
          .endOf('month')
          .format('YYYY-MM-DD')
      : moment(new Date()).endOf('month').format('YYYY-MM-DD'),
    id: null,
  })
  const [
    queryParamTimekeepingByYear,
    setQueryParamTimekeepingByYear,
  ] = useState({
    page: 0,
    size: 365,
    fromDate: timekeeping_day
      ? moment(timekeeping_day, 'DD-MM-YYYY')
          .startOf('month')
          .format('YYYY-MM-DD')
      : moment(new Date()).startOf('month').format('YYYY-MM-DD'),
    toDate: timekeeping_day
      ? moment(timekeeping_day, 'DD-MM-YYYY')
          .endOf('month')
          .format('YYYY-MM-DD')
      : moment(new Date()).endOf('month').format('YYYY-MM-DD'),
    id: null,
  })
  const [queryParamTimekeepingId, setQueryParamTimekeepingId] = useState({
    page: 0,
    size: 1,
    fromDate: null,
    toDate: null,
    id: null,
  })
  const GetTimekeepingById = async queryParamTimekeepingId => {
    await timekeepingInfoStore
      .getTimekeepingForUserNew(queryParamTimekeepingId)
      .then(response => {
        setTimekeepingId(toJS(response.data.content))
      })
  }
  const GetTimekeepingForUser = async queryParamTimekeeping => {
    await timekeepingInfoStore
      .getTimekeepingForUserNew(queryParamTimekeeping)
      .then(response => {
        setTimekeeping(toJS(response.data.content))
      })
  }
  const GetTimekeepingForYear = async queryParamTimekeeping => {
    await timekeepingInfoStore
      .getTimekeepingForUserNew(queryParamTimekeeping)
      .then(response => {
        setTimekeepingYear(toJS(response.data.content))
      })
  }

  useEffect(() => {
    GetTimekeepingForUser(queryParamTimekeeping)
    GetTimekeepingForYear(queryParamTimekeepingByYear)
    if (authenticationStore.currentUser) {
      timekeepingInfoStore
        .getWorkingTimeReport({
          username: authenticationStore.currentUser.username,
          fromDate: queryParamTimekeeping.fromDate,
          toDate: queryParamTimekeeping.toDate,
        })
        .then(({ data }) => setMontlyWorkingHourReport(data))
      timekeepingInfoStore
        .getWorkingTimeReport({
          username: authenticationStore.currentUser.username,
          fromDate: queryParamTimekeepingByYear.fromDate,
          toDate: queryParamTimekeepingByYear.toDate,
        })
        .then(({ data }) => setMontlyWorkingHourReportByYear(data))
    }
  }, [queryParamTimekeeping, authenticationStore.currentUser])

  const total_leave =
    authenticationStore.currentUser?.other?.take_leave?.split(':')[1]?.trim() ||
    12
  useEffect(() => {
    const fetchData = async () => {
      if (buttonClicked) {
        setLoading(true)
        try {
          await GetTimekeepingById(queryParamTimekeepingId)
        } catch (error) {
          message.error(error.vi)
        } finally {
          setLoading(false)
        }
      }
    }

    fetchData()
  }, [buttonClicked, queryParamTimekeepingId])
  const [dates, setDate] = useState(
    timekeeping_day ? moment(timekeeping_day, 'DD-MM-YYYY') : moment(new Date())
  )

  const onOpenIo = async id => {
    setButtonClicked(true)
    setOpenIo(true)
    setOpenAbsence(false)
    setQueryParamTimekeepingId({
      ...queryParamTimekeepingId,
      fromDate: moment(dates).startOf('month').format('YYYY-MM-DD'),
      toDate: moment(dates).endOf('month').format('YYYY-MM-DD'),
      id: id,
    })

    const menuCodes = [PO]
    setLink(commandList.filter(item => menuCodes.includes(item.code)))
  }
  const onOpenIoNull = async value => {
    setOpenIo(false)
    setOpenAbsence(true)
    setTimekeepingId([])
    try {
      if (value) {
        setLoading(true)
        await timekeepingInfoStore.getTimekeepingById()
        await timekeepingInfoStore
          .getProposalsByDay(moment(value._d).format('YYYY-MM-DD'))
          .then(response => {
            setProposalsByDay(response.data)
          })
      }
    } catch (error) {
      message.error(error.vi)
    } finally {
      setLoading(false)
    }
    const menuCodes = [PO]
    setLink(commandList.filter(item => menuCodes.includes(item.code)))
  }

  const onChangeDay = async date => {
    setQueryParamTimekeeping({
      ...queryParamTimekeeping,
      fromDate: moment(date).startOf('month').format('YYYY-MM-DD'),
      toDate: moment(date).endOf('month').format('YYYY-MM-DD'),
      id: null,
      size: 31,
    })
    try {
      setLoading(true)
      loadingAnimationStore.setShowSpinInline(true)
      await GetTimekeepingById(queryParamTimekeeping)
    } catch (error) {
      message.error(error.vi)
    } finally {
      setLoading(false)
      loadingAnimationStore.setShowSpinInline(false)
    }
  }
  // const [month, setMonth] = useState('')
  // const [month2, setMonth2] = useState('')

  // useEffect(() => {
  //   setMonth(moment().format('MM')) // Thiết lập giá trị ban đầu cho 'month'
  // }, [])

  useEffect(() => {
    const currentURL = window.location.href
    const url = new URL(currentURL)
    let timekeeping_day = url.searchParams.get('timekeeping_day')
    if (timekeepingList.length > 0) {
      if (timekeeping_day !== null) {
        let item = timekeepingList.find(
          item =>
            timekeeping_day === moment(item.timekeepingDay).format('DD-MM-YYYY')
        )
        let isWorkingDay = item?.summary !== '{}'
        let isProposal = item?.proposalList?.length > 0
        if (!isWorkingDay && !isProposal) {
          item = undefined
        }
        if (item !== undefined) {
          onOpenIo(item.id).then(r => {})
        } else {
          onOpenIoNull(timekeeping_day_query).then(r => {})
        }
      }
    }
  }, [timekeepingList, timekeeping_day, dates])

  useEffect(() => {
    const currentURL = window.location.href
    const url = new URL(currentURL)
    let timekeeping_day = url.searchParams.get('timekeeping_day')
    if (timekeepingList.length > 0 && openAbsence === true) {
      if (timekeeping_day !== null) {
        timekeepingInfoStore.getTimekeepingById()
        timekeepingInfoStore
          .getProposalsByDay(
            moment(timekeeping_day, 'DD-MM-YYYY').format('YYYY-MM-DD')
          )
          .then(response => {
            setProposalsByDay(response.data)
          })
      }
    }
  }, [openAbsence])

  const dateCellRender = value => {
    dateTime.push(moment(value).format('YYYY-MM-DD'))
    const currentDay = moment(value).format('YYYY-MM-DD')
    const now = moment().format('YYYY-MM-DD')
    let item = timekeepingList.find(
      item => currentDay === moment(item.timekeepingDay).format('YYYY-MM-DD')
    )
    let isLess
    let isProposal = item?.proposalList?.length > 0
    let displayTexts = item?.summaryText?.split(',')

    let color = 'green'
    let workingHour = 0
    let isWorkingDay = item?.summary != '{}'

    if (workingHour < 8) {
      isLess = true
    }
    if (!item?.isValid && isWorkingDay) {
      color = 'red'
    }

    if (currentDay === now || (!item?.isValid && isProposal)) {
      color = '#fbbc05'
    }
    if (!isWorkingDay && !isProposal) {
      item = null
    }
    return (
      <div
        className=" ant-picker-calendar-date "
        style={{ height: '55.6px !important' }}>
        <SpinnerInlineComponent sizeSpin={'small'} />
        {item ? (
          <div onClick={() => onOpenIo(item.id)}>
            <div
              style={{ padding: '3px' }}
              className="ant-picker-calendar-date-value">
              {value.date()}
            </div>
            {displayTexts?.length > 1 ? (
              <div
                key={'calendar1'}
                style={{
                  minHeight: '24px !important',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <div>
                  <Carousel
                    style={{
                      maxHeight: '20px !important',
                      maxWidth: '20px !important',
                      marginRight: '2px',
                    }}
                    autoplay
                    dotPosition={'left'}
                    dots={false}>
                    {displayTexts?.map(item => (
                      <div>{item}</div>
                    ))}
                  </Carousel>
                </div>
                <div>
                  <CheckCircleOutlined
                    style={{
                      color: color,
                      height: '22px',
                      width: '22px',
                    }}
                  />
                </div>
              </div>
            ) : (
              <>
                <span>{item?.summaryText} </span>
                <CheckCircleOutlined
                  style={{
                    color: color,
                    height: '22px',
                    width: '22px',
                  }}
                />
              </>
            )}
          </div>
        ) : (
          <div onClick={() => onOpenIoNull(value)}>
            <div
              style={{ padding: '3px' }}
              className="ant-picker-calendar-date-value">
              {value.date()}
            </div>
            <div style={{ minHeight: '24px', width: '100%' }}></div>
          </div>
        )}
      </div>
    )
  }
  return (
    <DashboardLayout>
      <Helmet>
        <title>Chấm công</title>
      </Helmet>

      <PageTitle
        location={props.location}
        title={'Chấm công'}
        hiddenGoBack></PageTitle>

      <ContentBlockWrapper>
        <div>
          <div
            style={{
              display: 'flex',
            }}>
            <Row gutter={[16, 16]}>
              <Col md={24} xl={12}>
                <div key={'Dash'} style={{}}>
                  <Calendar
                    // disabledDate={disabledDate}
                    onPanelChange={date => {
                      onChangeDay(date)
                      setOpenIo(false)
                      setOpenAbsence(false)
                      // setMonth2(moment(date._d).format('MM'))
                    }}
                    onSelect={date => {
                      setDate(date)
                      history.replace(
                        `/timekeeping-info?timekeeping_day=${date_format.DEFAULT_DATE(
                          date
                        )}`
                      )
                    }}
                    defaultValue={
                      timekeeping_day
                        ? moment(timekeeping_day, 'DD-MM-YYYY')
                        : moment(new Date())
                    }
                    dateFullCellRender={dateCellRender}
                  />
                </div>
              </Col>
              <Col md={24} xl={12}>
                <Row gutter={[16, 16]} style={{ marginLeft: '10px' }}>
                  <Col xs={24} md={12}>
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          width: '100%',
                          margin: '10px 10px',
                          padding: '5px',
                          borderRadius: '5px',
                          backgroundColor: '#cddaf4',
                        }}>
                        <h4
                          style={{
                            borderBottom: '1px solid #fff',
                            fontWeight: 600,
                          }}>
                          Tổng công : {moment(dates).format('MMMM')} năm{' '}
                          {moment(dates).format('YYYY')}
                        </h4>
                        {listProposalType?.length === 0 ? null : (
                          <>
                            {listProposalType?.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{ fontSize: '14px', margin: '5px 0' }}>
                                  {item.name}: {item.total}
                                </div>
                              )
                            })}
                          </>
                        )}
                        <div style={{ fontSize: '14px', margin: '5px 0' }}>
                          Số giờ làm việc: {montlyWorkingHourReport.workingHour}
                        </div>
                        <div style={{ fontSize: '14px', margin: '5px 0' }}>
                          Số giờ làm thêm:{' '}
                          {montlyWorkingHourReport.overtimeHour}
                        </div>
                        <div style={{ fontSize: '14px', margin: '5px 0' }}>
                          Số giờ nghỉ phép: {montlyWorkingHourReport.leaveHour}
                        </div>
                      </div>
                    </div>
                    {/*year*/}
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          width: '100%',
                          margin: '10px 10px',
                          padding: '5px',
                          borderRadius: '5px',
                          backgroundColor: '#cddaf4',
                        }}>
                        <h4
                          style={{
                            borderBottom: '1px solid #fff',
                            fontWeight: 600,
                          }}>
                          Tổng công : năm {moment(dates).format('YYYY')}
                        </h4>
                        {listProposalTypeByYear?.length === 0 ? null : (
                          <>
                            {listProposalTypeByYear?.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{ fontSize: '14px', margin: '5px 0' }}>
                                  {item.name}: {item.total}
                                </div>
                              )
                            })}
                          </>
                        )}
                        <div
                          style={{
                            fontSize: '14px',
                            margin: '5px 0',
                          }}>
                          Số giờ làm thêm (/năm):{' '}
                          <span
                            style={{
                              color:
                                montlyWorkingHourReportByYear.overtimeHour >
                                  200 ===
                                true
                                  ? 'red'
                                  : null,
                            }}>
                            {montlyWorkingHourReportByYear.overtimeHour} / 200
                          </span>
                        </div>
                        <div style={{ fontSize: '14px', margin: '5px 0' }}>
                          Tổng số ngày nghỉ phép (/năm):{' '}
                          <span
                            style={{
                              color:
                                montlyWorkingHourReportByYear.leaveHour / 8 >
                                  total_leave ===
                                true
                                  ? 'red'
                                  : null,
                            }}>
                            {montlyWorkingHourReportByYear.leaveHour / 8} /{' '}
                            {total_leave}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          width: '100%',
                          margin: '10px 10px',
                          // maxHeight: '150px',
                          padding: '5px',
                          borderRadius: '5px',
                          backgroundColor: '#cddaf4',
                        }}>
                        <h4
                          style={{
                            borderBottom: '1px solid #fff',
                            fontWeight: 600,
                          }}>
                          Chú thích trạng thái ngày chấm công :
                        </h4>
                        <div>
                          <CheckCircleOutlined
                            style={{ color: 'green', margin: '0 3px' }}
                          />
                          <span style={{ fontSize: '14px', margin: '5px 0' }}>
                            : Ngày chấm công hợp lệ.
                          </span>
                        </div>
                        <div>
                          <CheckCircleOutlined
                            style={{ color: 'red', margin: '0 3px' }}
                          />
                          <span style={{ fontSize: '14px', margin: '5px 0' }}>
                            : Ngày chấm công sai phạm.
                          </span>
                        </div>
                        <div>
                          <CheckCircleOutlined
                            style={{ color: '#fbbc05', margin: '0 3px' }}
                          />
                          <span style={{ fontSize: '14px', margin: '5px 0' }}>
                            : Ngày có đơn hành chính hoặc ngày hiện tại.
                          </span>
                        </div>

                        <div>
                          <span style={{ margin: '0 3px' }}> X</span>
                          <span style={{ fontSize: '14px', margin: '5px 0' }}>
                            : Giờ làm bình thường.
                          </span>
                        </div>
                        <div>
                          <span style={{ margin: '0 3px' }}> NT</span>
                          <span style={{ fontSize: '14px', margin: '5px 0' }}>
                            : Có giờ làm thêm.
                          </span>
                        </div>
                        <div>
                          <span style={{ margin: '0 3px' }}> NN</span>
                          <span style={{ fontSize: '14px', margin: '5px 0' }}>
                            : Ngày nghỉ có giờ làm thêm.
                          </span>
                        </div>
                        <div>
                          <span style={{ margin: '0 3px' }}> NL</span>
                          <span style={{ fontSize: '14px', margin: '5px 0' }}>
                            : Ngày lễ có giờ làm thêm.
                          </span>
                        </div>
                        <div>
                          <span style={{ margin: '0 3px' }}> TC</span>
                          <span style={{ fontSize: '14px', margin: '5px 0' }}>
                            : Trực ca (chỉ cho lái xe).
                          </span>
                        </div>
                        <div>
                          <span style={{ margin: '0 3px' }}> CT</span>
                          <span style={{ fontSize: '14px', margin: '5px 0' }}>
                            : Công tác.
                          </span>
                        </div>
                        <div>
                          <span style={{ margin: '0 3px' }}> P</span>
                          <span style={{ fontSize: '14px', margin: '5px 0' }}>
                            : Nghỉ phép.
                          </span>
                        </div>
                        <div>
                          <span style={{ margin: '0 3px' }}> L</span>
                          <span style={{ fontSize: '14px', margin: '5px 0' }}>
                            : Nghỉ lễ.
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <Spin spinning={loading}>
            {openAbsence === false && openIo === false ? (
              <div
                style={{
                  border: '1px solid #2c65ac',
                  borderRadius: '5px',
                  maxHeight: '300px',
                  width: '100%',
                }}>
                <EmptyContent />
              </div>
            ) : (
              <div>
                {openAbsence === true ? (
                  <div style={{ width: '100%' }}>
                    <TimekeepingInfoDayOfAbsence
                      date={dates}
                      link={link}
                      proposalsByDay={proposalsByDay}
                      loading={{
                        tip: 'Đang tải...',
                        spinning: loadingAnimationStore.tableLoading,
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {openIo === true ? (
                  <div style={{ width: '100%' }}>
                    <TimekeepingInfo
                      timekeepingIdList={timekeepingIdList}
                      link={link}
                      loading={{
                        tip: 'Đang tải...',
                        spinning: loadingAnimationStore.tableLoading,
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
          </Spin>
        </div>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

TimekeepingPage.propTypes = {}

export default memo(
  inject(
    'loadingAnimationStore',
    'timekeepingInfoStore',
    'commandStore',
    'proposalStore',
    'authenticationStore'
  )(observer(TimekeepingPage))
)
