import { action, observable } from 'mobx'
import { DepartmentHrRequest } from '../requests/DepartmentHrRequest'

class DepartmentHrStore {
  /** Department list */
  @observable departmentHrList = []
  @observable departmentOriginalList = []

  @action getAllDepartmentHrList = () => {
    return new Promise((resolve, reject) => {
      DepartmentHrRequest.getAllDepartmentHrList()
        .then(response => {
          const responseData = response.data
          this.departmentHrList = responseData
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getDepartmentHrListFromCompanyCode = companyCode => {
    return new Promise((resolve, reject) => {
      DepartmentHrRequest.getDepartmentHrListFromCompanyCode(companyCode)
        .then(response => {
          this.departmentHrList = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  createHr = async name => {
    await DepartmentHrRequest.createHr(name)
  }

  updateHr = async (code, name) => {
    await DepartmentHrRequest.updateHr(code, name)
  }

  deleteHr = async code => {
    await DepartmentHrRequest.deleteHr(code)
  }

  sortHr = async codes => {
    await DepartmentHrRequest.sortHr(codes)
  }
}

export default new DepartmentHrStore()
