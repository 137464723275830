import {
  ApartmentOutlined,
  BarChartOutlined,
  HomeOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { Menu } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { memo, useCallback, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { ACL_ACTION_TYPE, MODULE_CODE } from '../../constants'
import date_format from '../../date_format'
import { SiderbarWrapper } from './MainSidebarStyled'

const MainSidebar = props => {
  const {
    location,
    commandStore,
    accountStore,
    authenticationStore,
    commonStore,
    moduleStore,
    aclStore,
  } = props

  const { commandList } = commandStore
  const { accountList } = accountStore
  const { currentUser, isAccountAdmin, isSuperAdmin } = authenticationStore
  const { openedSubMenu, setOpenedSubMenu, collapsedMenu } = commonStore
  const { aclActionsByUser } = aclStore

  const isAccessControl = (moduleCode, accessControlType) => {
    if (isSuperAdmin || isAccountAdmin) return true
    if (!moduleStore.checkAccessModule(moduleCode)) return false
    if (accessControlType)
      return !!aclActionsByUser.find(item => item.code === accessControlType)
        ?.status
    return false
  }

  const isCurrentUserHasCommand =
    currentUser?.commands && currentUser.commands.length !== 0

  // Menu trang chủ
  const menuTrangChu = (
    <Menu.Item key={'/dashboard'} icon={<HomeOutlined />}>
      <Link to={'/dashboard'}>Trang chủ</Link>
    </Menu.Item>
  )

  // Menu hồ sơ
  const menuInfo = (
    <Menu.SubMenu
      key={'info'}
      icon={<InfoCircleOutlined />}
      title="Hồ sơ thông tin">
      {isAccessControl(
        MODULE_CODE.hr_thong_tin_ca_nhan,
        ACL_ACTION_TYPE.view_THONG_TIN_CA_NHAN
      ) && (
        <Menu.Item key={'/my-profile'}>
          <Link to={'/my-profile'}>Thông tin cá nhân</Link>
        </Menu.Item>
      )}
    </Menu.SubMenu>
  )

  // Menu Lương - Thuế - BH
  const menuSalary = (
    <Menu.SubMenu
      key={'salary - tax - insurance'}
      icon={<BarChartOutlined />}
      title="Lương - Thuế - BH">
      {isAccessControl(
        MODULE_CODE.hr_bang_luong,
        ACL_ACTION_TYPE.view_BANG_LUONG
      ) && (
        <Menu.Item key={'/my-salary'}>
          <Link to={'/my-salary'}>Bảng lương</Link>
        </Menu.Item>
      )}
      {isAccessControl(
        MODULE_CODE.hr_cham_cong,
        ACL_ACTION_TYPE.view_CHAM_CONG
      ) && (
        <Menu.Item key={'/timekeeping-info'}>
          <Link
            to={`/timekeeping-info?timekeeping_day=${date_format.DEFAULT_DATE(
              new Date()
            )}`}>
            Chấm công
          </Link>
        </Menu.Item>
      )}
    </Menu.SubMenu>
  )

  const menuACL = (
    <Menu.Item key={'/acl'}>
      <Link to={'/acl'}>Phân quyền</Link>
    </Menu.Item>
  )

  // Menu Quản trị
  const menuQuanTri = (
    <Menu.SubMenu
      key={'management'}
      icon={<ApartmentOutlined />}
      title="Quản trị">
      {(isAccessControl(
        MODULE_CODE.hr_cham_cong,
        ACL_ACTION_TYPE.view_CHAM_CONG_QLNS
      ) ||
        isAccessControl(
          MODULE_CODE.hr_cham_cong,
          ACL_ACTION_TYPE.view_TRUONG_BAN
        )) && (
        <Menu.Item key={'/management/timekeeping'}>
          <Link to={'/management/timekeeping'}>Chấm công</Link>
        </Menu.Item>
      )}
      {isAccessControl(
        MODULE_CODE.hr_dong_bo_cham_cong,
        ACL_ACTION_TYPE.view_DONG_BO_CHAM_CONG
      ) && (
        <Menu.Item key={'/management/timekeepingSync'}>
          <Link to={'/management/timekeepingSync'}>Đồng bộ chấm công</Link>
        </Menu.Item>
      )}
      {isAccessControl(
        MODULE_CODE.hr_phan_quyen,
        ACL_ACTION_TYPE.view_PHAN_QUYEN
      ) && menuACL}

      {isAccessControl(
        MODULE_CODE.hr_ngay_lam_viec,
        ACL_ACTION_TYPE.view_NGAY_LAM_VIEC
      ) && (
        <Menu.Item key={'/management/officeday'}>
          <Link to={'/management/officeday'}>Ngày làm việc</Link>
        </Menu.Item>
      )}

      {isAccessControl(
        MODULE_CODE.hr_tim_kiem_nhan_su,
        ACL_ACTION_TYPE.view_TIM_KIEM_NHAN_SU
      ) && (
        <Menu.Item key={'/admin/user-account-management'}>
          <Link to={'/admin/user-account-management'}>Tìm kiếm nhân sự</Link>
        </Menu.Item>
      )}
      {isAccessControl(
        MODULE_CODE.hr_phong_nhan_su,
        ACL_ACTION_TYPE.view_PHONG_NHAN_SU
      ) && (
        <Menu.Item key={'/admin/management/user-account-management'}>
          <Link to={'/admin/management/user-account-management'}>
            Phòng nhân sự
          </Link>
        </Menu.Item>
      )}
    </Menu.SubMenu>
  )

  const onSubMenuToggle = useCallback(keys => {
    setOpenedSubMenu(keys)
  }, [])

  const onClickMenuItem = ({ keyPath }) => {
    setOpenedSubMenu([keyPath[1]])
  }

  useEffect(() => {
    // Trang chủ
    if (location.pathname.includes('/dashboard')) {
      commonStore.setPage(['/dashboard'])
      setOpenedSubMenu([])
      return
    }

    // Hồ sơ thông tin
    if (
      location.pathname.includes('/my-profile') ||
      location.pathname.includes('/my-profile/')
    ) {
      commonStore.setPage(['/my-profile'])
      setOpenedSubMenu(['info'])
      return
    }

    // Lương - Thuế - BH
    if (
      location.pathname.includes('/my-salary') ||
      location.pathname.includes('/my-salary/')
    ) {
      commonStore.setPage(['/my-salary'])
      setOpenedSubMenu(['salary - tax - insurance'])
      return
    }

    if (
      location.pathname.includes('/timekeeping-info') ||
      location.pathname.includes('/timekeeping-info/')
    ) {
      commonStore.setPage(['/timekeeping-info'])
      setOpenedSubMenu(['salary - tax - insurance'])
      return
    }

    if (
      location.pathname.includes('/proposal-salary-management') ||
      location.pathname.includes('/proposal-salary-management/')
    ) {
      commonStore.setPage(['/proposal-salary-management'])
      setOpenedSubMenu(['salary'])
      return
    }

    if (
      location.pathname.includes('/salary') ||
      location.pathname.includes('/salary/')
    ) {
      commonStore.setPage(['/salary'])
      setOpenedSubMenu(['salary'])
      return
    }

    if (
      location.pathname.includes('/proposal-salary-request') ||
      location.pathname.includes('/proposal-salary-request/')
    ) {
      commonStore.setPage(['/proposal-salary-request'])
      setOpenedSubMenu(['salary'])
      return
    }

    // Tuyển dụng

    // Khen thưởng & Đào tạo

    // Quản lý phân hệ
    if (
      location.pathname.includes('/module') ||
      location.pathname.includes('/module/')
    ) {
      commonStore.setPage(['/module'])
      // setOpenedSubMenu([])
      return
    }
    // Quản lý phân quyền
    if (
      location.pathname.includes('/acl') ||
      location.pathname.includes('/acl/')
    ) {
      commonStore.setPage(['/acl'])
      // setOpenedSubMenu([])
      return
    }

    // Quy trình
    if (
      location.pathname.includes('/administrative/consult') ||
      location.pathname.includes('/administrative/consult/')
    ) {
      commonStore.setPage(['/administrative/consult'])
      setOpenedSubMenu(['administrative'])
      return
    }
    if (
      location.pathname.includes('/administrative/policy') ||
      location.pathname.includes('/administrative/policy/')
    ) {
      commonStore.setPage(['/administrative/policy'])
      setOpenedSubMenu(['administrative'])
      return
    }
    if (
      location.pathname.includes('/administrative/advance-payment') ||
      location.pathname.includes('/administrative/advance-payment/')
    ) {
      commonStore.setPage(['/administrative/advance-payment'])
      setOpenedSubMenu(['administrative'])
      return
    }
    if (location.pathname.includes('/administrative/payslip')) {
      commonStore.setPage(['/administrative/payslip'])
      setOpenedSubMenu(['administrative'])
      return
    }

    // Tiện ích
    if (
      location.pathname.includes('/utility/iso') ||
      location.pathname.includes('/utility/iso/')
    ) {
      commonStore.setPage(['/utility/iso'])
      setOpenedSubMenu(['utilities'])
      return
    }

    if (
      location.pathname.includes('/utility/messages') ||
      location.pathname.includes('/utility/messages/')
    ) {
      commonStore.setPage(['/utility/messages'])
      setOpenedSubMenu(['utilities'])
      return
    }
    if (
      location.pathname.includes('/utility/signed-document') ||
      location.pathname.includes('/utility/signed-document/')
    ) {
      commonStore.setPage(['/utility/signed-document'])
      setOpenedSubMenu(['utilities'])
      return
    }
    if (
      location.pathname.includes('/utility/contacts') ||
      location.pathname.includes('/utility/contacts/')
    ) {
      commonStore.setPage(['/utility/contacts'])
      setOpenedSubMenu(['utilities'])
      return
    }
    if (
      location.pathname.includes('/utility/general-notifications') ||
      location.pathname.includes('/utility/general-notifications/')
    ) {
      commonStore.setPage(['/utility/general-notifications'])
      setOpenedSubMenu(['utilities'])
      return
    }
    if (
      location.pathname.includes('/utility/digital-signature') ||
      location.pathname.includes('/utility/digital-signature/')
    ) {
      commonStore.setPage(['/utility/digital-signature'])
      setOpenedSubMenu(['utilities'])
      return
    }
    // Quản trị
    if (location.pathname.includes('/management/group')) {
      commonStore.setPage(['/management/group'])
      setOpenedSubMenu(['management'])
      return
    }
    commonStore.setPage([location.pathname])
  }, [location.pathname])

  useEffect(() => {
    if (isCurrentUserHasCommand) {
      currentUser.commands.forEach(userCommand => {
        commandList.length !== 0 &&
          commandList.forEach(command => {
            if (command.code === userCommand.code) {
              userCommand.url = command.url
              userCommand.description = command.description
              userCommand.image = command.image
            }
          })
        accountList.length !== 0 &&
          accountList.forEach(account => {
            if (account.command.code === userCommand.code) {
              userCommand.account_name = account.account_name
              userCommand.id = account.id
              userCommand.password = account.password
            }
          })
      })
    }
  }, [currentUser, commandList, accountList, isCurrentUserHasCommand])

  return (
    <SiderbarWrapper>
      <Menu
        mode="inline"
        selectedKeys={commonStore.pageName}
        openKeys={openedSubMenu}
        onOpenChange={onSubMenuToggle}
        inlineCollapsed={!collapsedMenu}
        onClick={onClickMenuItem}>
        {menuTrangChu}
        {isAccessControl(
          MODULE_CODE.hr_ho_so_thong_tin,
          ACL_ACTION_TYPE.view_HO_SO_THONG_TIN
        ) && menuInfo}
        {isAccessControl(
          MODULE_CODE.hr_luong_thue_bh,
          ACL_ACTION_TYPE.view_LUONG_THUE_BH
        ) && menuSalary}
        {/* {isAccessControl(MODULE_CODE.tien_ich) && menuTienIch} */}
        {/* {menuTuyenDung} */}
        {/* {menuKTVDT} */}
        {isAccessControl(
          MODULE_CODE.hr_quan_tri,
          ACL_ACTION_TYPE.view_QUAN_TRI
        ) && menuQuanTri}
      </Menu>
    </SiderbarWrapper>
  )
}

export default memo(
  withRouter(
    inject(
      'commandStore',
      'accountStore',
      'authenticationStore',
      'commonStore',
      'notificationStore',
      'moduleStore',
      'aclStore'
    )(observer(MainSidebar))
  )
)
