import PortalPageV2 from './pages/PortalPageV2/PortalPageV2'
import DashboardPageV2 from './pages/DashboardPageV2/DashboardPageV2'
import UserAccountManagementPage from './pages/UserAccountManagementPage/UserAccountManagementPage'
import UserAppManagementPage from './pages/UserAppManagementPage/UserAppManagementPage'
import MyProfilePage from './pages/MyProfilePage/MyProfilePage'
import NotificationPage from './pages/NotificationPage/NotificationPage'
import EmailRedirectPage from './pages/EmailRedirectPage'
import AuthoritiesPage from './pages/AuthoritiesPage/AuthoritiesPage'
import PositionPage from './pages/Position/PositionPage'
import ContactPage from './pages/Contacts/Contact'
import DigitalSignaturePage from './pages/DigitalSignaturePage/DigitalSignaturePage'
import LeaveDocumentPage from './pages/Administrative/LeaveForm/LeaveTable'
import LeaveForm from './pages/Administrative/LeaveForm/Form/Form'
import ChamCongPage from './pages/Administrative/ChamCong/ChamCongPage'
import ChamCongForm from './pages/Administrative/ChamCong/Form/ChamCongForm'
import Payslip from './pages/Administrative/Payslip/Payslip'
import UserGroupPage from './pages/UserGroupPage/UserGroupPage'
import TinTucPage from './pages/TinTuc/TinTucPage'
import TinTucCreatePage from './pages/TinTucCreatePage/TinTucCreatePage'
import TinTucDetailPage from './pages/TinTucDetailPage/TinTucDetailPage'
import SignAccountPage from './pages/SignAccountPage/SignAccountPage'
import MessagesPage from './pages/MessagesPage'
import MessageDetailPage from './pages/MessageDetailPage'
import SalaryPage from './pages/SalaryPage'
import SalaryListPage from './pages/SalaryListPage'
import MySalaryPage from './pages/MySalaryPage'
import ModulePage from './pages/ModulePage'
import ACLPage from './pages/ACLPage'
import ACLCreatePage from './pages/ACLCreatePage'
import ManageDepartmentPage from './pages/ManageDepartment/ManageDepartmentPage'
import ContentTemplatePage from './pages/ContentTemplate/ContentTemplatePage'
import AdministrativeManagement from './pages/Administrative/AdministrativeManagement'
import AdministrativeManagementContentTemplate from './pages/Administrative/AdministrativeManagement/AdministrativeManagementContentTemplate'
import AdministrativeManagementApprovalTemplate from './pages/Administrative/AdministrativeManagement'
import SalaryManagement from './pages/SalaryManagement'
import { CompanyPage } from './pages/CompanyPage'
import { AdminsPage } from './pages/AdminsPage.js'
import TimekeepingPage from './pages/TimekeepingPage/TimekeepingPage'
import TimekeepingSyncPage from './pages/TimekeepingPage/TimekeepingSyncPage'
import TimekeepingInfoPage from './pages/TimekeepingPage/TimekeepingPageForUser'
import AcsOfficeDayPage from './pages/AcsOfficeDayPage/AcsOfficeDayPage'
import ProposalPage from './pages/ProposalPage/ProposalPage'
import ProposalDetail from './pages/ProposalPage/ProposalDetailPage/ProposalDetail'
import UserAccountManagement from './pages/UserAccountManagement/UserAccountManagement'

export const normalRoutes = [
  {
    path: '/login',
    component: DashboardPageV2,
    name: 'Login',
  },
  {
    path: '/portalv2',
    component: PortalPageV2,
    name: 'Trang chủ',
  },
]

export const routes = [
  {
    path: '/',
    component: PortalPageV2,
    name: 'Trang chủ',
  },
  {
    path: '/dashboard',
    component: DashboardPageV2,
    name: 'DashboardPage',
  },
  {
    path: '/admin/user-account-management',
    component: UserAccountManagementPage,
    name: 'Tìm kiếm nhân sự',
  },
  {
    path: '/admin/management/user-account-management',
    component: UserAccountManagement,
    name: 'Phòng nhân sự',
  },
  {
    path: '/admin/user-app-management',
    component: UserAppManagementPage,
    name: 'Quản lý phần mềm',
  },
  {
    path: '/my-profile',
    component: MyProfilePage,
    name: 'Thông tin của bạn',
  },
  {
    path: '/notification',
    component: NotificationPage,
    name: 'Thông báo',
  },
  {
    path: '/eMail',
    component: EmailRedirectPage,
    name: 'eMail',
  },
  {
    path: '/timekeeping-info',
    component: TimekeepingInfoPage,
    name: 'Thông tin chấm công người dùng',
  },

  //Authorities page
  {
    path: '/eoffice-config/authorities',
    component: AuthoritiesPage,
    name: 'Cơ quan ban hành',
  },
  {
    path: '/management/position',
    component: PositionPage,
    name: 'Quản lý chức danh',
  },
  {
    path: '/utility/messages',
    component: MessagesPage,
    name: 'Tin nhắn liên thông',
  },
  {
    path: '/utility/messages/view/:messageId',
    component: MessageDetailPage,
    name: 'Chi tiết',
  },
  {
    path: '/utility/contacts',
    component: ContactPage,
    name: 'Danh bạ',
  },
  {
    path: '/management/department',
    component: ManageDepartmentPage,
    name: 'Quản lý phòng ban',
  },
  {
    path: '/utility/digital-signature',
    component: DigitalSignaturePage,
    name: 'Ký số điện tử',
  },
  //Hành chính -> Nghỉ phép
  {
    path: '/administrative/leave-form',
    component: LeaveDocumentPage,
    name: 'Nghỉ phép',
  },
  {
    path: '/administrative/leave-form/:leaveDocId',
    component: LeaveForm,
    name: 'Chi tiết',
  },
  //Quản lí hành chính
  {
    path: '/administrative-management',
    component: AdministrativeManagement,
    name: 'Quản lý hành chính',
  },
  {
    path: '/administrative-management/content-template',
    component: AdministrativeManagementContentTemplate,
    name: 'Biểu mẫu',
  },
  {
    path: '/administrative-management/approval-templates',
    component: AdministrativeManagementApprovalTemplate,
    name: 'Mẫu duyệt',
  },
  {
    path: '/administrative/cham-cong',
    component: ChamCongPage,
    name: 'Chấm công',
  },
  {
    path: '/administrative/cham-cong/:chamCongId',
    component: ChamCongForm,
    name: 'Chi tiết',
  },
  //
  {
    path: '/administrative/payslip',
    component: Payslip,
    name: 'Bảng lương',
  },
  //
  {
    path: '/management/group',
    component: UserGroupPage,
    name: 'Danh sách nhóm người dùng',
  },
  {
    path: '/management/nhiem-vu-group',
    component: UserGroupPage,
    name: 'Danh sách nhóm nhiệm vụ',
  },
  {
    path: '/management/sign-account',
    component: SignAccountPage,
    name: 'Tài khoản ký số',
  },
  // Tin tuc
  {
    path: '/utility/general-notifications',
    component: TinTucPage,
    name: 'Thông báo chung',
  },
  {
    path: '/utility/general-notifications/create',
    component: TinTucCreatePage,
    name: 'Đăng thông báo',
  },
  {
    path: '/utility/general-notifications/update/:id',
    component: TinTucCreatePage,
    name: 'Chỉnh sửa thông báo',
  },
  {
    path: '/utility/general-notifications/view/:id',
    component: TinTucDetailPage,
    name: 'Chi tiết thông báo',
  },
  {
    path: '/management/timekeeping',
    component: TimekeepingPage,
    name: 'Chấm công',
  },
  {
    path: '/management/timekeepingSync',
    component: TimekeepingSyncPage,
    name: 'Đồng bộ chấm công',
  },
  {
    path: '/management/officeday',
    component: AcsOfficeDayPage,
    name: 'Ngày làm việc',
  },
  {
    path: '/management/proposal',
    component: ProposalPage,
    name: 'Hành chính',
  },
  {
    path: '/management/proposal/view/:id',
    component: ProposalDetail,
    name: 'Chi tiết hành chính',
  },
  // Lương
  {
    path: '/salary/create',
    component: SalaryPage,
    name: 'Trình duyệt bảng lương',
  },
  {
    path: '/salary/view/:id',
    component: SalaryPage,
    name: 'Chi tiết bảng lương',
  },
  {
    path: '/salary',
    component: SalaryListPage,
    name: 'Duyệt lương',
  },
  {
    path: '/my-salary',
    component: MySalaryPage,
    name: 'Bảng lương của tôi',
  },
  {
    path: '/proposal-salary-management',
    component: SalaryManagement,
    name: 'Quản lý bảng lương',
  },
  // Module
  {
    path: '/module',
    component: ModulePage,
    name: 'Quản lý phân hệ',
  },
  {
    path: '/company',
    component: CompanyPage,
    name: 'Quản lý công ty',
  },
  {
    path: '/admins',
    component: AdminsPage,
    name: 'Quản trị viên',
  },
  {
    path: '/acl',
    component: ACLPage,
    name: 'Quản lý phân quyền',
  },
  {
    path: '/acl/create',
    component: ACLCreatePage,
    name: 'Thiết lập phân quyền',
  },
  {
    path: '/acl/view/:code',
    component: ACLCreatePage,
    name: 'Thiết lập phân quyền',
  },

  //Content template Biểu mẫu
  {
    path: '/content-template',
    component: ContentTemplatePage,
    name: 'Biểu mẫu',
  },
]
