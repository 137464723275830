import React, { useEffect, useState } from 'react'
import { InfoList } from './MyProfilePageStyled'
import { Button, Col, message, Row, Space, Form, Input } from 'antd'
import { inject, observer } from 'mobx-react'
import { EmptyText } from '../../components/Common/CellText'
import moment from 'moment'
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  MailFilled,
} from '@ant-design/icons'
import validator from '../../validator'

const Contact = props => {
  const {
    authenticationStore,
    loadingAnimationStore,
    userStore,
    aclStore,
  } = props

  const { currentUser } = authenticationStore
  const { aclAclGroupName } = aclStore
  const nullText = <EmptyText>Chưa cập nhật</EmptyText>

  const [editing, setEditing] = useState(false)

  const [fields, setFields] = useState([
    {
      name: ['email'],
      value: undefined,
    },
    {
      name: ['phone'],
      value: undefined,
    },
    {
      name: ['home_phone'],
      value: undefined,
    },
    {
      name: ['c_id'],
      value: undefined,
    },
    {
      name: ['si_no'],
      value: undefined,
    },
    {
      name: ['license_date'],
      value: undefined,
    },
    {
      name: ['registration_location'],
      value: undefined,
    },
    {
      name: ['address'],
      value: undefined,
    },
    {
      name: ['hometown'],
      value: undefined,
    },
  ])

  const setFieldsOrigin = () => {
    setFields([
      {
        name: ['email'],
        value: currentUser?.email,
      },
      {
        name: ['phone'],
        value: currentUser?.phone,
      },
      {
        name: ['home_phone'],
        value: currentUser?.home_phone,
      },
      {
        name: ['c_id'],
        value: currentUser?.other?.c_id,
      },
      {
        name: ['si_no'],
        value: currentUser?.other?.si_no,
      },
      {
        name: ['license_date'],
        value: currentUser?.other?.license_date,
      },
      {
        name: ['registration_location'],
        value: currentUser?.other?.registration_location,
      },
      {
        name: ['address'],
        value: currentUser?.address,
      },
      {
        name: ['hometown'],
        value: currentUser?.other?.hometown,
      },
    ])
  }
  const onUpdate = async value => {
    loadingAnimationStore.showSpinner(true)
    try {
      await userStore.updateCurrentUserContact(value)
      authenticationStore.checkCurrentUser()
      setEditing(false)
      message.success('Cập nhật thông tin thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  useEffect(() => {
    if (!currentUser) return
    setFieldsOrigin()
  }, [currentUser])
  return (
    <>
      <div className={'overview-info'}>
        <Form onFinish={onUpdate} fields={fields}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h2 style={{ marginBottom: 0, textTransform: 'capitalize' }}>
              {currentUser?.name_lowercase || nullText}
              <span className={'username'}>({currentUser?.username})</span>
            </h2>
            {!editing && (
              <Button
                type={'primary'}
                icon={<EditOutlined />}
                onClick={() => setEditing(true)}>
                Chỉnh sửa
              </Button>
            )}
            {editing && (
              <Space>
                <Button
                  onClick={() => {
                    setFieldsOrigin()
                    setEditing(false)
                  }}
                  danger
                  icon={<CloseOutlined />}>
                  Hủy bỏ
                </Button>
                <Button
                  type={'primary'}
                  icon={<CheckOutlined />}
                  htmlType={'submit'}>
                  Lưu thay đổi
                </Button>
              </Space>
            )}
          </div>
          <Row gutter={[20, 10]}>
            <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
              <InfoList>
                <dt>Quê quán</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.hometown || nullText}</div>
                  ) : (
                    <Form.Item
                      name={'hometown'}>
                      <Input
                        placeholder={'Quê quán'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Nơi ở hiện nay</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.address || nullText}</div>
                  ) : (
                    <Form.Item
                      name={'address'}>
                      <Input
                        placeholder={'Nơi ở hiện này'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Nơi đăng ký HKTT</dt>
                <dd>{!editing ? (
                    <div>{currentUser?.other?.registration_location || nullText}</div>
                  ) : (
                    <Form.Item
                      name={'registration_location'}>
                      <Input
                        placeholder={'Nơi đăng ký hộ khẩu thường trú'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}</dd>
                <dt>Điện thoại di động</dt>
                <dd>
                  {!editing ? (
                    <div>{currentUser?.phone || nullText}</div>
                  ) : (
                    <Form.Item
                      rules={[{ validator: validator.validatePhoneNumber }]}
                      name={'phone'}>
                      <Input
                        placeholder={'Điện thoại di động'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}
                </dd>
              </InfoList>
            </Col>
            <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
              <InfoList>
                <dt>Số máy nội bộ</dt>
                <dd>
                  {!editing ? (
                    <div>{currentUser?.home_phone || nullText}</div>
                  ) : (
                    <Form.Item name={'home_phone'}>
                      <Input
                        placeholder={'Số máy nội bộ'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}
                </dd>
                <dt>Số CMTND/CCCD</dt>
                <dd>
                  {!editing ? (
                    <div>{currentUser?.other?.c_id || nullText}</div>
                  ) : (
                    <Form.Item name={'c_id'}>
                      <Input
                        placeholder={'CMND/CCCD'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}
                </dd>
                <dt>Ngày cấp</dt>
                <dd>
                  {!editing ? (
                    <div>{currentUser.other?.license_date || nullText}</div>
                  ) : (
                    <Form.Item
                      rules={[{ validator: validator.validateDate }]}
                      name={'license_date'}>
                      <Input
                        placeholder={'DD-MM-YYYY'}
                        style={{ maxWidth: 250 }}
                        type="text"
                      />
                    </Form.Item>
                  )}
                </dd>
                <dt>Số sổ BHXH</dt>
                <dd>
                  {!editing ? (
                    <div>{currentUser?.other?.si_no || nullText}</div>
                  ) : (
                    <Form.Item name={'si_no'}>
                      <Input
                        placeholder={'Số sổ BHXH'}
                        style={{ maxWidth: 250 }}
                      />
                    </Form.Item>
                  )}
                </dd>
              </InfoList>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

Contact.propTypes = {}

export default inject(
  'authenticationStore',
  'commonStore',
  'loadingAnimationStore',
  'userStore',
  'aclStore'
)(observer(Contact))
